import { GET_SEARCH_USER, GET_SEARCH_USER_ID, GET_SEARCH_USER_PER_PORTAL, SUCC_USER_REG_DATA } from "@/store/storeconstants";

export default{
    [SUCC_USER_REG_DATA](state,payload){

        state.user_idd = payload.id;

    },


    [GET_SEARCH_USER](state,payload){
        
        state.search_user_result = payload;
        state.user_idd = payload.id;
    
    },

    [GET_SEARCH_USER_PER_PORTAL](state,payload){
         
        if(payload.success_data != "Not Found"){
            console.log(payload.data)
            state.search_user_result = payload.data;
            state.user_idd = payload.data.id
            state.search_message = payload.success_data;
        }else{
            state.search_message = "Not Found";
            state.user_idd = ""
            state.search_user_result = {};
        }

      

    },

    [GET_SEARCH_USER_ID](state,payload){
        
        state.search_user_id_result = payload;
    
    }
}