<template>
   <div class="col-4 text-start">

        <div v-if="selectAll">
            You are currently selecting all
            <strong>{{ num_checked }}</strong> items.
        </div>

        <div v-else>
            You have selected <strong>{{ num_checked }}</strong> items, Do you want to
            Select All <strong>{{total}}</strong>?
            <a @click.prevent="selRecords" href="#" class="m1-2">Select All</a>
        </div>
        
    </div>
</template>

<script>
import { mapState } from 'vuex'
    export default {
        name:'SelectAllComponent',
        props:["num_checked"],

        methods:{
            selRecords(){
                this.$emit('selRecords')
            }
        },

             computed:{
            ...mapState('dataTable',['total','selectAll'])
        }
        
    }
</script>

<style scoped>

</style>