import actions from "./actions";
import mutations from "./mutations";
export default {
    namespaced:true,
    state(){
        return{
            provider_idd:"",
            provider_exist_success_mess:"",
            provider_message:"",
            provider_view_type:"",
            provider_info:{}
        }
    },

    mutations,
    actions,
}