<template>

        <!-- Modal -->
        <div class="modal fade"  :class="{ show: false, 'd-block': false }"  id="dependentSupportModal"    aria-labelledby="dependentSupportModalLabel" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg" style="max-height:30%; max-width: 80%;" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="dependentModalLabel" v-if="dependent_support_view_type=='add'">Add Dependent</h5>
                <h5 class="modal-title" id="dependentModalLabel" v-else-if="dependent_support_view_type=='edit'">Edit Dependent</h5>
                <h5 class="modal-title" id="dependentModalLabel" v-else-if="dependent_support_view_type=='view'">View Dependent</h5>
                <button type="button" class="btn-close" :data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#dependentSupportModal" aria-label="Close"></button>
            </div>
              <div v-if="this.dependent_support_view_type=='add' || this.dependent_support_view_type=='edit'">
              <form class="register-form" @submit.prevent="reg()">
            <div class="modal-body">

                <div class="card shadow-sm" id="stylized">
                   
                                <!-- <div class="card-body"> -->


                    <div class="card-body" v-if="dependent_support_view_type=='add'">
                      <h1 v-if="app_portal == 'Support'">Search Dependent</h1>
                      <h1 v-if="app_portal == 'Provider'">Search Support</h1>
                      <br/>
                      <p></p>
        
                  <span>
                  
                    <div class="row" v-if="app_portal == 'Support'">
                      <div class="col"  v-if="dependent_support_view_type=='add'">

                         <div class="form-group">
                          <label>Search Dependent By Contact/ Email</label>

                             <input
                            type="text"
                            class="form-control"
                            :class="{ form_error: searchDependentErr}"
                            style="min-width:100%"
                            placeholder="Search Dependent"
                            v-model.trim.lazy="searchDependent"
                          />
                          <div
                            :class="{ text_error: searchDependentErr  }"
                           v-if="searchDependentErr">
                          {{ searchDependentErr }} 
                          </div>

                           <div v-if="dependentLoading && !formData.dependent_id">
                            <SpinnerComp />
                            <div class="center">Checking...</div>
                          </div>
                          <span v-if="search_dependent_fail && searchDependent != ''" class="text_error : search_dependent_fail">{{ search_dependent_fail }}</span>

                         </div>

                      </div>



                     




                    </div>




                      <div class="row" v-else-if="app_portal == 'Provider'">
                      <div class="col"  v-if="dependent_support_view_type=='add'">

                         <div class="form-group">
                          <label>Search Support By Contact/ Email</label>

                             <input
                            type="text"
                            class="form-control"
                            :class="{ form_error: searchSupportErr}"
                            style="min-width:100%"
                            placeholder="Search Support"
                            v-model.trim.lazy="searchSupport"
                             @blur="searchSupportVal($event.target.value)" 
                          />
                          <div
                            :class="{ text_error: searchSupportErr  }"
                           v-if="searchSupportErr">
                          {{ searchSupportErr }} 
                          </div>

                           <div v-if="supportLoading && !formData.support_id">
                            <SpinnerComp />
                            <div class="center">Checking...</div>
                          </div>
                          <span v-if="search_support_fail && searchSupport != ''" class="text_error : search_support_fail">{{ search_support_fail }}</span>

                         </div>

                      </div>







                                 <div class="col"  v-if="dependent_support_view_type=='add'">

                          <div class="form-group">
                          <label>Support Info</label>

                          <input
                            type="text"
                            class="form-control"
                            :class="{ form_error: supportErr}"
                            placeholder="Full Name"
                            v-model.trim="support"
                            readonly
                            style="width:100%"
                          />
                          <div
                            :class="{ text_error: supportErr  }"
                           v-if="supportErr">
                          {{ supportErr }} 
                          </div>
                        </div>
                       

                      </div>



                     




                    </div>



                  </span>
                    </div>



                                 <div class="card-body">
                      <h1>Dependent Details</h1><br/>
                      <p></p>

                      <span>





                        <div class="row">





                            <div class="col">
                      
                      


                        <div class="form-group">
                          <label>Fullname</label>

                          <input
                            type="text"
                            class="form-control"
                            :class="{ form_error: fullnameErr}"
                            placeholder="Full Name"
                            @blur="fullnameVal($event.target.value)" 
                            v-model.trim="formData.fullname"
                          />
                          <div
                            :class="{ text_error: fullnameErr  }"
                           v-if="fullnameErr">
                          {{ fullnameErr }} 
                          </div>
                        </div>
                      </div>
                     


                        <div class="col">
                        <div class="form-group">
                          <label><span class="text_error">*</span>Contact</label><br/>
                          <!-- <input
                            type="text"
                            class="form-control"
                            id="telephone"
                            :class="{ form_error: contactErr }"
                            placeholder="Contact"
                             @blur="contactVal($event.target.value)"
                            v-model.trim.lazy="formData.contact"
                          /> -->
                          <div style="width: 250px;">
                          <vue-tel-input v-model.lazy="formData.contact" @blur="contactVal" ></vue-tel-input>
                          </div>
                          <div
                            :class="{ text_error: contactErr }"
                            v-if="contactErr"
                          >
                            {{ contactErr }}
                          </div><br/>
                            <div v-if="contactLoading && !formData.dependent_id">
                            <SpinnerComp />
                            <div class="center">Checking...</div>
                          </div>
                          <span v-if="formData.dependent_id && formData.contact != ''" class="text-success">{{search_contact_info}}</span>
                          <span v-if="search_contact_fail && formData.contact != ''" class="text_error : search_contact_fail">{{ search_contact_fail }}</span>
                      

                        </div>
                      </div>





                        <div class="col">
                        <div class="form-group">
                          <label>Email</label>
                          <input
                            type="email"
                            class="form-control"
                            :class="{ form_error: emailErr}"
                            placeholder="Email"
                            @blur="emailVal($event.target.value)"
                            v-model.trim.lazy="formData.email"
                          />
                          <div
                            :class="{ text_error: emailErr}"
                            v-if="emailErr"
                          >
                            {{ emailErr }} 
                          </div><br/>
                             <div v-if="emailLoading && !formData.dependent_id">
                            <SpinnerComp />
                            <div class="center">Checking...</div>
                          </div>
                            <span v-if="formData.dependent_id && formData.email != ''" class="text-success">{{search_email_info}}</span>
                            <span v-if="search_email_fail && formData.email != ''" class="text_error : search_email_fail">{{ search_email_fail }}</span>
                        </div>
                      </div>



     




                    </div>


                          <div class="row">

                            

                                               <div class="col">
                        <div class="form-group">
                          <label><span class="text_error">*</span>Country</label>
   

                         <v-select :options="getCountries"  class="select1" v-model="formData.country_id"  :reduce="country => country.id" label="country" @option:selected="countryVal" />

                       
                          <div
                            :class="{ text_error: countryErr }"
                            v-if="countryErr"
                          >
                            {{ countryErr }}
                          </div>
                        </div>
                      </div>
                    
                       



                             <div class="col">
                        <div class="form-group">
                          <label>City/Town</label>
                          <input
                            type="text"
                            class="form-control"
                            :class="{ form_error: cityErr }"
                            placeholder="City"
                            @blur="cityVal($event.target.value)"
                            v-model.trim="formData.city"
                          />
                          <div :class="{ text_error: cityErr }" v-if="cityErr">
                            {{ cityErr }} 
                          </div>
                        </div>
                      </div>




                            <div class="col">
                        <div class="form-group">
                          <label>Gender</label>
                          <select
                            class="form-control"
                            :class="{ form_error: genderErr}"
                             @change="genderVal($event.target.value)"
                            v-model.trim="formData.gender">
                            <option value="">--Choose An Option--</option>
                            <option value="MALE">MALE</option>
                            <option value="FEMALE">FEMALE</option>
                          </select>
                          
                          <div
                            :class="{ text_error: genderErr}"
                            v-if="genderErr"
                          >
                            {{ genderErr }}
                          </div>
                        </div>
                      </div>




                      <div class="col" v-if="app_portal=='Support'">
                        <div class="form-group">
                          <label>Status</label>
                          <select
                            class="form-control"
                            :class="{ form_error: statusErr}"
                             @change="statusVal($event.target.value)"
                            v-model.trim="formData.status">
                            <option value="">--Choose An Option--</option>
                            <option value="ACTIVE">ACTIVE</option>
                            <option value="INACTIVE">INACTIVE</option>
                            <option value="PENDING">PENDING</option>
                          </select>
                          
                          <div
                            :class="{ text_error: statusErr}"
                            v-if="statusErr"
                          >
                            {{ statusErr }}
                          </div>
                        </div>
                      </div>



                           <!-- {{ formData.dependent_id }} -->






               


                    </div>




                
                  </span>
                






                  
                </div>


                    

                                </div>
                
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary" v-if="dependent_support_view_type=='add' && !formData.dependent_id">Submit</button>
                <button type="submit" class="btn btn-primary"  v-else-if="dependent_support_view_type=='add' && formData.dependent_id">Link</button>
                <button type="submit" class="btn btn-primary" v-if="dependent_support_view_type=='edit'">Update</button>
                <button type="button" class="btn btn-danger" @click="remove_all" v-if="dependent_support_view_type=='add'">Clear All</button>
                <button type="button" class="btn btn-secondary" ref="DependentSupportmodal" data-bs-dismiss="modal" v-if="dependent_support_view_type=='edit'">Close</button>
            </div>

                  </form>
                  </div>


                 <div v-else>
                      <div class="modal-body">
                        <div class="table-responsive table-responsive-data2">
                        <table class="table table-hover table-striped">
                          
                              <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Value</th>
                                </tr>
                                </thead>


                                <tbody>
                                <tr>
                                <td>Fullname</td>
                                <td>{{formData.fullname}}</td>
                                </tr>

                               <tr>
                                <td>Contact</td>
                                <td>{{formData.contact}}</td>
                                </tr>



                                <tr>
                                <td>Email</td>
                                <td>{{formData.email}}</td>
                                </tr>

          

                                <tr>
                                <td>Country</td>
                                <td>{{country_name}}</td>
                                </tr>


                              <tr>
                                <td>Status</td>
                                <td>{{formData.status}}</td>
                              </tr>

                 
                              </tbody>
                                  </table>









                                                                

                    
                        </div>

                      </div>

                 </div>


            </div>
        </div>


        
        </div>
</template>

<script>
 import axios from 'axios';
import FieldValidateService from "@/services/FieldValidateService";
import notificationBox from "@/services/notificationBox";
import SpinnerComp from "@/components/SpinnerComp.vue";
import { mapActions, mapMutations, mapState } from 'vuex';
import { DEPENDENT_LINK_REG, EDIT_DEPENDENT_LINK_REG, LOADING, RESET_DEPENDENT_SEARCH_RESULT, SEARCH_DEPENDENT } from '@/store/storeconstants';
//import VueMultiselect from 'vue-multiselect'
//import Select2 from 'vue3-select2-component';
import vSelect from 'vue-select'

    export default {

        name:'DependentModals',
       // props:['checked_user_portal_id','checked_user_id','view_type', 'checked_user_data','checked_user_portal_data'],


           mounted(){

              // const input = document.querySelector("#telephone");
              // console.log(input);
              // intlTelInput(input,{
              //   // any initialisation options go here
              //       preferredCountries: ["gh", "us", "ca"],
                
                  
              // });

             //   console.log(localStorage.getItem("token"));

           },

        async created() {

      //    this.formData.fname = this.firstName;

         
    // this.portal =  localStorage.getItem('Portall')
    this.app_portal = localStorage.getItem("Portall");
    this.formData.portal = localStorage.getItem("Portall");
    if(this.app_portal == "Provider"){
      this.formData.status = "PENDING"; 
    }
  





   try{
       let response = await axios.get("getCountries");
     // console.log(response.data.data);
     // response.data.data.unshift({id:"",country:"Please Select Option", created_at:null,updated_at:null})   
        this.getCountries =  JSON.parse(JSON.stringify(response.data.data));
        //console.log(this.getCountries);
       
       
       
      
    }catch(error){
        console.log(error);
    }



     const user_data = JSON.parse(localStorage.getItem("users"));
      
          this.user_level = user_data.user_level;
         let user_idd = user_data.id;
         this.formData.user_id = user_idd; 

  if(this.app_portal=='Support')
  {
           try{
      const  user_dataa = {
            user_id : user_idd 
        }
       let response = await axios.post("getSupportId",user_dataa);
     // console.log(response.data.data);
     // response.data.data.unshift({id:"",country:"Please Select Option", created_at:null,updated_at:null})   
        this.formData.support_id =  JSON.parse(JSON.stringify(response.data.data));
       console.log(this.formData.support_id);
       
       
       
      
    }catch(error){
      //   console.log("5555");
        console.log(error);
    }
  }

 
  
 

        },




         data: function () {
    return {
      //userModal: null,
      link_val:"",
      country_name:"",
      searchDependent:"",
      app_portal: "",
      searchDependentErr: "",
      contactLoading:false,
      dependentLoading:false,
      emailLoading:false,
      search_email_info:"",
      search_contact_info:"",
      search_dependent_info:"",
      loading:false,
      getCountries:[],
      errorMess: "",
      succMess:"",
      SearcherrorServers:"",
      search_dependent_fail:"",
      search_contact_fail:"",
      search_email_fail:"",
      support:"",
      supportErr:"",
      searchSupportErr:"",
      searchSupport:"",
      supportLoading:"",
      clear_user: true,
      clear_all: true,
      errorClient:[],
      errorServers:[],
       modal:"modal",


   
      

      formData: {
        portal:"",
        dependent_id:"",

        fullname: "",
        gender: "",
        country_id: null,
        gps_address: "",
        city:  "",
        contact: "",
        email:  "",
        support_id:"",
        user_id:"",
        view_type:"",
        status:"ACTIVE"

        
        
      },



    //Errors  
      fullnameErr: "",
      genderErr: "",
      countryErr: "",
      cityErr: "",
      contactErr: "",
      emailErr: "",
      statusErr:"",



      // bindProps:{
      //   preferredCountries: ["GH", "US","CA","GB","NG"],
      //   name: "telephone",
      //     mode: "international",
      //     defaultCountry: "GH",
      //      dropdownOptions: {
      //     showSearchBox: true,
      //     showFlags:true,
      //   },
      // }



    };
  },


      methods: {

          ...mapActions({
            search_dependent: 'dependent/'+SEARCH_DEPENDENT,
            link_dependent_support: 'dependent/'+DEPENDENT_LINK_REG,
            edit_link_dependent_support:'dependent/'+EDIT_DEPENDENT_LINK_REG
          }),

  //          ...mapActions({
  //       userReg: 'user/'+USER_REG,
  //       search_user: 'user/'+SEARCH_USER,
  //       search_user_per_portal: 'user/'+SEARCH_USER_PER_PORTAL,
  //       verify_user_exist_otp:'user/'+VERIFY_USER_EXIST_OTP,
  //       supportReg: 'support/'+SUPPORT_REG,
  //       dependentReg: 'dependent/'+DEPENDENT_REG,
  //       user_portalReg: 'user_portal/'+USER_PORTAL_REG,
  //       user_portal_edit:'user_portal/'+USER_PORTAL_EDIT,
  //       user_exist_portalReg: 'user_portal/'+USER_EXIST_PORTAL_REG,
  //       search_user_id:'user/'+SEARCH_USER_ID,
  //       search_user_portal_id:'user_portal/'+SEARCH_USER_USER_PORTAL,
  //       search_employee_user_country_portal:'employee_country/'+SEARCH_EMPLOYEE_USER_COUNTRY_PORTAL,
  //       search_employee_user_provider_portal:'employee_provider/'+SEARCH_EMPLOYEE_USER_PROVIDER_PORTAL,
  //       search_employee_user_country_portal_id:'employee_country/'+SEARCH_EMPLOYEE_USER_COUNTRY_PORTAL_ID
  //  }),


         ...mapMutations({
                 reset_dependent_search_result: 'dependent/'+RESET_DEPENDENT_SEARCH_RESULT,
                 showLoading:LOADING,
         }),

  //        ...mapMutations({
  //      reset_user_id: 'user/'+GET_SEARCH_USER,
  //      reset_search_user_id:'user/'+GET_SEARCH_USER_ID,
  //      reset_search_user_portal_id:'user_portal/'+GET_SEARCH_USER_USER_PORTAL,
  //      get_employee_user_country_view_type:'employee_country/'+GET_EMPLOYEE_USER_COUNTRY_PORTAL_VIEW_TYPE,
  //      showLoading:LOADING,
  //    //  verified_username:'auth/'+SUCC_AUTH_VERIFY,
  //  }),





       async reg(){
      
      //notificationBox.successAlert("Registration Successful","An Email Has Been Sent To You. Please Click On The Link To Verify Your Account") 
       //console.log(this.formData);
       // console.log(this.errorClient);
        this.errorServers = [];
       this.errorClient = [];
       this.showLoading(true);

        this.fullnameVal(this.formData.fullname);
        this.genderVal(this.formData.gender);
        this.countryVal(this.formData.country_id);
        //this.addressVal(this.formData.address);
        this.cityVal(this.formData.city);
        this.contactVal(this.formData.contact);
        this.emailVal(this.formData.email);
        this.statusVal(this.formData.status);


   

             if(this.errorClient.length > 0)
       {
         this.showLoading(false);
          notificationBox.errorNotifications("Some Errors Exist");
          console.log(this.errorClient);
          return true;
       }

   




    if(this.dependent_support_view_type=="add"){

        

          await this.link_dependent_support(this.formData).catch(error => {
             console.log(error);
         //  this.errorServers = error.response.data.errors;
         //console.log(error.response.data.error);
           this.errorServers.push(error.response.data.error);
        //   console.log(JSON.parse(JSON.stringify(this.errorServers)));
           this.showLoading(false);
           notificationBox.errorNotifications("Some Errors Exist");
           
       });



    }
    else if(this.dependent_support_view_type=="edit")
    {
     


           await this.link_dependent_support(this.formData).catch(error => {
             console.log(error);
         //  this.errorServers = error.response.data.errors;
         //console.log(error.response.data.error);
           this.errorServers.push(error.response.data.error);
        //   console.log(JSON.parse(JSON.stringify(this.errorServers)));
           this.showLoading(false);
           notificationBox.errorNotifications("Some Errors Exist");
           
       });


    }




           if(this.errorServers.length > 0)
       {
   
      this.fullnameErr = 'fullname' in this.errorServers[0] ? this.errorServers[0].fullname[0] : '';
      this.genderErr = 'gender' in this.errorServers[0] ? this.errorServers[0].gender[0] : '';
      this.countryErr = 'country_id' in this.errorServers[0] ? this.errorServers[0].country_id[0] : '';
      this.cityErr = 'city' in this.errorServers[0] ? this.errorServers[0].city[0] : '';
      this.contactErr = 'contact' in this.errorServers[0] ? this.errorServers[0].contact[0] : '';
      this.emailErr = 'email' in this.errorServers[0] ? this.errorServers[0].email[0] : '';
      this.statusErr = 'status' in this.errorServers[0] ? this.errorServers[0].status[0] : '';


     
           return true;
       }




      else{
       
       this.errorServers = [];

     //This Get The Email And Sends It As State And Put It In Login
      // const authRegData = {
      //   is_verified: false,
      //   username: this.email
      //  };

    //   this.verified_username(authRegData);

      //notificationBox.succNotifications("User Registration Sucessful");
      this.showLoading(false);
        
        if(this.dependent_support_view_type=="add"){
        this.clear_all_user();
       
        notificationBox.succNotifications("Dependent Added Successfully");
         this.$emit("dependent_added");
        
        }else{

           notificationBox.succNotifications("Dependent Edited Successfully");

                this.$refs.DependentSupportmodal.click();
           
       
           // $('#userModal').modal('hide');
           this.$emit("dependent_added");
          //  $('#ModalId').modal('hide');
             
        }
       // this.$router.push('/reg-info');
       // console.log("Greaaaaaaaaaaaat");
       }
       
    
    },





     clear_main_user(){
        this.errorServers = [];
      this.clear_user = false;
      this.formData.fullname = "";
      this.formData.gender = "";
      this.country_name = "";
      this.formData.gps_address = "";
      this.formData.country_id = "";
      this.formData.city = "";
      this.formData.contact = "";
      this.formData.email = "";
      this.formData.status = "";
      this.formData.support_id = "";
      this.formData.dependent_id = "";
      this.clear_user = true;
    },






    clear_all_user(){
      this.clear_main_user();
      this.clear_all = false;
      this.existing_user = "";


            

        this.clear_all = true;
    },


    remove_all(){

        this.clear_all_user();

    },



    //     existing_userVal(value) {

    //    let field = "Existing User";


    //   if(this.clear_all && FieldValidateService.reqVal(value,field)){
    //     this.existing_userErr = FieldValidateService.reqVal(value,field);
    //     this.errorClient.push(this.existing_userErr);
    //   }else{
    //     this.existing_userErr =""; 
    //       this.errorClient.pop(this.existing_userErr);
    //   }
    // },





    //     searchVal(value) {

    //   let field = "Search";
     
    //  if(this.clear_user && this.is_existing_user && FieldValidateService.reqValSearch(value, field))
    //  {
    //    this.searchErr = FieldValidateService.reqValSearch(value,field);
    //     this.errorClient.push(this.searchErr);
    //  }else{

    //        this.searchErr =""; 
    //       this.errorClient.pop(this.searchErr);

    //  }
    // },






      fullnameVal(value){

      let max = 500;
      let field = "Full Name"
    //  let value = e.target.value

      //console.log("YES");

         if(this.clear_user && !this.formData.dependent_id && FieldValidateService.reqMaxStringVal(value, field,max))
     {
       this.fullnameErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.fullnameErr);
     }else{



            if(this.errorClient.includes(this.fullnameErr))
           {
              let pos = this.errorClient.indexOf(this.fullnameErr);
              this.errorClient.splice(pos,1);
              this.fullnameErr =""; 
           }

     }


    },






     genderVal(value) {

      let max = 100;
      let field = "Gender";
     
     if(this.clear_user && !this.formData.dependent_id && FieldValidateService.reqMaxStringVal(value,field,max))
     {
       this.genderErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.genderErr);
     }else{


              if(this.errorClient.includes(this.genderErr))
           {
              let pos = this.errorClient.indexOf(this.genderErr);
              this.errorClient.splice(pos,1);
              this.genderErr =""; 
           }


     }
    },







    countryVal(value){

             let field = "Country";
             if(typeof value == "object" && value!=null)
             {
             value = value.id;
             }


      if(this.clear_user && !this.formData.dependent_id && FieldValidateService.reqVal2(value,field)){
        this.countryErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.countryErr);
      }else{


            if(this.errorClient.includes(this.countryErr))
           {
              let pos = this.errorClient.indexOf(this.countryErr);
              this.errorClient.splice(pos,1);
              this.countryErr =""; 
           }

      
      }

    },











    cityVal(value){

           let max = 250;
           let field = "City"

         if(this.clear_user && !this.formData.dependent_id && FieldValidateService.reqMaxStringVal(value, field,max))
     {
       this.cityErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.cityErr);
     }else{


              if(this.errorClient.includes(this.cityErr))
           {
              let pos = this.errorClient.indexOf(this.cityErr);
              this.errorClient.splice(pos,1);
              this.cityErr =""; 
           }


     }


    },








    contactVal(){

            //console.log(phoneObject);

      //       if (phoneObject?.formatted) {
      //   this.formData.contact = phoneObject.formatted
      // }

      // value = this.formData.contact;
    let value = this.formData.contact;
      console.log(value);

           
           let field = "Contact"
           this.contact_edit_typed = "YES";

         if(this.clear_user && !this.formData.dependent_id && FieldValidateService.reqValNum(value, field))
     {
       this.contactErr = FieldValidateService.reqValNum(value,field);
        this.errorClient.push(this.contactErr);
     }else{


            if(this.errorClient.includes(this.contactErr))
           {
              let pos = this.errorClient.indexOf(this.contactErr);
              this.errorClient.splice(pos,1);
              this.contactErr =""; 
           }


       let contact_field = "contact";
      let email_field = null;
       let dependent_field = null;
       console.log("okkkkkk");
      if(this.dependent_support_view_type=="add"){
    this.searchDependentVal(value,dependent_field,contact_field,email_field)
      }


 

     }

    },








    emailVal(value){


                 let field = "Email"
                 this.email_edit_typed = "YES";

         if(this.clear_user && !this.formData.dependent_id && FieldValidateService.onlyEmail(value, field))
     {
       this.emailErr = FieldValidateService.onlyEmail(value,field);
        this.errorClient.push(this.emailErr);
     }else{


            if(this.errorClient.includes(this.emailErr))
           {
              let pos = this.errorClient.indexOf(this.emailErr);
              this.errorClient.splice(pos,1);
              this.emailErr =""; 
           }

     }


    },



     

     statusVal(value){

             let field = "Status";
             if(typeof value == "object" && value!=null)
             {
             value = value.id;
             }


      if(this.clear_user && !this.formData.dependent_id && FieldValidateService.reqVal2(value,field)){
        this.statusErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.statusErr);
      }else{


            if(this.errorClient.includes(this.statusErr))
           {
              let pos = this.errorClient.indexOf(this.statusErr);
              this.errorClient.splice(pos,1);
              this.statusErr =""; 
           }

      
      }

    },







 async getSearchedSupport(value){
        try{
         
         this.supportLoading = true;

       let response = await axios.get(`getSearchSupports?searchVal=${value}`);
     // console.log(response.data.data);
     // response.data.data.unshift({id:"",country:"Please Select Option", created_at:null,updated_at:null})   
     this.supportLoading = false;
        const getSupports =  JSON.parse(JSON.stringify(response.data.data));
        this.support =  getSupports.fullname+" - "+ getSupports.email+" - "+getSupports.contact;
        this.formData.support_id = getSupports.support_id;
       
       
       
      
    }catch(error)
    {

      this.supportLoading = false;

       this.searchSupportErr = error.response.data.error;
    }
   },




 searchSupportVal(value)
 {

     let field = "Search Support By Contact/ Email";
             if(typeof value == "object" && value!=null)
             {
             value = value.id;
             }


      if(this.clear_user && !this.formData.dependent_id && FieldValidateService.reqVal2(value,field)){
        this.searchSupportErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.searchSupportErr);
      }else{


            if(this.errorClient.includes(this.searchSupportErr))
           {
              let pos = this.errorClient.indexOf(this.searchSupportErr);
              this.errorClient.splice(pos,1);
              this.searchSupportErr =""; 
           }

             this.getSearchedSupport(value);


      
      }


 },



    async searchDependentVal(value,dependent_field,contact_field,email_field){
      
           
            let data = {
              search: value,
              support_id: this.formData.support_id
             };

      
                     if(contact_field != null){
        
        this.contactLoading = true;
      }
      else if(dependent_field != null){
        
        this.dependentLoading = true;
      }
      else if(email_field != null){
        this.emailLoading = true;
      } 


      await this.search_dependent(data).catch(error => {
        //this.checkLoading = false;

              if(contact_field != null){
        
        this.contactLoading = false;
         this.search_contact_fail = error.response.data.error;
      }
      else if(dependent_field != null){
        
        this.dependentLoading = false;
         this.search_dependent_fail = error.response.data.error;
      }
      else if(email_field != null){
        this.emailLoading = false;
         this.search_email_fail = error.response.data.error;
      }
         
           console.log(error);
          //   this.checkLoading = false;
           this.reset_dependent_search_result();


          
    

       });

        //console.log(this.search_message);

        if(this.search_message)
       {
        if(contact_field != null){
        
        this.contactLoading = false;
         this.search_contact_fail = "";
      }
      else if(dependent_field != null){
        
        this.dependentLoading = false;
        this.search_dependent_fail = "";
      }
      else if(email_field != null){
        this.emailLoading = false;
        this.search_email_fail = "";
      }
     
        if(this.search_message != "Dependent Not Found" && this.dependent_support_view_type == "add"){
          
 
               console.log(this.dependent_support_view_type);
                this.formData.dependent_id = this.search_dependent_result.dependent_idd;
               this.formData.email = this.search_dependent_result.email;
                this.formData.contact = this.search_dependent_result.contact;
                this.formData.fullname = this.search_dependent_result.fullname;
                this.formData.gender = this.search_dependent_result.gender;
                this.formData.country_id = this.search_dependent_result.country_id;
                this.formData.city = this.search_dependent_result.city;
                console.log(this.formData.contact)
                   if(contact_field != null)
                   {
                this.search_email_info = "";
                this.search_dependent_info = "";
                this.search_email_fail = "";
                this.search_dependent_fail = "";
                if(this.search_message != "You Are Already Providing Support To This Dependent")
                {
                this.search_contact_info = this.search_message;
                }
                else{
                  this.search_contact_fail = this.search_message;
                }
                
                   }
                   else if(dependent_field != null){

                    this.search_email_info = "";
                    this.search_contact_info = "";
                    this.search_email_fail = "";
                    this.search_contact_fail = "";
                     if(this.search_message != "You Are Already Providing Support To This Dependent")
                {
                this.search_dependent_info = this.search_message;
                }
                else{
                  this.search_dependent_fail = this.search_message;
                }

                   }
                   else if(email_field != null)
                   {
                    this.search_dependent_info = "";
                    this.search_contact_info = "";
                    this.search_contact_fail = "";
                    this.search_dependent_fail = "";
                       if(this.search_message != "You Are Already Providing Support To This Dependent")
                {
                this.search_email_info = this.search_message;
                }
                else{
                  this.search_email_fail = this.search_message;
                }
                    
                   }
        }
        else{
          console.log('Not Found');
             this.search_email_info =  "";
              this.search_contact_info = "";
              this.search_dependent_info = "";
              this.search_dependent_fail = "Search Not Found Please Fill Dependent Details";
              console.log(this.search_dependent_fail);
              this.reset_dependent_search_result();
           //   this.formData.password = "";
             //   this.formData.password_confirmation = "";
               // this.userPortData.user_level = "";

        }
        

  
       }
      //  else{


      //    // this.checkLoading = false;
      //      if(contact_field != null){
        
      //   this.contactLoading = false;
      // }
      // else if(email_field != null){
      //   this.emailLoading = false;
      // }
      //   this.is_existing = "NO";
      //       this.search_email_info =  "";
      //         this.search_contact_info = "";
      //          //    this.formData.password = "";
      //          // this.formData.password_confirmation = "";
      //           // this.userPortData.user_level = "";
      //            this.has_password = false;

      //         // if(contact_field != null)
      //         //      {
      //         //  this.formData.email = "";
      //         //      }
      //         //        else if(email_field != null)
      //         //      {
      //         //        this.formData.contact = "";
      //         //      }
 
      // this.reset_user_id({});

      //  }
    },




      





  

      },

      
  computed:{


    ...mapState('dependent',['dependent_view_type','dependent_info','search_message','search_dependent_result']),
    ...mapState('dependent_support',['dependent_support_view_type','dependent_support_info']),

    // firstName(){
    //   return this.checked_user_data.fullname;
    // }
  },  

    components: {
      SpinnerComp,
      vSelect
      //VueMultiselect
     // Select2
    },

    watch:{

 


    async "searchDependent"(curValue){

          let contact_field = null;
      let email_field = null;
       let dependent_field = "dependent";
      if(this.dependent_support_view_type=="add"){
    this.searchDependentVal(curValue,dependent_field,contact_field,email_field)

    }
    }, 


   


        async "formData.email"(curValue) {
  
     
      let contact_field = null;
      let email_field = "email";
      let dependent_field = null;
      if(this.dependent_support_view_type=="add"){
     this.searchDependentVal(curValue,dependent_field,contact_field,email_field)
      }

  

  
 
    

   
 
    },


        dependent_support_view_type(value){
          
           if(value == "add"){
            this.formData.view_type = value;
            this.formData.fullname =""
            this.formData.gender = ""
            this.formData.country_id = ""
           // this.formData.address = ""
            //this.formData.state = ""
            this.formData.city = ""
            //this.formData.zip = ""
            this.formData.contact = ""
            this.formData.email = ""
          
            this.formData.password = ""
            this.formData.password_confirmation = ""
            this.formData.user_level = ""

           }
        },

        dependent_support_info(value){
       
         let data = value;
            //console.log(data);
            this.formData.dependent_id = data.dependent_id;
            this.formData.fullname = data.fullname;
            // this.formData.lname = data.lname
            // this.formData.oname = data.oname
            this.formData.gender = data.gender;
            this.formData.country_id = data.country_id;
            this.country_name = data.country;
            //this.formData.address = data.address
            //this.formData.state = data.state
            this.formData.city = data.city;
            //this.formData.zip = data.zip
            this.formData.contact = data.contact;
            this.formData.email = data.email;
            this.formData.status = data.dependent_support_status;
          
       
          
          
        },


    //     async checked_user_portal_data(value){
    //          this.showLoading(false);
            
    //         let data = JSON.parse(JSON.stringify(value));
    //          //console.log(data)
         



    //         if(this.formData.user_level == "COUNTRY"){

    //     await this.search_employee_user_country_portal(this.formData.user_idy).catch(error => {
    //       // this.SearcherrorServers = error.response.data.error;
    //        console.log(error);
    //        this.showLoading(false);
    //       // notificationBox.errorNotifications(error.response.data.error);

    //    });

    //  //  this.user_portal_table_data = this.search_user_portal_id_result;
              
    //         }



    //          else  if(this.formData.user_level == "LOCAL"){

    //     await this.search_employee_user_provider_portal(this.formData.user_idy).catch(error => {
    //        console.log(error);
    //        this.showLoading(false);

    //    });

    //  //  this.user_portal_table_data = this.search_user_portal_id_result;
              
    //         }
           
          
    //     },

    //     existing_user(curValue) {
 

    //   this.existing_userVal(curValue);

    //   //Check iF vAL iS True Or False
    //   if (curValue === "YES") {
    //     this.is_existing_user = true;
    //      this.search = "";
    //      this.errorServers = [];
    //           this.fnameErr= "",
    //   this.lnameErr= "",
    //   this.onameErr= "",
    //   this.genderErr= "",
    //   this.countryErr= "",
    //   this.addressErr= "",
    //   this.stateErr= "",
    //   this.cityErr= "",
    //   this.zipErr= "",
    //   this.contactErr= "",
    //   this.emailErr= "",
    //   this.passwordErr= "",
    //   this.password_confirmationErr= ""
    //   } else {
    //     this.is_existing_user = false;
    //     this.search = "";
    //   this.errorServers = [];
    //   this.clear_main_user();
 

    //   }
    // },



//         async search(curValue) {
//     //  this.searchVal(curValue);

   
//  if(this.is_existing_user){
    

//             let data = {
//               search: curValue,
//       };

//        this.showLoading(true);
//       await this.search_user(data).catch(error => {
//            this.SearcherrorServers = error.response.data.error;
//            console.log(error);
//            this.showLoading(false);
//            this.reset_user_id({});
//            notificationBox.errorNotifications(error.response.data.error);

//        });

//         if(this.user_idd)
//        {
//          this.SearcherrorServers=false;
//       //  let response = await axios.post("getUser",data);
//       // console.log(response.data.data);
//       this.showLoading(false);
//       this.formData.fname = this.search_user_result.fname;
//       this.formData.lname = this.search_user_result.lname;
//       this.formData.gender = this.search_user_result.gender;
//       this.country_name = this.search_user_result.country;
//       this.formData.address = this.search_user_result.address;
//       this.formData.country_id = this.search_user_result.country_id;
//       this.formData.state = this.search_user_result.state;
//       this.formData.city = this.search_user_result.city;
//       this.formData.zip = this.search_user_result.zip;
//       this.formData.contact = this.search_user_result.contact;
//       this.formData.email = this.search_user_result.email;
//       this.formData.password = this.search_user_result.password;
//       this.formData.password_confirmation = this.search_user_result.password;
//        }
//        else{


//           this.showLoading(false);
//     //  this.user_id = this.search_user_result.id
//       // this.formData.fname = "";
//       // this.formData.lname = "";
//       // this.formData.gender = "";
//       // this.country_name = "";
//       // this.formData.address = "";
//       // this.formData.country_id = "";
//       // this.formData.state = "";
//       // this.formData.city = "";
//       // this.formData.zip = "";
//       // this.formData.contact = "";
//       // this.formData.email = "";
//       // this.formData.password = "";
//       // this.formData.password_confirmation = "";

//       this.clear_main_user();

//        }
   
//  }
//     },




    //    "formData.fname"(curValue) {
    //     this.fnameVal(curValue)
    // },

    // "formData.lname"(curValue) {
    //      this.lnameVal(curValue)
    // },

    // "formData.oname"(curValue) {
    //      this.onameVal(curValue)
    // },

    //     "formData.gender"(curValue) {
    //      this.genderVal(curValue)
    // },


    // "formData.country_id"(curValue) {
    //    this.countryVal(curValue);
    // },

    // "formData.address"(curValue) {
    //     this.addressVal(curValue);
    // },

    // "formData.state"(curValue) {
    //   this.stateVal(curValue);
    // },

    // "formData.city"(curValue) {
    //      this.cityVal(curValue);
    // },

    //     "formData.zip"(curValue) {
    //    this.zipVal(curValue);
    // },

    // "formData.contact"(curValue) {
    //    this.contactVal(curValue);
    // },

    // "formData.email"(curValue) {
    //     this.emailVal(curValue);
    // },

    // "formData.password"(curValue) {
    //    this.passwordVal(curValue);
    // },

    // "formData.password_confirmation"(curValue) {
    //     this.password_confirmationVal(curValue);      
    // },

    
    // "userPortData.user_level"(curValue) 
    // {
    //    this.user_levelVal(curValue);
    // },




    }
        
    }
</script>

<style scoped>


</style>