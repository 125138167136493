<template>

    <div class="form-v9">
	<div class="page-content" style="margin-top:-150px">
		<div class="form-v9-content" :style="{backgroundImage: 'url(' + require('@/assets/lagos-crusade-field.jpg') +')'}">
			<form class="form-detail" @submit.prevent="reg()">
				<h2><img src="@/assets/CfaN_Plain_Logo_red.png" style="width:180px; margin-top:-20px" alt="IMG"> Fire Conference Registration Form </h2>
				<div class="form-row-total">
                    <!-- <div class="form-row">
						<select
                            class="input-text"
                            @change="titleVal($event.target.value)"
                            v-model="formData.title"                            
                           >
                            <option value="">* --Please Select Your Title--</option>
                            <option value="ARCHBISHOP">ARCHBISHOP</option>
                            <option value="BISHOP">BISHOP</option>
                            <option value="APOSTLE">APOSTLE</option>
                            <option value="PROPHET">PROPHET</option>
                            <option value="EVANGELIST">EVANGELIST</option>
                            <option value="PASTOR">PASTOR</option>
                            <option value="REV">REV</option>
                            <option value="PROF">PROF</option>
                            <option value="DR">DR</option>
                            <option value="NANA">NANA</option>
                            <option value="MR">MR</option>
                            <option value="MRS">MRS</option>
                            <option value="MISS">MISS</option>
                            <option value="MAD">MAD</option>
                          </select>
                             <div
                            :class="{reg_text_error : titleErr}"
                            v-if="titleErr"
                          >
                           {{ titleErr }}
                          </div>
					</div> -->
                    
                       
					<div class="form-row">
						<input type="text"    @blur="fullnameVal($event.target.value)"
                            v-model.trim="formData.fullname"
                             class="input-text" placeholder="* Your Fullname - Surname First">
                         <div
                            :class="{reg_text_error : fullnameErr}"
                            v-if="fullnameErr"
                          >
                           {{ fullnameErr }}
                          </div>
					</div>

					<div class="form-row">
							<select
                            class="input-text"
                             @change="genderVal($event.target.value)"
                            v-model="formData.gender"  
                           >
                            <option value="">* --Please Select Your Gender--</option>
                            <option value="MALE">MALE</option>
                            <option value="FEMALE">FEMALE</option>
                          </select>
                           <div
                              :class="{reg_text_error : genderErr}"
                            v-if="genderErr"
                          >
                           {{ genderErr }}
                          </div>
					</div>



                          <div class="form-row">
						<input type="text" @blur="contactVal($event.target.value)"
                            v-model="formData.contact"  
                            class="input-text"
                            maxlength = "15"
                             placeholder="* Your Phone Number">

                             <div
                            :class="{reg_text_error : contactErr}"
                            v-if="contactErr"
                          >
                           {{ contactErr }}
                          </div>

					</div>


				</div>

				<div class="form-row-total">
        

                    <div class="form-row">
						<input type="text" @blur="emailVal($event.target.value)"
                            v-model="formData.email"  
                             class="input-text" 
                             placeholder="Your Email" 
                             >

                               <div
                            :class="{reg_text_error : emailErr}"
                            v-if="emailErr"
                          >
                           {{ emailErr }}
                          </div>
					</div>


           <div class="form-row">
						<input type="text" @blur="residentialAddressVal($event.target.value)"
                            v-model="formData.residential_address"  
                             class="input-text" 
                             placeholder="* Your Residential Address" 
                             >

                               <div
                            :class="{reg_text_error : residentialAddressErr}"
                            v-if="residentialAddressErr"
                          >
                           {{ residentialAddressErr }}
                          </div>
					</div>



          
                  <div class="form-row">
                      <input type="text"  @blur="organisationVal($event.target.value)"
                          v-model="formData.organisation"
                          class="input-text"
                            placeholder="* Your Church/Organisation" 
                            >

                            <div
                          :class="{reg_text_error : organisationErr}"
                          v-if="organisationErr"
                        >
                        {{ organisationErr }}
                        </div>
                  </div>

	
					<!-- <div class="form-row">
						<input type="password" name="comfirm-password" id="comfirm-password" class="input-text" placeholder="Comfirm Password" required>
					</div> -->
				</div>
                <div class="form-row-total">




                      <div class="form-row">
						<input type="text"  @blur="church_locationVal($event.target.value)"
                            v-model="formData.church_location"
                             class="input-text" 
                             placeholder="* Branch Of Church">
                             <div
                            :class="{reg_text_error : church_locationErr}"
                            v-if="church_locationErr"
                          >
                           {{ church_locationErr }}
                          </div>
					</div>


                      <div class="form-row">
						<input type="text"  @blur="pastor_nameVal($event.target.value)"
                            v-model="formData.pastor_name"
                             class="input-text" 
                             placeholder="* Pastor's Name">
                             <div
                            :class="{reg_text_error : pastor_nameErr}"
                            v-if="pastor_nameErr"
                          >
                           {{ pastor_nameErr }}
                          </div>
					</div>


          <div class="form-row">
						<input type="text"  @blur="position_in_churchVal($event.target.value)"
                            v-model="formData.position_in_church"
                             class="input-text" 
                             placeholder="* Position In Church">
                             <div
                            :class="{reg_text_error : position_in_churchErr}"
                            v-if="position_in_churchErr"
                          >
                           {{ position_in_churchErr }}
                          </div>
					</div>
                    
				</div>
				<div class="form-row-last">
					<input type="submit" name="register" class="register" value="Register">
				</div>
			</form>
		</div>
	</div>
    </div>

	
</template>

<script>
import axios from 'axios';
import FieldValidateService from "@/services/FieldValidateService";
//import notificationBox from "@/services/notificationBox";
import { mapActions, mapMutations, mapState } from 'vuex';
import notificationBox from '@/services/notificationBox';
import { AUTH_LOGIN, LOADING } from '@/store/storeconstants';
//import vSelect from 'vue-select'
//import Tilt from 'vanilla-tilt-vue';


  



export default {    
  name: "PastorRegistrationView",
  components: {
  //  Tilt
  //  vSelect,
  },
  async created(){

            
  },


  data: function(){
      return {
          provider_id:"",
          getProviders:[],
          errorServers:[],
          errorClient:[],
          page:"",
          route_name:"",

          formData:{
            //title:"",
            fullname:"",
            gender:"",
            contact:"",
            email:"",
            residential_address:"",
            organisation:"",
            church_location:"",
            pastor_name:"",
            position_in_church:"",
            //date:""



          },
         // titleErr:"",
          fullnameErr:"",
          genderErr:"",
          contactErr:"",
          emailErr:"",
          residentialAddressErr:"",
          organisationErr:"",
          church_locationErr:"",
                pastor_nameErr:"",
            position_in_churchErr:"",



          
      }
  },



  computed:{
    ...mapState('auth',['verified','verified_portal','verified_username'])
  },


  methods:{

    ...mapActions({
          loginAct: 'auth/'+AUTH_LOGIN,
    }),

    
     
    ...mapMutations({
       showLoading:LOADING,
   }),


    async reg(){

        this.errorServers = [];
       this.errorClient = [];
       //this.formData.date = date("Y-m-d");
       this.showLoading(true);

        this.fullnameVal(this.formData.fullname);
        this.genderVal(this.formData.gender);
        this.contactVal(this.formData.contact);
        this.emailVal(this.formData.email);
        this.residentialAddressVal(this.formData.residential_address);
        this.organisationVal(this.formData.organisation);
        this.church_locationVal(this.formData.church_location);
        this.pastor_nameVal(this.formData.pastor_name);
        this.position_in_churchVal(this.formData.position_in_church);

        
             if(this.errorClient.length > 0)
       {
         this.showLoading(false);
          notificationBox.errorNotifications("Some Errors Exist");
          console.log(this.errorClient);
          return true;
       }
     else{

        try{
            const response = await axios.post("pastors",this.formData);
            if(response.status == 200)
            {
                 this.showLoading(false);

                     this.clearFormData();
                  notificationBox.succNotifications("You Have Registered Successfully");
            }
        }catch(error){

           //  console.log(error);
           this.errorServers.push(error.response.data.error);
           
           this.showLoading(false);
           notificationBox.errorNotifications("Some Errors Exist");

                  if(this.errorServers.length > 0)
       {
          
            this.getServerError();
     
       }

        }

     }

  },





   getServerError(){
    
      //  this.titleErr = 'title' in this.errorServers[0] ? this.errorServers[0].title[0] : '';
       this.fullnameErr = 'fullname' in this.errorServers[0] ? this.errorServers[0].fullname[0] : '';
       this.genderErr = 'gender' in this.errorServers[0] ? this.errorServers[0].gender[0] : '';
       this.contactErr = 'contact' in this.errorServers[0] ? this.errorServers[0].contact[0] : '';
       this.emailErr = 'email' in this.errorServers[0] ? this.errorServers[0].email[0] : '';
       this.residentialAddressErr = 'email' in this.errorServers[0] ? this.errorServers[0].residential_address[0] : '';
       this.organisationErr = 'organisation' in this.errorServers[0] ? this.errorServers[0].organisation[0] : '';
      this.church_locationErr = 'church_location' in this.errorServers[0] ? this.errorServers[0].church_location[0] : '';
      this.pastor_nameErr = 'pastor_name' in this.errorServers[0] ? this.errorServers[0].pastor_name[0] : '';
      this.position_in_churchErr = 'position_in_church' in this.errorServers[0] ? this.errorServers[0].position_in_church[0] : '';


     
           return true;
   },




     clearFormData(){
      this.errorServers = [];
      this.errorClient = [];
      this.formData.title = "";
      this.formData.fullname="",
      this.formData.gender="",
      this.formData.contact="",
      this.formData.email="",
      this.formData.residential_address="",
      this.formData.organisation="",
      this.formData.church_location="",
      this.formData.pastor_name="",
      this.formData.position_in_church="",
      
      this.titleErr="",
      this.fullnameErr="",
      this.genderErr=""
      this.contactErr="",
      this.emailErr="",
      this.residentialAddressErr="",
      this.organisationErr="",
      this.church_locationErr="",
      this.pastor_nameErr="",
      this.position_in_churchErr="",
   
      this.clear = false;
        },

   
   titleVal(value){

     let max = 100;
      let field = "Title";
     
     if(FieldValidateService.reqMaxStringVal(value,field,max))
     {
       this.titleErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.titleErr);
     }else{

           if(this.errorClient.includes(this.titleErr))
           {
            let pos = this.errorClient.indexOf(this.titleErr);
           this.errorClient.splice(pos,1);
            this.titleErr =""; 
           }
          
          

     }

   },




      fullnameVal(value){

     let max = 500;
      let field = "Fullname";
     
     if(FieldValidateService.reqMaxStringVal(value,field,max))
     {
       this.fullnameErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.fullnameErr);
     }else{

           if(this.errorClient.includes(this.fullnameErr))
           {
            let pos = this.errorClient.indexOf(this.fullnameErr);
           this.errorClient.splice(pos,1);
            this.fullnameErr =""; 
           }
          
          

     }

   },


   
      genderVal(value){

     let max = 100;
      let field = "Gender";
     
     if(FieldValidateService.reqMaxStringVal(value,field,max))
     {
       this.genderErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.genderErr);
     }else{

           if(this.errorClient.includes(this.genderErr))
           {
            let pos = this.errorClient.indexOf(this.genderErr);
           this.errorClient.splice(pos,1);
            this.genderErr =""; 
           }
          
          

     }

   },


   
      contactVal(value){

               let field = "Contact"

               let max = 15;

        //   let value = this.formData.contact;

         if(FieldValidateService.reqMaxNumVal(value, field, max))
     {
       this.contactErr = FieldValidateService.reqMaxNumVal(value, field, max);
        this.errorClient.push(this.contactErr);
     }else{

          if(this.errorClient.includes(this.contactErr))
        {
        let pos = this.errorClient.indexOf(this.contactErr);
        this.errorClient.splice(pos,1);
        }
           this.contactErr =""; 


     }

   },


   
      emailVal(value){

         let field = "Email"

         if(FieldValidateService.onlyEmail(value, field))
     {
       this.emailErr = FieldValidateService.onlyEmail(value,field);
        this.errorClient.push(this.emailErr);
     }else{

          if(this.errorClient.includes(this.contactErr))
        {
        let pos = this.errorClient.indexOf(this.contactErr);
        this.errorClient.splice(pos,1);
        }
           this.emailErr =""; 
         

     }


   },





         residentialAddressVal(value){

     let max = 500;
      let field = "Residential Address";
     
     if(FieldValidateService.reqMaxStringVal(value,field,max))
     {
       this.residentialAddressErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.residentialAddressErr);
     }else{

           if(this.errorClient.includes(this.residentialAddressErr))
           {
            let pos = this.errorClient.indexOf(this.residentialAddressErr);
           this.errorClient.splice(pos,1);
            this.residentialAddressErr =""; 
           }
          
          

     }

   },


   
      organisationVal(value){

     let max = 500;
      let field = "Organisation";
     
     if(FieldValidateService.reqMaxStringVal(value,field,max))
     {
       this.organisationErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.organisationErr);
     }else{

           if(this.errorClient.includes(this.organisationErr))
           {
            let pos = this.errorClient.indexOf(this.organisationErr);
           this.errorClient.splice(pos,1);
            this.organisationErr =""; 
           }
          
          

     }

   },


   
      church_locationVal(value){

     let max = 500;
      let field = "Location";
     
     if(FieldValidateService.reqMaxStringVal(value,field,max))
     {
       this.church_locationErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.church_locationErr);
     }else{

           if(this.errorClient.includes(this.church_locationErr))
           {
            let pos = this.errorClient.indexOf(this.church_locationErr);
           this.errorClient.splice(pos,1);
            this.church_locationErr =""; 
           }
          
          

     }

   },






   
      pastor_nameVal(value){

     let max = 500;
      let field = "Pastor's Name";
     
     if(FieldValidateService.reqMaxStringVal(value,field,max))
     {
       this.pastor_nameErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.pastor_nameErr);
     }else{

           if(this.errorClient.includes(this.pastor_nameErr))
           {
            let pos = this.errorClient.indexOf(this.pastor_nameErr);
           this.errorClient.splice(pos,1);
            this.pastor_nameErr =""; 
           }
          
          

     }

   },






      position_in_churchVal(value){

      let max = 500;
      let field = "Position In Church";
     
     if(FieldValidateService.reqMaxStringVal(value,field,max))
     {
       this.position_in_churchErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.position_in_churchErr);
     }else{

           if(this.errorClient.includes(this.position_in_churchErr))
           {
            let pos = this.errorClient.indexOf(this.position_in_churchErr);
           this.errorClient.splice(pos,1);
            this.position_in_churchErr =""; 
           }
          
          

     }

   },



  }
}
</script>

<style scoped>
/**
   *  Font  CSS
   */
  @import "../assets/p_registration/css/nunito-font.css";
   /**
   *  Font Awesome  CSS
   */
  @import "../assets/p_registration/css/style.css";
 
</style>