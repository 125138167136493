import actions from "./actions";
import mutations from "./mutations";
export default {
    namespaced:true,
    state(){
        return{
            dependent_id:"",
            dependent_idd:"",
            dependent_view_type:"",
            dependent_info:{},
            search_dependent_result:{},
            search_message:""
        }
    },

    mutations,
    actions,
}