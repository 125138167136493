<template>
    <div>
        <SideBarComp/>
        <NavBarComp/>
        <div class="content-wrap">
        <div class="main">
            <div class="section__content section__content--p30">
                <div class="container-fluid">  
                    <HeaderComp page="Convert Report" pageDesc="report"/>                
                    <section id="main-content">
                        <div class="row ps-3 pe-3">
                            <div class="col-md-12">                               
                                <div class="row">

                                   
                                    <!-- <PerPageComponent @perpageValue="perpageval" />

                                    <TableFilter @statusValue="statusval" portall="" />

                    

                                    <TableSearch @searchValue="searchval" />


                                  
    


                                    <ActionButton  @deleteRecords="deleteRecords" :num_checked="checked.length" portall="" />









                                
                                    <div class="col-2 text-end">
                        

                                                   
                                        <a class="btn btn-outline-success" @click.prevent="crusade_typeAdd" ref="crusade_type_add_ref" data-bs-toggle="modal"  href="#crusade_typeModal" v-if="app_portal=='MAIN'">
                                            <i class="fas fa-plus"></i> Add Crusade Type
                                        </a>   


                                           <a style="display:none" class="btn btn-outline-success" ref="crusade_type_ref" data-bs-toggle="modal"  href="#crusade_typeModal">
                                            <i class="fas fa-plus"></i> Crusade Type Ref
                                        </a>   

                              

                                        
                                    </div>


                            


                                    <SelectAllComponent @selRecords="selectAllRecords" :num_checked="checked.length" v-if="selectPage" /> -->


                                </div>
                            </div>


                            <div class="card shadow-sm" id="stylized">
                              <form class="register-form" @submit.prevent="searchConvert()">

                                <div class="card-body">



                              <div class="row form_space" style="margin-bottom:10px" v-if="app_portal=='MAIN' ||  app_portal=='AREA'">
                    <div class="col" v-if="app_portal=='MAIN'">
                                <label><span class="text_error">*</span>Area</label>
                             <div class="input-group">
                          
                         <v-select :options="getDenominations"  class="form-control select1" v-model="formData.denomination_id"  :reduce="denomination => denomination.id" label="denomination" @option:selected="denominationVal" />

                                                  
                        
                        </div>

                          <div
                            :class="{ text_error: denominationErr }"
                            v-if="denominationErr"
                          >
                            {{ denominationErr }}
                          </div>
                        </div>



                 
                      <div class="col" v-if="(app_portal=='MAIN' || app_portal=='AREA') && formData.denomination_id != 'ALL'">
                                <label><span class="text_error">*</span>District</label>
                             <div class="input-group">
                          

                         <v-select :options="getChurches" class="form-control select1" v-model="formData.church_id"  :reduce="church => church.id" label="church" @option:selected="churchVal" />

                                                  
                        
                        </div>

                          <div
                            :class="{ text_error: churchErr }"
                            v-if="churchErr"
                          >
                            {{ churchErr }}
                          </div>
                        </div>


                    </div>


                             <div class="row">

                               <div class="col">
                              <label>Search Type</label>
                            <div class="input-group">
                          
                          <select
                            class="form-control"
                            :class="{ form_error: search_typeErr}"
                             @change="search_typeVal($event.target.value)"
                            v-model.trim="formData.search_type">
                            <option value="STATIC">STATIC</option>
                            <!-- <option value="STATIC">STATIC</option> -->
                            <!-- <option value="DYNAMIC">DYNAMIC</option> -->
                          </select>
                          
                          
                        </div>
                        <div
                            :class="{ text_error: search_typeErr}"
                            v-if="search_typeErr"
                          >
                            {{ search_typeErr }}
                          </div>
                      </div>

                      


                          <div class="col">
                                <label><span class="text_error">*</span>Search By</label>
                             <div class="input-group" style="width:100%">
                          

                         <v-select :options="getSearchBy"   class="form-control select1" v-model="formData.search_by"  :reduce="search_by => search_by.search_by" label="search_by_act_name" @option:selected="search_byVal" />

                                                  
                        
                        </div>

                          <div
                            :class="{ text_error: search_byErr }"
                            v-if="search_byErr"
                          >
                            {{ search_byErr }}
                          </div>

                      </div>







                   <div class="col" v-if="formData.search_type=='DYNAMIC'">
                                <label><span class="text_error">*</span>Select Column To View</label>
                             <div class="input-group" style="width:100%">
                          

                         <v-select :options="getColumns" multiple  class="form-control select1" v-model="formData.column"  :reduce="column => column" label="column_view" @option:selected="columnVal" />

                                                  
                        
                        </div>

                          <div
                            :class="{ text_error: columnErr }"
                            v-if="columnErr"
                          >
                            {{ columnErr }}
                          </div>

                      </div>

         



                     




                    </div>

                          <div class="row">



                    <div class="col" v-if="formData.search_by != 'ALL'">
                        <label><span class="text_error">*</span>Search Value</label>
                        <div class="input-group">
                          

                          <input
                            type="text"
                            class="form-control"
                            :class="{ form_error: search_valueErr}"
                            placeholder="Search Value"
                            @blur="search_valueVal($event.target.value)" 
                            v-model.trim="formData.search_value"
                          />
                         
                        </div>
                         <div
                            :class="{ text_error: search_valueErr  }"
                           v-if="search_valueErr">
                          {{ search_valueErr }} 
                          </div>
                      </div>





                            <div class="col">
                              <label>View Type</label>
                            <div class="input-group">
                          
                          <select
                            class="form-control"
                            :class="{ form_error: view_typeErr}"
                             @change="view_typeVal($event.target.value)"
                            v-model.trim="formData.view_type">
                            <option value="">--Choose An Option--</option>
                            <option value="GRID">GRID</option>
                            <option value="REPORT">REPORT</option>
                          </select>
                          
                          
                        </div>
                        <div
                            :class="{ text_error: view_typeErr}"
                            v-if="view_typeErr"
                          >
                            {{ view_typeErr }}
                          </div><br/>

                            <button type="submit" class="btn btn-primary">Generate Report</button>

                      </div>


              


                          



                          </div>

                         
                                   
                                </div>
                                 </form>


                            <!-- Table Goes Here -->

                            </div>

                              <div class="col-md-12" v-if="formData.view_type=='GRID'"> 
                              <div class="row">

                                   
                                    <PerPageComponent @perpageValue="perpageval" />

                                    <!-- <TableFilter @statusValue="statusval" portall="" /> -->

                    

                                    <TableSearch @searchValue="searchval" />


                                  
    


                                    <ActionButton  @deleteRecords="deleteRecords" :num_checked="checked.length" portall="" />









                                
                                         <div class="col-3 text-end">
                        

                                        <div v-if="grid_converts.length">Search Result/Total:{{ total }} / {{ this.totalConverts }}</div>

                                       
                                        <div v-if="getsearch">Filter Text : {{ getsearch }} </div>
                                        <!-- <a class="btn btn-outline-success" @click.prevent="crusade_typeAdd" ref="crusade_type_add_ref" data-bs-toggle="modal"  href="#crusade_typeModal" v-if="app_portal=='MAIN'">
                                            <i class="fas fa-plus"></i> Add Crusade Type
                                        </a>   


                                           <a style="display:none" class="btn btn-outline-success" ref="crusade_type_ref" data-bs-toggle="modal"  href="#crusade_typeModal">
                                            <i class="fas fa-plus"></i> Crusade Type Ref
                                        </a>    -->

                              

                                        
                                    </div>


                            


                                    <SelectAllComponent @selRecords="selectAllRecords" :num_checked="checked.length" v-if="selectPage" />


                                </div>
                        </div>



                         <div class="card shadow-sm">
                                <div class="card-body" v-if="formData.view_type=='GRID'">
                                    <div class="table-responsive table-responsive-data2">
                                    



                                       <table class="table table-hover table-striped" v-if="formData.search_type == 'STATIC'">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                                <!-- <th scope="col">#</th> -->
                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('converts.fulname')">Fullname</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='converts.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='converts.fullname'">&darr;</span>
                                                </th>


                                              <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('denominations.denomination')">Area</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='denominations.denomination'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='denominations.denomination'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('churches.church')">District</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='churches.church'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='churches.church'">&darr;</span>
                                                </th>


                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('converts.gender')">Gender</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='converts.gender'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='converts.gender'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('converts.residence_phone_number')">Residence / Personal Phone Number</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='converts.residence_phone_number'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='converts.residence_phone_number'">&darr;</span>
                                                </th>


                                                
                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('converts.first_time_decision_for_christ')">First Time Decision For Christ</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='converts.first_time_decision_for_christ'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='converts.first_time_decision_for_christ'">&darr;</span>
                                                </th>


                                     

                                               


                                                <th scope="col">
                                                Action
                                                </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(cov) in grid_converts" :key="cov.id" :class="isChecked(cov.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="cov.id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{cov.fullname}}</td>
                                                    <td>{{cov.denomination }}</td>
                                                    <td>{{cov.church }}</td>
                                                    <td>{{cov.gender }}</td>
                                                    <td>{{cov.residence_phone_number }}</td>
                                                    <td>{{cov.first_time_decision_for_christ }}</td>
                                                    <td>
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                                            <li class="d-flex">                                                    
                                                                <div class="btn"  title="edit" @click="convertEdit(cov.id)">
                                                                    <i class="fa-solid fa-pencil"></i>
                                                                </div>                                                  
                                                                <div onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" class="btn" title="delete" @click="deleteSingleRecord(cov.id)">
                                                                    <i class="fa-solid fa-trash"></i>
                                                                </div>                                                  
                                                                <a class="btn" title="view" @click="convertView(cov.id)">
                                                                    <i class="fa-solid fa-eye"></i>
                                                                </a>
                                                            </li>
                                                        </div>
                                                    </td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>








                                           <table class="table table-hover table-striped" v-if="formData.search_type == 'DYNAMIC'">
                                            <thead>
                                              
                                                <tr>
                                               
                                                <!-- <th scope="col">#</th> -->
                                                <th scope="col"><input type="checkbox" v-model="selectPage" /></th>

                                                <th scope="col"  v-for="(col_item,index) in formData.column" :key="index" >
                                                <a href="#" @click.prevent="change_sort('col_item.column_field')">ME</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='col_item.column_field'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='col_item.column_field'">&darr;</span>
                                                </th>


                                              <!-- <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('denominations.denomination')">Denomination</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='denominations.denomination'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='denominations.denomination'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('churches.church')">Church</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='churches.church'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='churches.church'">&darr;</span>
                                                </th>


                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('converts.gender')">Gender</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='converts.gender'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='converts.gender'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('converts.residence_phone_number')">Residence / Personal Phone Number</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='converts.residence_phone_number'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='converts.residence_phone_number'">&darr;</span>
                                                </th>


                                                
                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('converts.first_time_decision_for_christ')">First Time Decision For Christ</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='converts.first_time_decision_for_christ'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='converts.first_time_decision_for_christ'">&darr;</span>
                                                </th> -->


                                     

                                               


                                                <th scope="col">
                                                Action
                                                </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(cov) in grid_converts" :key="cov.id" :class="isChecked(cov.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="cov.id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    
                                                    <td v-for="(col_itemm,index) in formData.column" :key="index">
                                                      {{cov.col_itemm.column}}
                                                    </td>
                                                    <!-- <td>{{cov.denomination }}</td>
                                                    <td>{{cov.church }}</td>
                                                    <td>{{cov.gender }}</td>
                                                    <td>{{cov.residence_phone_number }}</td>
                                                    <td>{{cov.first_time_decision_for_christ }}</td> -->
                                                    <td>
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                                            <li class="d-flex">                                                    
                                                                <div class="btn"  title="edit" @click="convertEdit(cov.id)">
                                                                    <i class="fa-solid fa-pencil"></i>
                                                                </div>                                                  
                                                                <div onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" class="btn" title="delete" @click="deleteSingleRecord(cov.id)">
                                                                    <i class="fa-solid fa-trash"></i>
                                                                </div>                                                  
                                                                <a class="btn" title="view" @click="convertView(cov.id)">
                                                                    <i class="fa-solid fa-eye"></i>
                                                                </a>
                                                            </li>
                                                        </div>
                                                    </td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>



                                    </div>
                                          <div class="row mt-4">
                                             <VueTailwindPagination
                                            :current="currentPage"
                                            :total="total"
                                            :per-page="perpage"
                                            @page-changed="pageChange($event)"
                                            />
                                    </div>
                                </div>




                            

                                            
                                            
                                            
                                            <div class="container" v-if="formData.view_type=='REPORT'">
                                        <div class="row gutters">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div class="custom-actions-btns mb-5">
                                            <!-- <a href="#" class="btn btn-primary">
                                                <i class="icon-download"></i> Download
                                            </a> -->
                                            <a href="#" @click="printDiv" class="btn btn-secondary">
                                                <i class="icon-printer"></i> Print
                                            </a>
                                        </div>
                                    </div>
                                </div>

        <vue-easy-print tableShow ref="easyPrint" >
        <div class="row gutters" id="printableArea">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="card">
                    <div class="card-body p-0">
                        <div class="invoice-container">
                            <div class="invoice-header">
                                <!-- Row start -->
                        
                                <!-- Row end -->
                                <!-- Row start -->
                                <div class="row gutters">
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                        <img src="@/assets/pent_logo.jpg" style="height: 150px;"/>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <address class="text-right">
                                                {{ org_name   }}.<br>
                                                {{ app_portal }}.<br>
                                              
                                            </address>
                                    </div>
                                </div>
                                <!-- Row end -->
                                <!-- Row start -->
                                <div class="row gutters">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div class="invoice-details">
                                            <div class="row">
                                              <div class="col" v-if='report_converts.length > 0'>
                                                    <b>Total / Total Converts:</b>
                                                    {{ report_converts.length }} / {{ totalConverts }}
                                                    </div>
                                              <div class="col" v-else>
                                                    <b>Total:</b>
                                                    0
                                                    </div>

                                                <div class="col" v-if="app_portal == 'MAIN'">
                                                    <b>Area:</b>
                                                    {{ formData.denomination }}
                                                  </div>
                                                <div class="col" v-if="app_portal == 'MAIN' || app_portal == 'AREA'">
                                                    <b>District:</b>
                                                    {{ !formData.church ? "ALL" : formData.church }}
                                                 </div>
                                               
                                                </div>

                                                   <div class="row">

                                                     <div class="col">
                                                    <b>Search By:</b>
                                                    {{ formData.search_by }}
                                                    </div>


                                                  <div class="col" v-if="formData.search_value == ''">
                                                    <b>Search Value:</b>
                                                     ALL
                                                    </div>

                                                       <div class="col" v-else>
                                                    <b>Search Value:</b>
                                                    {{ formData.search_value }}
                                                    </div>


                                                    

                                                   </div>
                                            <!-- <addr>
                                                    From Date<br>
                                                    Denominatio
                                                </address> -->
                                        </div>
                                      
                                    </div>
                                    <!-- <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                        <div class="invoice-details">
                                            <div class="invoice-num">
                                                <div>Invoice - #009</div>
                                                <div>September 12th 2022</div>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                                <!-- Row end -->
                            </div>
                            <div class="invoice-body">
                                <!-- Row start -->
                                <div class="row gutters">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="table-responsive">
                                            <table class="table custom-table m-0">
                                                <thead>
                                                    <tr>
                                                        <th v-if="app_portal == 'MAIN'">
                                                            Area

                                                        </th>
                                                        <th v-if="app_portal == 'MAIN' || app_portal == 'AREA'">
                                                            District

                                                        </th>
                                                        <th>Fullname</th>
                                                        <th>Age</th>
                                                        <th>Gender</th>
                                                        <th>Location</th>
                                                        <th>Residence Phone Number</th>
                                                        <th>First Time Decision</th>
                                                        <th>Convert's Need</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(cov) in report_converts" :key="cov.id">
                                                        <td v-if="app_portal == 'MAIN'">{{ cov.denomination }}</td>
                                                      
                                                        <td  v-if="app_portal == 'MAIN' || app_portal == 'AREA'">{{ cov.church }}</td>
                                                        <td>{{cov.fullname}}</td>
                                                        <td>{{cov.age}}</td>
                                                        <td>{{cov.gender}}</td>
                                                        <td>{{cov.location}}</td>
                                                        <td>{{cov.residence_phone_number}}</td>
                                                        <td>{{cov.first_time_decision_for_christ}}</td>
                                                        <td>{{cov.convert_needs}}</td>
                                                       
                                                    </tr>
                                                   
                                                    <tr>
                                                        <!-- <td>&nbsp;</td> -->
                                                        <td>
                                                          
                                                            <h5 class="text-success"><strong>Total</strong></h5>
                                                        </td>
                                                        <td colspan="8" v-if="report_converts.length > 0">
                                                         
                                                            <h5 class="text-success"><strong>{{report_converts.length}}</strong></h5>
                                                        </td>
                                                        <td colspan="8" v-else>
                                                         
                                                            <h5 class="text-success"><strong>0</strong></h5>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <!-- Row end -->
                            </div>
                            <div class="invoice-footer">
                                {{ year }}.  GREATER ACCRA UNLEASHED CRUSADE (CoP)
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </vue-easy-print>
    </div>






                         </div>



                         




                        </div>
                    </section>
                    <FooterComp/>
                </div>
            </div>
        </div>
        </div> 

  


        <!-- <UserModals @userAdded="userAdded"  @provider_view_typee="provider_view_typee"   @country_view_typee="country_view_typee"  :getUserPortalData="getUserPortalData"   :view_type="view_type"  />

        <EmployeeUserCountry @userView="userView" :employee_country_view_type="country_view_type" :employee_user_id="employee_user_id"  />    
  
        <EmployeeUserProvider @userView="userView"   :employee_provider_view_type="provider_view_type" :employee_provider_user_id="employee_provider_user_id" />      -->
        <!-- Modal -->
        <!-- <div class="modal fade" id="userModal" tabindex="-1" aria-labelledby="userModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="userModalLabel">Modal title</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                ...
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>
            </div>
        </div>
        </div> -->
    </div>
</template>


<script>
// @ is an alias to /src
import HeaderComp from "@/components/HeaderComp.vue"
import FooterComp from "@/components/FooterComp.vue"
import SideBarComp from "@/components/SideBarComp.vue"
import NavBarComp from "@/components/NavBarComp.vue"
import PerPageComponent from "@/components/table/PerPage.vue"
import TableSearch from "@/components/table/TableSearch"
// import TableFilter from "@/components/table/TableFilter"
import ActionButton from "@/components/table/ActionButton"
import SelectAllComponent from "@/components/table/SelectAllComponent"
import axios from "axios"
import FieldValidateService from "@/services/FieldValidateService";

//import "@ocrv/vue-tailwind-pagination/dist/style.css";
import VueTailwindPagination from "@ocrv/vue-tailwind-pagination";
import "@ocrv/vue-tailwind-pagination/styles";
import notificationBox from '@/services/notificationBox'
import {BASEURL} from '@/services/UserService';
import {  mapMutations, mapState } from 'vuex';
import { LOADING, TABLE_CURRENT_PAGE, TABLE_PER_PAGE, TABLE_SEARCH, TABLE_SEL_ALL, TABLE_SORT_FIELD_DIRECTION, TABLE_STATUS, TABLE_TOTAL, TABLE_URL } from '@/store/storeconstants';
import vSelect from 'vue-select';
import vueEasyPrint from "vue-easy-print";


export default {
  name: "ConvertReportView",
    beforeRouteEnter(to,from,next){
     let user_level = "";
     if(Array.isArray(JSON.parse(localStorage.getItem("user_portal_info")))) {
     const user_data = JSON.parse(localStorage.getItem("user_portal_info"));
     user_level = user_data[0].user_level;
     }
     else{
             const user_data = JSON.parse(localStorage.getItem("user_portal_info"));
             user_level = user_data.user_level;
     }
   
    
    if(user_level=='SUPER ADMIN' || user_level=='SECRETARY' || user_level=='AREA ADMIN' || user_level=='AREA SECRETARY' || user_level=='DISTRICT ADMIN' || user_level=='DISTRICT SECRETARY'){
        next();

    }else{
  
        next("/user-not-authorise"); 
    }
 
    
  },
  components: {
    HeaderComp, 
    FooterComp,
    SideBarComp, 
    NavBarComp,
    PerPageComponent,
    TableSearch,
    // TableFilter,
    ActionButton,
    SelectAllComponent,
    vSelect,
    VueTailwindPagination,
     vueEasyPrint 
  },



  data(){
    return {

      grid_converts:[],
      report_converts:{},
      totalConverts:"",
        //showUserModals:false,

        //currentPage:1,
        //total:100,
        perPage:20,
        //perPage:20,
        search:"",
        is_active: "",
        checked:[],
        user_table_data:{},
        user_portal_table_data:{},
        getCrusadeTypeData:{},
        view_type:"",
        app_portal:"",
        user_portal_info:{},
        getSearchBy:[],
        getColumns:[],
        getDenominations:[],
        getChurches:[],
        errorClient:[],
        errorServers:[],
        // provider_id:"",
        // country_view_type:"",
        // provider_view_type:"", 
        // employee_user_id:"",
        // employee_provider_user_id:"",
        // support_id:"",
        // dependent_id:"",
        // getSupportData:{},


        login_user_id:"",
        user_level:"",
        selectPage:false,
        year:"",

         formData:{
            search_type:"STATIC",
            search_by:"",
            column:[],
            search_value:"",
            view_type:"",
            denomination:"",
            denomination_id:"",
            church:"",
            church_id:"",
         },

         search_typeErr:"",
         search_byErr:"",
         columnErr:"",
         search_valueErr:"",
         view_typeErr:"",
         churchErr:"",
         denominationErr:"",
         org_name:"",


    }
  },


  computed:{
    ...mapState('dataTable',['currentPage','url','total','perpage','getstatus','getsearch','selectAll','sort_direction','sort_field']),
    ...mapState("user_portal",['search_user_portal_id_result']),
    //...mapState("user",['search_user_id_result'])
  },



  methods:{

       ...mapMutations({
       table_select_all: 'dataTable/'+TABLE_SEL_ALL,
       table_sort_field_direction: 'dataTable/'+TABLE_SORT_FIELD_DIRECTION,
       table_current_page:'dataTable/'+TABLE_CURRENT_PAGE,
       table_url:'dataTable/'+TABLE_URL,
       table_per_page :'dataTable/'+TABLE_PER_PAGE,
       table_total :'dataTable/'+TABLE_TOTAL,
       table_search :'dataTable/'+TABLE_SEARCH,
       table_status :'dataTable/'+TABLE_STATUS,
       showLoading:LOADING,

     //  verified_username:'auth/'+SUCC_AUTH_VERIFY,
   }),




    selectAllRecords(){

        axios.get('crusade_types/all?is_active=' + this.getstatus)
             .then(response => {
                 console.log(response.data);
                  this.checked = response.data;
                  // this.selectAll = true;
                 // this.table_checked(response.data);
                   this.table_select_all(true);
             });
    },



    change_sort(field){
        // if(this.sort_field == field){
        //  this.sort_direction =   this.sort_direction == "asc" ? "desc" : "asc"
        // }else{
        //     this.sort_field = field;
        // }
        this.table_sort_field_direction(field);
        this.searchConvert();

    },



   deleteSingleRecord(id){

    //console.log(id);

       axios.delete(`crusade_types/${id}`)
       .then( (response) => {
        this.checked = this.checked.filter(crusade_type_id => crusade_type_id != id)
        //this.table_checked(this.checked.filter(user_portal_id => user_portal_id != id))
        console.log(response.data.success)
        notificationBox.succNotifications("Deleted Successfully");
         this.getCrusadeType();
       }).catch(error => {
        console.log(error.message)
         notificationBox.errorNotifications(error)
       })


   },



   deleteRecords(){
      axios.delete(`crusade_types/massDestroy/${this.checked}`)
      .then((response) => {
         if(response.status == 204){
            console.log(response.data.success)
            notificationBox.succNotifications("Selected Crusade Types were Deleted Successfully");
            this.checked = [];
            //this.table_checked([])
            this.searchConvert();

         }
      })
   },





   


    pageChange(pageNumber){

        //this.currentPage = pageNumber;
        this.table_current_page(pageNumber);
        this.searchConvert();

    },




   isChecked(id){

    return  this.checked.includes(id);

   },



    getCrusadeType(){
   
        axios.get(
            'crusade_types?page=' + this.currentPage + 
            '&page_size=' + this.perpage + 
            '&q=' +this.getsearch + 
            '&is_active=' + this.getstatus +
            '&sort_direction=' + this.sort_direction +
            '&sort_field=' + this.sort_field +
            '&portal=' + this.app_portal +
            '&crusade_type_id=' + this.crusade_type_id 
            )
        .then(response => {

            this.crusade_types = response.data.data.data;
            //this.perPage = response.data.data.per_page;
               this.table_per_page(response.data.data.per_page);
            //this.total = response.data.data.total;
             this.table_total(response.data.data.total)
        });

    },



    perpageval(value){
        this.table_per_page(value);
       this.searchConvert();

    },


    // banSucc(){

    //     this.getSupportMember();

    // },


    searchval(value){

         this.table_search(value);
        this.searchConvert();

    },

    statusval(value){

        this.table_status(value);
        this.searchConvert();

    },

 

   
   crusade_typeEdit(crusade_type_id){

    this.crusade_type_id = crusade_type_id;
    this.view_type = "edit";
    this.getCrusadeTypeDataa(crusade_type_id);
    this.$refs.crusade_type_ref.click();

   },




    crusade_typeView(crusade_type_id)
    {

    this.crusade_type_id = crusade_type_id;
    this.view_type = "view";
    this.getCrusadeTypeDataa(crusade_type_id);
    
    this.$refs.crusade_type_ref.click();

   },




      async getCrusadeTypeDataa(crusade_type_id){
         
             this.showLoading(true);
             try{
            //   console.log("From Watchers",user_portal_id);
            //   console.log("From Props",this.get_user_portal_id);
            
            const response = await axios.get(`crusade_types/${crusade_type_id}`);
            this.showLoading(false);
                this.getCrusadeTypeData = response.data.data;
             }catch(error){
                this.showLoading(false);
             }
          
              },





    crusade_typeAdd(){

        this.view_type = "add";
        this.$refs.crusade_type_add_ref.click();

     },






     crusade_typeAdded(){
        this.getCrusadeType();
     },








      async get_denomination_id(){
           try{
       let response = await axios.get(`getDenominationById?denomination_id=${this.formData.denomination_id}`);
        console.log(response);
        this.formData.denomination =  JSON.parse(JSON.stringify(response.data.data));
       // this.getPortalDenominations =  JSON.parse(JSON.stringify(response.data.data));
        //console.log("meeeeeeee");
       
      // console.log(response);
      
    }catch(error){
        console.log(error);
    }
},








  async get_church_id(){
           try{
       let response = await axios.get(`getChurchById?church_id=${this.formData.church_id}`);
        console.log(response);
        this.formData.church =  JSON.parse(JSON.stringify(response.data.data));
       // this.getPortalDenominations =  JSON.parse(JSON.stringify(response.data.data));
        //console.log("meeeeeeee");
       
      // console.log(response);
      
    }catch(error){
        console.log(error);
    }
},





           denominationVal(value){

             let field = "Area";

                   if(typeof value == "object" && value!=null)
             {
             value = value.id;
             }

      if((this.app_portal=="MAIN" || this.app_portal=="AREA") && FieldValidateService.reqVal2(value,field)){
        this.denominationErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.denominationErr);
      }else{
        if(this.errorClient.includes(this.denominationErr))
        {
        let pos = this.errorClient.indexOf(this.denominationErr);
        this.errorClient.splice(pos,1);
        }
        this.denominationErr =""; 
         this.churchByDenomination();
         this.get_denomination_id();
      }

    },




   




      churchVal(value){

             let field = "District";

                   if(typeof value == "object" && value!=null)
             {
             value = value.id;
             }

      if(this.formData.denomination_id != "ALL" && FieldValidateService.reqVal2(value,field)){
        this.churchErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.churchErr);
      }else{
        if(this.errorClient.includes(this.churchErr))
        {
        let pos = this.errorClient.indexOf(this.churchErr);
        this.errorClient.splice(pos,1);
        }
        this.churchErr ="";
        this.get_church_id(); 
      }

    },







 async churchByDenomination(){
    try{

        const response = await axios.get(`getChurchByDenomination?denomination_id=${this.formData.denomination_id}`)
        this.getChurches = response.data.data;

       // console.log(this.getChurches);

    }catch(_){

        console.log(_);

    }

    this.getChurches.unshift({"id":"ALL", "church":"ALL"});
},





async getPortal(){


 
if(Array.isArray(JSON.parse(localStorage.getItem("user_portal_info")))) {
   this.app_portal = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal;
   this.org_name = "CoPAccra"
   //console.log(localStorage.getItem("user_portal_info"))
 if(this.app_portal == "DISTRICT")
 {
this.formData.denomination_id = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_denomination_id;
this.formData.denomination = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_denomination;
this.formData.church_id = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_church_id;
this.formData.church = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_church;
this.org_name = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_church;
 }
  else if(this.app_portal == "AREA")
 {
  this.formData.denomination_id = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_denomination_id;
  this.formData.denomination = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_denomination;
  this.org_name = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_denomination;
 }
}
else{


     this.app_portal = JSON.parse(localStorage.getItem("user_portal_info")).portal;
     this.org_name = "CoPAccra"

     //console.log(localStorage.getItem("user_portal_info"))
 if(this.app_portal == "DISTRICT")
 {
this.formData.church_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_church_id;
this.formData.denomination_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_denomination_id;
this.formData.denomination = JSON.parse(localStorage.getItem("user_portal_info")).portal_denomination;
this.formData.church = JSON.parse(localStorage.getItem("user_portal_info")).portal_church;
this.org_name = JSON.parse(localStorage.getItem("user_portal_info")).portal_church;
 }
  else if(this.app_portal == "AREA")
 {
  this.formData.denomination_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_denomination_id;
  this.formData.denomination = JSON.parse(localStorage.getItem("user_portal_info")).portal_denomination;
  this.org_name = JSON.parse(localStorage.getItem("user_portal_info")).portal_denomination;
 }

}
},





          search_typeVal(value) {

      let max = 10;
      let field = "Search Type";
     
     if(FieldValidateService.reqMaxStringVal(value,field,max))
     {
       this.search_typeErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.search_typeErr);
     }else{

           if(this.errorClient.includes(this.search_typeErr))
           {
            let pos = this.errorClient.indexOf(this.search_typeErr);
           this.errorClient.splice(pos,1);
            this.search_typeErr =""; 
           }
          
          

     }
    },






     search_byVal(value){

             let field = "Search By";

                   if(typeof value == "object" && value!=null)
             {
             value = value.search_by;
             }


      if(FieldValidateService.reqVal2(value,field)){
        this.search_byErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.search_byErr);
      }else{
        if(this.errorClient.includes(this.search_byErr))
        {
        let pos = this.errorClient.indexOf(this.search_byErr);
        this.errorClient.splice(pos,1);
        }
        
        this.search_byErr =""; 
      }

    },





         columnVal(value){

             let field = "Column";

              if(typeof value == "object" && value!=null)
             {
                  value.push(value.column);
             }

      if(this.formData.search_type=='DYNAMIC' && FieldValidateService.reqVal2(value,field)){
        this.columnErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.columnErr);
      }else{
        if(this.errorClient.includes(this.columnErr))
        {
        let pos = this.errorClient.indexOf(this.columnErr);
        this.errorClient.splice(pos,1);
        }
        this.columnErr =""; 
      }

    },






          search_valueVal(value) {

      let max = 500;
      let field = "Search Value";
     
     if(this.formData.search_by != 'ALL' && FieldValidateService.reqVal(value,field,max))
     {
       this.search_valueErr = FieldValidateService.reqVal(value,field,max);
        this.errorClient.push(this.search_valueErr);
     }else{

           if(this.errorClient.includes(this.search_valueErr))
           {
            let pos = this.errorClient.indexOf(this.search_valueErr);
           this.errorClient.splice(pos,1);
            this.search_valueErr =""; 
           }
          
          

     }
    },







    







    view_typeVal(value) {

      let max = 10;
      let field = "Value Type";
     
     if(FieldValidateService.reqMaxStringVal(value,field,max))
     {
       this.view_typeErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.view_typeErr);
     }else{

           if(this.errorClient.includes(this.view_typeErr))
           {
            let pos = this.errorClient.indexOf(this.view_typeErr);
           this.errorClient.splice(pos,1);
            this.view_typeErr =""; 
           }
          
          

     }
    },



    printDiv(){

          this.$refs.easyPrint.print()

    },




    async getTotalConver(){
        try{
       let response = await axios.get(`getTotalConverts?denomination_id=${this.formData.denomination_id}&&church_id=${this.formData.church_id}`);
        this.totalConverts =  JSON.parse(JSON.stringify(response.data.data));
       // this.getPortalDenominations =  JSON.parse(JSON.stringify(response.data.data));
        //console.log("meeeeeeee");
       this.formData.denomination_id
       
      
    }catch(_){
        console.log(_);
    }
    },


    async searchConvert(){

       this.errorServers = [];
       this.errorClient = [];
       this.showLoading(true);

        this.denominationVal(this.formData.denomination_id);
        this.churchVal(this.formData.church_id);
        this.search_typeVal(this.formData.search_type);
        this.search_byVal(this.formData.search_by);
        this.columnVal(this.formData.column);
        this.search_valueVal(this.formData.search_value);
        this.view_typeVal(this.formData.view_type);


          if(this.errorClient.length > 0)
       {
         this.showLoading(false);
          notificationBox.errorNotifications("Some Errors Exist");
          console.log(this.errorClient);
          return true;
       }

       else{

   


                 try{


                   const formData = {

            search_type: this.formData.search_type,
            search_by: this.formData.search_by,
            column: this.formData.column,
            search_value: this.formData.search_value,
            view_type: this.formData.view_type,
            denomination: this.formData.denomination,
            denomination_id: this.formData.denomination_id,
            church: this.formData.church,
            church_id: this.formData.church_id,
            table_search: this.getsearch,
            page_size:  this.perpage,
            sort_direction: this.sort_direction,
            sort_field: this.sort_field
            
        };


            let response = await axios.post("searchConvert",formData);
            //console.log("resssssssssssssss", response);
              if(response.status == 200)
              {
                   this.showLoading(false);

                   this.getTotalConver();
                     //this.clearFormData();
        // notificationBox.succNotifications("Convert Added Successfully");
        // this.$emit("convertAdded");


               if(this.formData.view_type == "GRID")
               {
               
               this.grid_converts = [];
                this.grid_converts = response.data.data.data;
                console.log(response.data.data.data);

                   this.table_per_page(response.data.data.per_page);
             this.table_total(response.data.data.total)
              
               }
               else{
                this.report_converts = response.data.data;
                this.year = new Date().getFullYear(); 
               }
              }


         }catch(error){

                  this.showLoading(false);
                 console.log(error);
      //      this.errorServers.push(error.response.data.error);
           
      //      this.showLoading(false);
           

      //             if(this.errorServers.length > 0)
      //  {
      //     notificationBox.errorNotifications("Some Errors Exist");
      //       this.getServerError();
     
      //  }
      //  else if(error.response.data.data_error){
        
      //      notificationBox.errorNotifications(error.response.data.data_error);
      //  }

         }

       }


    },






  
  },





 async created(){


    //  try{
    //    let response = await axios.get(`getTotalConverts?app_portal=${this.app_portal}`);
    //     this.totalConverts =  JSON.parse(JSON.stringify(response.data.data));
    //    // this.getPortalDenominations =  JSON.parse(JSON.stringify(response.data.data));
    //     //console.log("meeeeeeee");
       
       
      
    // }catch(_){
    //     console.log(_);
    // }


        try{
       let response = await axios.get("getDenominations");
        this.getDenominations =  JSON.parse(JSON.stringify(response.data.data));
       // this.getPortalDenominations =  JSON.parse(JSON.stringify(response.data.data));
        //console.log("meeeeeeee");
       
       
      
    }catch(error){
        console.log(error);
    }

    this.getDenominations.unshift({"id":"ALL", "denomination":"ALL"});


this.getPortal();
 this.churchByDenomination();
       
      
// this.app_portal = JSON.parse(localStorage.getItem("user_portal_info")).portal;
// this.denomination_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_denomination_id;
//console.log(JSON.parse(localStorage.getItem("user_portal_info")))

    //this.currentPage = 1;
    this.table_current_page(1);
    this.getCrusadeType();

    this.getSearchBy = [
        {
                "search_by":"ALL",
                "search_by_act_name":"ALL",
            },
            {
                "search_by":"crusade_types.crusade_type",
                "search_by_act_name":"CRUSADE TYPE",
            },
            // {
            //     "search_by":"denominations.denomination",
            //     "search_by_act_name":"DENOMINATION",
            // },
            // {
            //     "search_by":"churches.church",
            //     "search_by_act_name":"CHURCH",
            // },
            {
                "search_by":"converts.fullname",
                "search_by_act_name":"FULLNAME",
            },
            {
                "search_by":"converts.age",
                "search_by_act_name":"AGE",
            },
            // {
            //     "search_by":"age>=",
            //     "search_by_act_name":"AGE>=",
            // },
            // {
            //     "search_by":"age<=",
            //     "search_by_act_name":"AGE<=",
            // },
            {
                "search_by":"converts.gender",
                "search_by_act_name":"GENDER",
            },
            {
                "search_by":"converts.location",
                "search_by_act_name":"LOCATION",
            },
            {
                "search_by":"converts.residence_phone_number",
                "search_by_act_name":"RESIDENCE PHONE NUMBER",
            },
            {
                "search_by":"converts.office_phone_number",
                "search_by_act_name":"OFFICE PHONE NUMBER",
            },
            {
                "search_by":"converts.first_time_decision_for_christ",
                "search_by_act_name":"FIRST TIME DECISION FOR CHRIST",
            },
            {
                "search_by":"converts.best_time_to_visit",
                "search_by_act_name":"BEST TIME TO VISIT",
            },
            {
                "search_by":"converts.convert_needs",
                "search_by_act_name":"CONVERT NEEDS",
            },
    ];

   // console.log(JSON.parse(JSON.stringify(this.getSearchBy)));

    this.getColumns = [{
                "column":"crusade_type",
                "column_view":"CRUSADE TYPE",
                "column_field":"crusade_types.crusade_type",
            },
            {
                "column":"area",
                "column_view":"AREA",
                "column_field":"denominations.denomination",
            },
            {
                "column":"district",
                "column_view":"DISTRICT",
                 "column_field":"churches.church",
            },
            
            {
                "column":"fullname",
                "column_view":"FULLNAME",
                "column_field":"converts.fullname",
            },
            {
                "column":"age",
                "column_view":"AGE",
                "column_field":"converts.age",
            },
            {
                "column":"gender",
                "column_view":"GENDER",
                "column_field":"converts.gender",
            },
            {
                "column":"location",
                "column_view":"LOCATION/LANDMARK",
                "column_field":"converts.location",
            },
            {
                "column":"residence_phone_number",
                "column_view":"RESIDENCE PHONE NUMBER",
                "column_field":"converts.residence_phone_number",
            },
            {
                "column":"office_phone_number",
                "column_view":"C/O PHONE NUMBER",
                "column_field":"converts.office_phone_number",
            },
            {
                "column":"first_time_decision_for_christ",
                "column_view":"FIRST TIME DECISION FOR CHRIST",
                "column_field":"converts.first_time_decision_for_christ",
            },
            {
                "column":"best_time_to_visit",
                "column_view":"BEST TIME TO VISIT",
                "column_field":"converts.best_time_to_visit",
            },
            {
                "column":"convert_needs",
                "column_view":"CONVERT NEEDS",
                "column_field":"converts.convert_needs",
            }];
  },


  watch:{


     "selectPage"(value){
         this.checked = [];
       //  this.table_checked([]);

        if(value){
            const crusade_types = JSON.parse(JSON.stringify(this.crusade_types));
            crusade_types.forEach(crusade_type=>{
                this.checked.push(crusade_type.id)
                //this.table_checked_push(user.id);
            });
        
        }else{
              
              this.checked = [];
              //this.table_checked([]);
              //this.selectAll = false;
              this.table_select_all(false);
        }

     },

     "checked"(){

        this.table_url(BASEURL + "crusade_types/export/"+ this.checked);
       // this.url = BASEURL + this.url + this.checked;

     }

  }

};
</script>


<style scoped src="@/assets/css/report.css"></style>