import { SUCC_DEPENDENT_REG_DATA, DEPENDENT_REG, SET_DEPENDENT_INFO, GET_DEPENDENT_INFO, SEARCH_DEPENDENT, GET_SEARCH_DEPENDENT, DEPENDENT_LINK_REG, EDIT_DEPENDENT_LINK_REG } from '@/store/storeconstants';
import axios from 'axios';
export default{

    async [DEPENDENT_REG](context,payload){

        let response = await axios.post('dependents',payload);
        //  console.log(response);
          let mess = response.data.message;
         
          if(mess === 'success'){
           let data = response.data.data;
              context.commit(SUCC_DEPENDENT_REG_DATA,data);
          }

    },



    async [SET_DEPENDENT_INFO](context,payload){

        let response = await axios.get('dependents/'+payload);
        //  console.log(response);
          let mess = response.data.message;
          console.log(mess);
         
          if(mess === 'success'){
              let data = response.data.data;
              console.log(data);
              context.commit(GET_DEPENDENT_INFO,data);
          }

    },




    async [SEARCH_DEPENDENT](context,payload){

        let response = await axios.post("getSearchDependent",payload);
        let data = response.data;
       // console.log(data);
        context.commit(GET_SEARCH_DEPENDENT,data);
  
      },





      async [DEPENDENT_LINK_REG](context,payload){

        await axios.post('add_dependent',payload);
        //  console.log(response);
      

    },


    async [EDIT_DEPENDENT_LINK_REG](context,payload){

        await axios.post(`edit_dependent${payload.dependent}`,payload);

    }

}