//LOADING
export const LOADING = '[mutations] show loading';

//DataTable
export const TABLE_SEL_ALL = '[mutations] set selectAll';
// export const TABLE_CHECKED = '[mutations] set checked';
// export const TABLE_CHECKED_PUSH = '[mutations] set checked push';
export const TABLE_SORT_FIELD_DIRECTION = '[mutations] set sort field and sort direction';
export const TABLE_CURRENT_PAGE = '[mutations] set sort table current page';
export const TABLE_URL = '[mutations] set table url';
export const TABLE_PER_PAGE = '[mutations] set table per_page';
export const TABLE_TOTAL = '[mutations] set table total';
export const TABLE_SEARCH = '[mutations] set table search';
export const TABLE_STATUS = '[mutations] set table status';
//export const AUTH_VERIFY = '[actions] auth verify';

//Auth
//Verify
export const AUTH_VERIFY = '[actions] auth verify';
export const SUCC_AUTH_VERIFY = '[mutations] set success auth verify data';

//Forgot
export const AUTH_FORGOT = '[actions] auth forgot';
export const SUCC_AUTH_FORGOT = '[mutations] set success auth forgot data';

//Reset
export const AUTH_RESET = '[actions] auth reset';
//export const SUCC_AUTH_RESET = '[mutations] set success auth reset data';

//Login
export const AUTH_LOGIN = '[actions] auth login';
export const SUCC_AUTH_LOGIN = '[mutations] set success auth login data';
//export const SUCC_AUTH_LOGIN = '[mutations] set success auth login data';

//Otp Confirmation
export const AUTH_OTP = '[actions] auth otp';
export const SUCC_AUTH_OTP = '[mutations] set success auth otp data';




//USERS
export const USER_REG = '[actions] user registration';
export const SUCC_USER_REG_DATA = '[mutations] set success user reg data';
export const SEARCH_USER = '[actions] search user';
export const SEARCH_USER_PER_PORTAL = '[actions] search user per portal';
export const GET_SEARCH_USER = '[mutations] set search user';
export const GET_SEARCH_USER_PER_PORTAL = '[mutations] set search user per portal';
export const SEARCH_USER_ID = '[actions] search user id';
export const GET_SEARCH_USER_ID = '[mutations] set search user id';
export const VERIFY_USER_EXIST_OTP = '[actions] verify user exist otp';
export const GET_USER_EXIST_MESS = '[actions] get user exist otp mess';


//SUPPORT
export const SUPPORT_REG = '[actions] support registration';
export const SUCC_SUPPORT_REG_DATA = '[mutations] set success support reg data';
//DEPENDENT
export const DEPENDENT_REG = '[actions] dependent registration';
export const SUCC_DEPENDENT_REG_DATA = '[mutations] set success dependent reg data';
export const SET_DEPENDENT_INFO = '[actions] set dependent info';
export const GET_DEPENDENT_INFO = '[mutations] get dependent info';
export const GET_DEPENDENT_VIEW_TYPE = '[mutations] get dependent view type';
export const GET_DEPENDENT_ID = '[mutation] get dependent id';
export const SEARCH_DEPENDENT = '[actions] search dependent';
export const GET_SEARCH_DEPENDENT = '[mutation] get search dependent';
export const RESET_DEPENDENT_SEARCH_RESULT = '[mutation] reset dependent search result';
export const DEPENDENT_LINK_REG = '[actions] dependent link registration';
export const EDIT_DEPENDENT_LINK_REG = '[actions] edit dependent link registration';

//DEPENDENT_SUPPORT
export const GET_DEPENDENT_SUPPORT_VIEW_TYPE = '[mutations] get dependent_support view type';
export const GET_DEPENDENT_SUPPORT_ID = '[mutation] get dependent_support id';
export const SEARCH_DEPENDENT_SUPPORT = '[actions] search dependent_support';
export const GET_SEARCH_DEPENDENT_SUPPORT = '[mutation] get search dependent_support';
export const RESET_DEPENDENT_SUPPORT_SEARCH_RESULT = '[mutation] reset dependent_support search result';
export const DEPENDENT_SUPPORT_LINK_REG = '[actions] dependent_support link registration';
export const EDIT_DEPENDENT_SUPPORT_LINK_REG = '[actions] edit dependent_support link registration';
export const SET_DEPENDENT_SUPPORT_INFO = '[actions] set dependent support info';
export const GET_DEPENDENT_SUPPORT_INFO = '[mutations] get dependent support info';

//PROVIDER
export const PROVIDER_REG = '[actions] provider registration';
export const SUCC_PROVIDER_REG_DATA = '[mutations] set success provider reg data';
export const VERIFY_PROVIDER_EXIST = '[actions] verify provider exist';
export const GET_PROVIDER_EXIST_MESS = '[actions] get provider exist  mess';
export const APPROVE_PROVIDER = '[actions] approve provider';
export const REJECT_PROVIDER = '[actions] reject provider';
export const MASS_APPROVE_PROVIDER = '[actions] mass_approve provider';
export const MASS_REJECT_PROVIDER = '[actions] mass_reject provider';
export const SET_PROVIDER_MESSAGE = '[mutation] get provider message';
export const GET_PROVIDER_ID = '[mutation] get provider id';
export const SET_PROVIDER_INFO = '[actions] set provider info';
export const GET_PROVIDER_INFO = '[mutations] get provider info';
export const GET_PROVIDER_VIEW_TYPE = '[mutations] get provider view type';
//User Portal
export const USER_PORTAL_REG = '[actions] user_portal registration';
export const USER_EXIST_PORTAL_REG = '[actions] user_exist_portal registration';
export const USER_PORTAL_EDIT = '[actions] user_portal edit';
export const SUCC_USER_PORTAL_REG_DATA = '[mutations] set success user_portal reg data';
export const SEARCH_USER_USER_PORTAL = '[actions] search user user portal id';
export const GET_SEARCH_USER_USER_PORTAL = '[mutations] get search user user portal id';
export const GET_MODAL_USER_PORTAL_VIEW_TYPE = '[mutations] get modal user portal view type';
export const GET_MODAL_USER_PORTAL_USER_ID = '[mutations] get modal user portal user id';
export const GET_MODAL_USER_PORTAL_ID = '[mutations] get modal user portal id';

//Employee Country
export const SEARCH_EMPLOYEE_USER_COUNTRY_PORTAL = '[actions] employee user country portal using user_id';
export const GET_EMPLOYEE_USER_COUNTRY_PORTAL = '[mutations] get employee user country portal using user_id';
export const SEARCH_EMPLOYEE_USER_COUNTRY_PORTAL_ID = '[actions] employee user portal country by id';
export const GET_EMPLOYEE_USER_COUNTRY_PORTAL_ID = '[mutations] get employee user portal country by id';
export const GET_EMPLOYEE_USER_COUNTRY_PORTAL_VIEW_TYPE = '[mutations] get employee user portal country view type';
export const EDIT_EMPLOYEE_USER_COUNTRY_PORTAL = '[mutations] edit employee user portal country';
export const SET_USER_PORTAL_ID = '[mutations] set user portal_id';

//Employee Provider
export const SEARCH_EMPLOYEE_USER_PROVIDER_PORTAL = '[actions] employee user provider portal using user_id';
export const GET_EMPLOYEE_USER_PROVIDER_PORTAL = '[mutations] get employee user provider portal using user_id';
export const SEARCH_EMPLOYEE_USER_PROVIDER_PORTAL_ID = '[actions] employee user portal provider by id';
export const GET_EMPLOYEE_USER_PROVIDER_PORTAL_ID = '[mutations] get employee user portal provider by id';
export const GET_EMPLOYEE_USER_PROVIDER_PORTAL_VIEW_TYPE = '[mutations] get employee user portal provider view type';