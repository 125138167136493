<template>
  <div>
<div id="cover-spin"></div>
  </div>
</template>

<script>
export default {
  name: "LoadingComp"
};
</script>

<style scoped></style>