
<template>
    <div>
        <SideBarComp/>
        <NavBarComp/>
        <div class="content-wrap">
            <div class="main">
                <div class="container-fluid">
                    <HeaderComp page="Dashboard" pageDesc="visual display of all of your data"/>
                    
                    <section id="main-content">
                        <div class="row">

                            <form class="register-form" @submit.prevent="reg()">

                            <div class="card shadow-sm" id="stylized">

                                <div class="card-body">

                                    <div class="row form_space" style="margin-bottom:10px" v-if="app_portal=='MAIN' ||  app_portal=='AREA'">



                                        <div class="col" v-if="app_portal=='MAIN'">
                                <label><span class="text_error">*</span>Area</label>
                             <div class="input-group">
                          
                         <v-select :options="getDenominations"  class="form-control select1" v-model="formData.denomination_id"  :reduce="denomination => denomination.id" label="denomination" @option:selected="denominationVal" />

                                                  
                        
                        </div>

                          <div
                            :class="{ text_error: denominationErr }"
                            v-if="denominationErr"
                          >
                            {{ denominationErr }}
                          </div>
                        </div>


                        <div class="col" v-if="(app_portal=='MAIN' || app_portal=='AREA') && formData.denomination_id != 'ALL'">
                                <label><span class="text_error">*</span>District</label>
                             <div class="input-group">
                          

                         <v-select :options="getChurches" class="form-control select1" v-model="formData.church_id"  :reduce="church => church.id" label="church" @option:selected="churchVal" />

                                                  
                        
                        </div>

                          <div
                            :class="{ text_error: churchErr }"
                            v-if="churchErr"
                          >
                            {{ churchErr }}
                          </div>
                        </div>



                                    </div>



                                    <div class="row form_space" style="margin-bottom:10px">


                                        <div class="col">
                        <label><span class="text_error">*</span>From Date</label>
                        <div class="input-group">
                          

                          <input
                            type="date"
                            class="form-control"
                            :class="{ form_error: from_dateErr}"
                            placeholder="From Date"
                            @blur="from_dateVal($event.target.value)" 
                            v-model.trim="formData.from_date"
                          />
                         
                        </div>
                         <div
                            :class="{ text_error: from_dateErr  }"
                           v-if="from_dateErr">
                          {{ from_dateErr }} 
                          </div>
                      </div>




                      <div class="col">
                        <label><span class="text_error">*</span>To Date</label>
                        <div class="input-group">
                          

                          <input
                            type="date"
                            class="form-control"
                            :class="{ form_error: to_dateErr}"
                            placeholder="To Date"
                            @blur="to_dateVal($event.target.value)" 
                            v-model.trim="formData.to_date"
                          />
                         
                        </div>
                         <div
                            :class="{ text_error: to_dateErr  }"
                           v-if="to_dateErr">
                          {{ to_dateErr }} 
                          </div>
                      </div>





                                    </div>


                                </div>

                                <button type="submit" class="btn btn-primary">Generate</button>


                            </div>


              

                            </form>

                        </div>
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="card">
                                    <div class="stat-widget-one">
                                        <div class="stat-icon dib"><i class="ti-user color-success border-success"></i>
                                        </div>
                                        <div class="stat-content dib">
                                            <div class="stat-text">Total Male</div>
                                            <div class="stat-digit">{{ this.total_male }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="card">
                                    <div class="stat-widget-one">
                                        <div class="stat-icon dib"><i class="ti-user color-primary border-primary"></i>
                                        </div>
                                        <div class="stat-content dib">
                                            <div class="stat-text">Total Female</div>
                                            <div class="stat-digit">{{ this.total_female }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="card">
                                    <div class="stat-widget-one">
                                        <div class="stat-icon dib"><i class="ti-layout-grid2 color-pink border-pink"></i>
                                        </div>
                                        <div class="stat-content dib">
                                            <div class="stat-text">Total Converts</div>
                                            <div class="stat-digit">{{ this.total}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                        </div>





                    </section>
                    <FooterComp/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import HeaderComp from "@/components/HeaderComp.vue"
import FooterComp from "@/components/FooterComp.vue"
import SideBarComp from "@/components/SideBarComp.vue"
import NavBarComp from "@/components/NavBarComp.vue"
import axios from "axios"
import notificationBox from '@/services/notificationBox'
import FieldValidateService from "@/services/FieldValidateService";
import vSelect from 'vue-select';
import {  mapMutations} from 'vuex';
import { LOADING} from '@/store/storeconstants';







export default {
    name: "DashboardView",

    beforeRouteEnter(to,from,next){
     let user_level = "";
     if(Array.isArray(JSON.parse(localStorage.getItem("user_portal_info")))) {
     const user_data = JSON.parse(localStorage.getItem("user_portal_info"));
     user_level = user_data[0].user_level;
     }
     else{
             const user_data = JSON.parse(localStorage.getItem("user_portal_info"));
             user_level = user_data.user_level;
     }
   
    
    if(user_level=='SUPER ADMIN' || user_level=='AREA ADMIN' || user_level=='DISTRICT ADMIN'){
        next();

    }else{
  
        next("/user-not-authorise"); 
    }
 
    
  },
    data:  function() {

        return{

            total_male: 0,
            total_female: 0,
            total: 0,
            getDenominations:[],
            getChurches:[],
            app_portal:"",
            churchErr:"",
            denominationErr:"",
            from_dateErr:"",
            to_dateErr:"",
            errorClient:[],
            errorServers:[],


            formData:{
            denomination:"",
            denomination_id:"",
            church:"",
            church_id:"",
            from_date: new Date().getFullYear()+"-01-01",
            to_date: new Date().getFullYear()+"-12-31"
         },


        }
    },

    methods: {


        ...mapMutations({
       showLoading:LOADING,

     //  verified_username:'auth/'+SUCC_AUTH_VERIFY,
   }),


        async churchByDenomination(){
    try{

        const response = await axios.get(`getChurchByDenomination?denomination_id=${this.formData.denomination_id}`)
        this.getChurches = response.data.data;

       // console.log(this.getChurches);

    }catch(_){

        console.log(_);

    }

    this.getChurches.unshift({"id":"ALL", "church":"ALL"});
},



        async getPortal(){


 
if(Array.isArray(JSON.parse(localStorage.getItem("user_portal_info")))) {
   this.app_portal = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal;
   this.formData.denomination_id = "ALL";
   this.formData.church_id = "ALL";
   this.org_name = "CoPAccra"
   //console.log(localStorage.getItem("user_portal_info"))
 if(this.app_portal == "DISTRICT")
 {
this.formData.denomination_id = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_denomination_id;
this.formData.denomination = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_denomination;
this.formData.church_id = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_church_id;
this.formData.church = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_church;
this.org_name = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_church;
 }
  else if(this.app_portal == "AREA")
 {
  this.formData.denomination_id = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_denomination_id;
  this.formData.denomination = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_denomination;
  this.org_name = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_denomination;
 }
}
else{


     this.app_portal = JSON.parse(localStorage.getItem("user_portal_info")).portal;
     this.formData.denomination_id = "ALL";
     this.formData.church_id = "ALL";
     this.org_name = "CoPAccra"

     //console.log(localStorage.getItem("user_portal_info"))
 if(this.app_portal == "DISTRICT")
 {
this.formData.church_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_church_id;
this.formData.denomination_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_denomination_id;
this.formData.denomination = JSON.parse(localStorage.getItem("user_portal_info")).portal_denomination;
this.formData.church = JSON.parse(localStorage.getItem("user_portal_info")).portal_church;
this.org_name = JSON.parse(localStorage.getItem("user_portal_info")).portal_church;
 }
  else if(this.app_portal == "AREA")
 {
  this.formData.denomination_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_denomination_id;
  this.formData.denomination = JSON.parse(localStorage.getItem("user_portal_info")).portal_denomination;
//   this.org_name = JSON.parse(localStorage.getItem("user_portal_info")).portal_denomination;
 }

}
},






denominationVal(value){

let field = "Area";

      if(typeof value == "object" && value!=null)
{
value = value.id;
}

if((this.app_portal=="MAIN" || this.app_portal=="AREA") && FieldValidateService.reqVal2(value,field)){
this.denominationErr = FieldValidateService.reqVal2(value,field);
this.errorClient.push(this.denominationErr);
}else{
if(this.errorClient.includes(this.denominationErr))
{
let pos = this.errorClient.indexOf(this.denominationErr);
this.errorClient.splice(pos,1);
}
this.denominationErr =""; 
this.churchByDenomination();
this.get_denomination_id();
}

},









churchVal(value){

let field = "District";

      if(typeof value == "object" && value!=null)
{
value = value.id;
}

if(this.formData.denomination_id != "ALL" && FieldValidateService.reqVal2(value,field)){
this.churchErr = FieldValidateService.reqVal2(value,field);
this.errorClient.push(this.churchErr);
}else{
if(this.errorClient.includes(this.churchErr))
{
let pos = this.errorClient.indexOf(this.churchErr);
this.errorClient.splice(pos,1);
}
this.churchErr ="";
this.get_church_id(); 
}

},





from_dateVal(value) {

let field = "From Date";

if(FieldValidateService.reqVal2(value,field))
{
 this.from_dateErr = FieldValidateService.reqVal(value,field);
  this.errorClient.push(this.from_dateErr);
}else{

     if(this.errorClient.includes(this.from_dateErr))
     {
      let pos = this.errorClient.indexOf(this.from_dateErr);
     this.errorClient.splice(pos,1);
      this.from_dateErr =""; 
     }
    
    

}
},






to_dateVal(value) {

let field = "To Date";

if(FieldValidateService.reqVal2(value,field))
{
 this.to_dateErr = FieldValidateService.reqVal(value,field);
  this.errorClient.push(this.to_dateErr);
}else{

     if(this.errorClient.includes(this.to_dateErr))
     {
      let pos = this.errorClient.indexOf(this.to_dateErr);
     this.errorClient.splice(pos,1);
      this.to_dateErr =""; 
     }
    
    

}
},





async get_denomination_id(){
           try{
       let response = await axios.get(`getDenominationById?denomination_id=${this.formData.denomination_id}`);
        console.log(response);
        this.formData.denomination =  JSON.parse(JSON.stringify(response.data.data));
       // this.getPortalDenominations =  JSON.parse(JSON.stringify(response.data.data));
        //console.log("meeeeeeee");
       
      // console.log(response);
      
    }catch(error){
        console.log(error);
    }
},



async get_church_id(){
           try{
       let response = await axios.get(`getChurchById?church_id=${this.formData.church_id}`);
       // console.log(response);
        this.formData.church =  JSON.parse(JSON.stringify(response.data.data));
       // this.getPortalDenominations =  JSON.parse(JSON.stringify(response.data.data));
        //console.log("meeeeeeee");
       
      // console.log(response);
      
    }catch(error){
        console.log(error);
    }
},




async reg(){

this.errorServers = [];
this.errorClient = [];
this.showLoading(true);

 this.denominationVal(this.formData.denomination_id);
 this.churchVal(this.formData.church_id);
 this.from_dateVal(this.formData.from_date);
 this.to_dateVal(this.formData.to_date);



   if(this.errorClient.length > 0)
{
  this.showLoading(false);
   notificationBox.errorNotifications("Some Errors Exist");
   console.log(this.errorClient);
   return true;
}

else{




          try{


            const formData = {

 
     denomination: this.formData.denomination,
     denomination_id: this.formData.denomination_id,
     church: this.formData.church,
     church_id: this.formData.church_id,
     from_date: this.formData.from_date,
     to_date: this.formData.to_date,

     
 };


     let response = await axios.post("getDashboard",formData);
     console.log("resssssssssssssss", response);
       if(response.status == 200)
       {
            this.showLoading(false);

            this.total_male = response.data.male_converts;
            this.total_female = response.data.female_converts;
            this.total =  response.data.total_converts;


       
       }


  }catch(error){

           this.showLoading(false);
          console.log(error);
//      this.errorServers.push(error.response.data.error);
    
//      this.showLoading(false);
    

//             if(this.errorServers.length > 0)
//  {
//     notificationBox.errorNotifications("Some Errors Exist");
//       this.getServerError();

//  }
//  else if(error.response.data.data_error){
 
//      notificationBox.errorNotifications(error.response.data.data_error);
//  }

  }

}


},

    },

    async created() {


        try{
       let response = await axios.get("getDenominations");
        this.getDenominations =  JSON.parse(JSON.stringify(response.data.data));
       // this.getPortalDenominations =  JSON.parse(JSON.stringify(response.data.data));
        //console.log("meeeeeeee");
       
       
      
    }catch(error){
        console.log(error);
    }

    this.getDenominations.unshift({"id":"ALL", "denomination":"ALL"});

    this.getPortal();
 this.churchByDenomination();
 this.reg()


    },

    components: {
        HeaderComp,
        FooterComp,
        SideBarComp, 
        NavBarComp,
        vSelect,
    },
    props: {
  }
};
</script>