import { GET_SEARCH_USER_USER_PORTAL, SEARCH_USER_USER_PORTAL, SUCC_USER_PORTAL_REG_DATA, USER_EXIST_PORTAL_REG, USER_PORTAL_EDIT, USER_PORTAL_REG } from '@/store/storeconstants';
import axios from 'axios';
export default{

    async [USER_PORTAL_REG](context,payload){

        let response = await axios.post('user_portal_user_prov',payload);
        //  console.log(response);
          let mess = response.data.message;
         
          if(mess === 'success'){
           let data = response.data.data;
              context.commit(SUCC_USER_PORTAL_REG_DATA,data);
          }

    },



    async [USER_PORTAL_EDIT](context,payload){

      let response = await axios.post(`edit_user_portal_user_prov/${payload.user_portal_idy}`,payload);
      //  console.log(response);
        let mess = response.data.message;
       
        if(mess === 'success'){
         let data = response.data.data;
            context.commit(SUCC_USER_PORTAL_REG_DATA,data);
        }

    },


    async[USER_EXIST_PORTAL_REG](context,payload){

      let response = await axios.post(`user_portal_user_exist_prov/${payload.user_idy}`,payload);
      //  console.log(response);
        let mess = response.data.message;
       
        if(mess === 'success'){
         let data = response.data.data;
            context.commit(SUCC_USER_PORTAL_REG_DATA,data);
        }

    },

    async [SEARCH_USER_USER_PORTAL](context,payload){

        let response = await axios.get(`user_portals/${payload}`);
        let data = response.data.data;
       // console.log(data);
        context.commit(GET_SEARCH_USER_USER_PORTAL,data);
  
      }

}