<template>

        <!-- Modal -->
        <div class="modal fade"  :class="{ show: false, 'd-block': false }"  id="providerCommentModal" style="z-index:9999999"    aria-labelledby="providerCommentModalLabel" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg" style="max-height:30%;" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="providerCommentModalLabel" v-if="provider_view_type=='add'">Comment</h5>
                <h5 class="modal-title" id="providerCommentModalLabel" v-if="provider_view_type=='view'">View Provider Details</h5>
                <button type="button" class="btn-close" :data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#providerCommentModal" aria-label="Close"></button>
            </div>
              <div v-if="provider_view_type=='add'">
              <form class="register-form">
            <div class="modal-body">

                <div class="card shadow-sm" id="stylized">
                   
                                <!-- <div class="card-body"> -->


                    <div class="card-body">
                      <h1>Provider Comment</h1><br/>
                      <p></p>
        
                  <span>
                  
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <label>Comment</label>

                          <!-- <input
                            type="text"
                            class="form-control"
                            :class="{ form_error: fullnameErr}"
                            placeholder="Full Name"
                            @blur="fullnameVal($event.target.value)" 
                            v-model.trim="formData.fullname"
                          /> -->
                          <textarea 
                          class="form-control"
                          :class="{ fomr_error: commentError}"
                          placeholder="Comment"
                          @blur="commentVal($event.target.value)" 
                          v-model.trim="formData.comment"
                          style="width:700px; height:200px"
                        >

                          </textarea>
                          <div
                            :class="{ text_error: commentErr  }"
                           v-if="commentErr">
                          {{ commentErr }} 
                          </div>
                        </div>
                      </div>





               


                    </div>





                
                  </span>
                


                  
                </div>


                    

                                </div>
                
            </div>
            <div class="modal-footer">
                <button type="button" @click.prevent="approveRecord" class="btn btn-primary" >Approve</button>
                <button type="button" @click.prevent="rejectRecord" class="btn btn-danger" >Reject</button>
                <button type="button" class="btn btn-danger" @click="remove_all" v-if="view_type=='add'">Clear All</button>
               <button type="button" class="btn btn-secondary" ref="providerNotemodal" data-bs-dismiss="modal">Close</button>

            </div>

                  </form>
                  </div>



                  <div v-else-if="provider_view_type=='view'">




                             <div class="modal-body">
                        <div class="table-responsive table-responsive-data2">
                        <table class="table table-hover table-striped">
                          
                              <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Value</th>
                                </tr>
                                </thead>


                                <tbody>
                                <tr>
                                <td>Provider</td>
                                <td>{{provider_info.provider}}</td>
                                </tr>

                               <tr>
                                <td>Country</td>
                                <td>{{provider_info.country}}</td>
                                </tr>



                                <tr>
                                <td>Contact</td>
                                <td>{{provider_info.provider_contact}}</td>
                                </tr>

          

                                <tr>
                                <td>Health Care</td>
                                <td>{{provider_info.health_care}}</td>
                                </tr>

                        

                        
                                <tr>
                                <td>Status</td>
                                <td>{{provider_info.status}}</td>
                                </tr>


                                <tr>
                                <td>Health Professional</td>
                                <td>{{provider_info.fullname}}</td>
                                </tr>



                                <tr>
                                <td>Comment</td>
                                <td>{{provider_info.comment}}</td>
                                </tr>

                              </tbody>
                                  </table>


                          






                                         

                    
                        </div>

                      </div>



                  </div>





            </div>
        </div>


        
        </div>
</template>

<script>
import FieldValidateService from "@/services/FieldValidateService";
import notificationBox from "@/services/notificationBox";
import { mapActions, mapMutations, mapState } from 'vuex';
import { APPROVE_PROVIDER, LOADING, REJECT_PROVIDER } from '@/store/storeconstants';


    export default {

        name:'ProviderNote',
        props:['checked_user_portal_id','checked_user_id','view_type', 'checked_user_data','checked_user_portal_data'],


           mounted(){

    //this.theModal = new bootstrap(document.getElementById('theModal'));
   
 

           },

        async created() {





 
  
 

        },




         data: function () {
    return {
      //userModal: null,

      loading:false,
      getCountries:[],
      getProviders:[],
      country_name:"",
      errorMess: "",
      succMess:"",
      SearcherrorServers:"",
      clear_user: true,
      clear_all: true,
      errorClient:[],
      errorServers:[],
      has_password:false,
      modal:"modal",


   
      

      formData: {
           //This Helps Link A Gerdd Employee To Either A Country Or A Provider  Applys Here
        comment:"",
    
      },



    //Errors  
      commentErr: "",









    };
    },


    




      methods: {


           ...mapActions({
      approve_provider:'provider/' + APPROVE_PROVIDER,
       reject_provider:'provider/'+ REJECT_PROVIDER,
   }),


         ...mapMutations({
       showLoading:LOADING,
     //  verified_username:'auth/'+SUCC_AUTH_VERIFY,
   }),





       async approveRecord(){
      //notificationBox.successAlert("Registration Successful","An Email Has Been Sent To You. Please Click On The Link To Verify Your Account") 
       //console.log(this.formData);
       // console.log(this.errorClient);
        this.errorServers = [];
       this.errorClient = [];
       this.showLoading(true);

        this.commentVal(this.formData.comment);


   

             if(this.errorClient.length > 0)
       {
         this.showLoading(false);
          notificationBox.errorNotifications("Some Errors Exist");
          console.log(this.errorClient);
          return true;
       }

   

 const formData={
        "provider_id" : this.provider_idd,
        "comment" : this.formData.comment
    }

              await this.approve_provider(formData).catch(error => {
         //  this.errorServers = error.response.data.errors;
          this.showLoading(false);
         console.log(error);
    
           notificationBox.errorNotifications("Provider Could Not Be Approved");
           return true
       });

              this.showLoading(false);
             this.$emit("provider_added");
             this.clear_main_user();
             this.$refs.providerNotemodal.click()
           notificationBox.succNotifications(this.provider_message);

        
      
       
       
    
    },




    async rejectRecord(){

      this.showLoading(true);

    const formData={
        "provider_id" : this.provider_idd,
        "comment" : this.formData.comment
    }

         await this.reject_provider(formData).catch(error => {
         //  this.errorServers = error.response.data.errors;
         this.showLoading(false);
         console.log(error);
    
           notificationBox.errorNotifications("Provider Could Not Be Approved");
           return true
       });

              this.showLoading(false);

              this.$emit("provider_added");
              this.clear_main_user();
               this.$refs.providerNotemodal.click()
          notificationBox.succNotifications(this.provider_message);
    },





     clear_main_user(){
        this.errorServers = [];
      this.clear_user = false;
      this.formData.comment = "";
      this.clear_user = true;
         this.clear_all = true;
    },









    remove_all(){

        this.clear_main_user();

    },



   








      commentVal(value){

     // let max = 500;
      let field = "Comment"
   

         if(this.clear_user && !this.user_idd && FieldValidateService.reqVal(value, field))
     {
       this.commentErr = FieldValidateService.reqVal(value,field);
        this.errorClient.push(this.commentErr);
     }else{

           this.commentErr =""; 
          this.errorClient.pop(this.commentErr);

     }


    },





      },

      
  computed:{
   ...mapState("provider",['provider_message']),
   ...mapState("provider",['provider_idd']),
   ...mapState("provider",['provider_view_type']),
   ...mapState("provider",['provider_info']),

  },  



    watch:{

          async "formData.contact"(curValue) {
      let contact_field = "contact";
      let email_field = null;
      if(this.view_type=="add"){
    this.searchContEm(curValue,contact_field ,email_field)
      }

 

   
 
    },



        async "formData.email"(curValue) {
  
     
      let contact_field = null;
      let email_field = "email";
      if(this.view_type=="add"){
     this.searchContEm(curValue,contact_field,email_field)
      }

  

  
 
    

   
 
    },


        view_type(value){
           if(value == "add"){

            this.formData.fullname =""
            this.formData.gender = ""
            this.formData.country_id = ""
           // this.formData.address = ""
            //this.formData.state = ""
            this.formData.city = ""
            //this.formData.zip = ""
            this.formData.contact = ""
            this.formData.email = ""
          
            this.formData.password = ""
            this.formData.password_confirmation = ""
            this.formData.user_level = ""

           }
        },

        checked_user_data(value){
       
         let data = value;
            //console.log(data);
            this.formData.user_idy = data.id;
            this.formData.fullname = data.fullname;
            // this.formData.lname = data.lname
            // this.formData.oname = data.oname
            this.formData.gender = data.gender;
            this.formData.country_id = data.country_id;
            this.country_name = data.country;
            //this.formData.address = data.address
            //this.formData.state = data.state
            this.formData.city = data.city;
            //this.formData.zip = data.zip
            this.formData.contact = data.contact;
            this.formData.email = data.email;
          
            //this.formData.user_level = data.user_level
            this.formData.password = data.password;
            this.formData.password_confirmation = data.password;
          
          
        },


        async checked_user_portal_data(value){
             this.showLoading(false);
            
            let data = JSON.parse(JSON.stringify(value));
             //console.log(data)
             this.formData.user_portal_idy = data.id;
            this.formData.user_level = data.user_level;
            this.formData.view_portal = data.portal;
            if(this.formData.view_portal == 'PROVIDER'){
              this.formData.provider_id = data.provider_id;
            }



            if(this.formData.user_level == "COUNTRY"){

        await this.search_employee_user_country_portal(this.formData.user_idy).catch(error => {
          // this.SearcherrorServers = error.response.data.error;
           console.log(error);
           this.showLoading(false);
          // notificationBox.errorNotifications(error.response.data.error);

       });

     //  this.user_portal_table_data = this.search_user_portal_id_result;
              
            }



             else  if(this.formData.user_level == "LOCAL"){

        await this.search_employee_user_provider_portal(this.formData.user_idy).catch(error => {
           console.log(error);
           this.showLoading(false);

       });

     //  this.user_portal_table_data = this.search_user_portal_id_result;
              
            }
           
          
        },

    //     existing_user(curValue) {
 

    //   this.existing_userVal(curValue);

    //   //Check iF vAL iS True Or False
    //   if (curValue === "YES") {
    //     this.is_existing_user = true;
    //      this.search = "";
    //      this.errorServers = [];
    //           this.fnameErr= "",
    //   this.lnameErr= "",
    //   this.onameErr= "",
    //   this.genderErr= "",
    //   this.countryErr= "",
    //   this.addressErr= "",
    //   this.stateErr= "",
    //   this.cityErr= "",
    //   this.zipErr= "",
    //   this.contactErr= "",
    //   this.emailErr= "",
    //   this.passwordErr= "",
    //   this.password_confirmationErr= ""
    //   } else {
    //     this.is_existing_user = false;
    //     this.search = "";
    //   this.errorServers = [];
    //   this.clear_main_user();
 

    //   }
    // },



//         async search(curValue) {
//     //  this.searchVal(curValue);

   
//  if(this.is_existing_user){
    

//             let data = {
//               search: curValue,
//       };

//        this.showLoading(true);
//       await this.search_user(data).catch(error => {
//            this.SearcherrorServers = error.response.data.error;
//            console.log(error);
//            this.showLoading(false);
//            this.reset_user_id({});
//            notificationBox.errorNotifications(error.response.data.error);

//        });

//         if(this.user_idd)
//        {
//          this.SearcherrorServers=false;
//       //  let response = await axios.post("getUser",data);
//       // console.log(response.data.data);
//       this.showLoading(false);
//       this.formData.fname = this.search_user_result.fname;
//       this.formData.lname = this.search_user_result.lname;
//       this.formData.gender = this.search_user_result.gender;
//       this.country_name = this.search_user_result.country;
//       this.formData.address = this.search_user_result.address;
//       this.formData.country_id = this.search_user_result.country_id;
//       this.formData.state = this.search_user_result.state;
//       this.formData.city = this.search_user_result.city;
//       this.formData.zip = this.search_user_result.zip;
//       this.formData.contact = this.search_user_result.contact;
//       this.formData.email = this.search_user_result.email;
//       this.formData.password = this.search_user_result.password;
//       this.formData.password_confirmation = this.search_user_result.password;
//        }
//        else{


//           this.showLoading(false);
//     //  this.user_id = this.search_user_result.id
//       // this.formData.fname = "";
//       // this.formData.lname = "";
//       // this.formData.gender = "";
//       // this.country_name = "";
//       // this.formData.address = "";
//       // this.formData.country_id = "";
//       // this.formData.state = "";
//       // this.formData.city = "";
//       // this.formData.zip = "";
//       // this.formData.contact = "";
//       // this.formData.email = "";
//       // this.formData.password = "";
//       // this.formData.password_confirmation = "";

//       this.clear_main_user();

//        }
   
//  }
//     },




    //    "formData.fname"(curValue) {
    //     this.fnameVal(curValue)
    // },

    // "formData.lname"(curValue) {
    //      this.lnameVal(curValue)
    // },

    // "formData.oname"(curValue) {
    //      this.onameVal(curValue)
    // },

    //     "formData.gender"(curValue) {
    //      this.genderVal(curValue)
    // },


    // "formData.country_id"(curValue) {
    //    this.countryVal(curValue);
    // },

    // "formData.address"(curValue) {
    //     this.addressVal(curValue);
    // },

    // "formData.state"(curValue) {
    //   this.stateVal(curValue);
    // },

    // "formData.city"(curValue) {
    //      this.cityVal(curValue);
    // },

    //     "formData.zip"(curValue) {
    //    this.zipVal(curValue);
    // },

    // "formData.contact"(curValue) {
    //    this.contactVal(curValue);
    // },

    // "formData.email"(curValue) {
    //     this.emailVal(curValue);
    // },

    // "formData.password"(curValue) {
    //    this.passwordVal(curValue);
    // },

    // "formData.password_confirmation"(curValue) {
    //     this.password_confirmationVal(curValue);      
    // },

    
    // "userPortData.user_level"(curValue) 
    // {
    //    this.user_levelVal(curValue);
    // },




    }
        
    }
</script>

<style scoped>


</style>