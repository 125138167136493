<template>
     <div class="col-4 text-end">
        <div style="min-width: 400px; float:right;">
                <div class="input-group mb-3">
                <input type="text" class="form-control" v-model="search" placeholder="Search" name="SearchTerm">
                </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'TableSearch',
        emits:['searchValue'],

        data(){
            return {
                search:"",
            }
        },


       watch:{
        search(value){
            this.$emit('searchValue', value)
        }
       }

        
    }
</script>

<style scoped>

</style>