import { GET_EMPLOYEE_USER_PROVIDER_PORTAL, GET_EMPLOYEE_USER_PROVIDER_PORTAL_ID, SEARCH_EMPLOYEE_USER_PROVIDER_PORTAL, SEARCH_EMPLOYEE_USER_PROVIDER_PORTAL_ID } from '@/store/storeconstants';
import axios from 'axios';

export default {

    async [SEARCH_EMPLOYEE_USER_PROVIDER_PORTAL](context,payload){
        let response = await axios.get(`getEmployeeProviderPortal/${payload}`);
        let data = response.data.data;
       // console.log(data);
        context.commit(GET_EMPLOYEE_USER_PROVIDER_PORTAL,data);
    },



    async[SEARCH_EMPLOYEE_USER_PROVIDER_PORTAL_ID](context,payload){
              
        let response = await axios.get(`gerdd_employee_providers/${payload}`);
        let data = response.data.data;

        context.commit(GET_EMPLOYEE_USER_PROVIDER_PORTAL_ID,data)
    }

}