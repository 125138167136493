<template>
  <section id="main-content">
      <div class="row">
          <div class="col-lg-12">
              <div id="extra-area-chart"></div>
              <div id="morris-line-chart"></div>
              <div class="footer">
                  <p>{{ year }} © AGdataTech Company Ltd.
                      <!-- <a href="http://cfan.com">emedipay.com</a> -->
                  </p>
              </div>
          </div>
      </div>
  </section>
</template>

<script>
export default {
  name: "FooterComp",
  created(){
     this.year = new Date().getFullYear();
  },
  data: function(){
    return{
        year:"",
    }
  },



};
</script>

<style scoped></style>