<template>
    <div>
        <SideBarComp/>
        <NavBarComp/>
        <div class="content-wrap">
        <div class="main">
            <div class="section__content section__content--p30">
                <div class="container-fluid">  
                    <HeaderComp page="Support Members" pageDesc="list of all support members"/>                
                    <section id="main-content">
                        <div class="row ps-3 pe-3">
                            <div class="col-md-12">                               
                                <div class="row">

                                            <!-- <div class="col-1 text-end">
                                        <div class="d-flex align-items-center ml-4" style="min-width: 100px; float:right;">
                                            <label for="paginate" class="text-nowrap mr-2 mb-0"><strong>Per Page</strong></label>
                                            <select v-model.number.trim="perPage" class="form-control form-control-sm">
                                                <option value="20">20</option>
                                                <option value="2">2</option>
                                                <option value="40">40</option>
                                                <option value="60">60</option>
                                                <option value="80">80</option>
                                                <option value="100">100</option>
                                                <option value="120">120</option>
                                                <option value="140">140</option>
                                             </select>
                                       
                                        </div>
                                    </div> -->

                                    <PerPageComponent @perpageValue="perpageval" />

                                    <TableFilter @statusValue="statusval" portall="" />

                                        <!-- <div class="col-3 text-end">
                                         <div class="d-flex align-items-center ml-4">
                                            <label for="paginate" class="text-nowrap mr-2 mb-0"><strong>FilterBy Status</strong></label>
                                            <select v-model="status" class="form-control form-control-sm">
                                                <option value="">All Status</option>
                                                <option value="PENDING">PENDING</option>
                                                <option value="APPROVED">APPROVED</option>
                                                <option value="REJECTED">REJECTED</option>
                                                <option value="BAN">BAN</option>
                                            </select>

                                         </div>
                                    </div> -->
                                



                                    <!-- <div class="col-4 text-end">
                                        <div style="min-width: 400px; float:right;">
                                                <div class="input-group mb-3">
                                                <input type="text" class="form-control" v-model="search" placeholder="Search" name="SearchTerm">
                                                </div>
                                        </div>
                                    </div> -->

                                    <TableSearch @searchValue="searchval" />


                                  
                                     <!-- <div class="col-2 text-end">
                          
                                        <div class="dropdown ml-4">
                                            <button v-if="checked.length > 0" class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown">
                                                With checked ({{ checked.length }})
                                            </button>
                                            <div class="dropdown-menu">
                                                <a  onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" href="#" class="dropdown-item" type="button" @click.prevent="deleteRecords">
                                                    Delete
                                                </a>
                                                   <a :href="url"  class="dropdown-item" type="button">
                                                    Export
                                                </a>
                                            </div>
                                        </div>
                                    </div> -->


                                    <ActionButton :num_checked="checked.length" portall="" />









                                
                                    <div class="col-2 text-end">
                                        <!-- <a class="btn btn-outline-success" @click="userAdd()" data-bs-toggle="modal"  href="#userModal">
                                            <i class="fas fa-plus"></i> Add User
                                        </a>    -->


                                           <a style="display:none" class="btn btn-outline-success" ref="support_ban_mode" data-bs-toggle="modal"  href="#supportBanReasonModal">
                                            <i class="fas fa-plus"></i> Support Edit
                                        </a>   

                                         <a class="btn btn-outline-success" style="display:none" ref="viewSupportDetail" data-bs-toggle="modal" href="#supportDedailModule" >
                                            <i class="fas fa-plus"></i> View Support Detail
                                        </a>


                                        <a class="btn btn-outline-success" style="display:none" ref="viewSupportDependentDetail" data-bs-toggle="modal" href="#supportDependentDetailModule" >
                                            <i class="fas fa-plus"></i> View Support Dependent Detail
                                        </a>    

                                          <!-- <a class="btn btn-outline-success" style="display:none" ref="userEditEmployeeProviderModal" data-bs-toggle="modal" href="#userEmployeeProviderModal" >
                                            <i class="fas fa-plus"></i> Link User To Provider Portal
                                        </a>  -->
                                    </div>


                                             <!-- <div class="col-4 text-start" v-if="selectPage">

                                            <div v-if="selectAll">
                                                You are currently selecting all
                                                <strong>{{ checked.length }}</strong> items.
                                            </div>

                                            <div v-else>
                                                You have selected <strong>{{ checked.length }}</strong> items, Do you want to
                                                Select All <strong>{{total}}</strong>?
                                                 <a @click.prevent="selectAllRecords" href="#" class="m1-2">Select All</a>
                                            </div>
                                       
                                    </div> -->


                                    <SelectAllComponent @selRecords="selectAllRecords" :num_checked="checked.length" v-if="selectPage" />


                                </div>
                            </div>
                            
                            <div class="card shadow-sm">
                                <div class="card-body">
                                    <div class="table-responsive table-responsive-data2">
                                        <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                                <!-- <th scope="col">#</th> -->
                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('users.fullname')">Full Name</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.fullname'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('users.gender')">Gender</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.gender'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.gender'">&darr;</span>
                                                </th>

                                     

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('users.contact')">Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.contact'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('users.email')">Email</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.email'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.email'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('countries.country')">Country</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='countries.country'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='countries.country'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('users.address')">Address</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.address'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.address'">&darr;</span>
                                                </th>

                                        

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('users.city')">City</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.city'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.city'">&darr;</span>
                                                </th>



                                                <th scope="col" v-if="app_portal == 'Gerdd'">
                                                <a href="#" @click.prevent="change_sort('users.city')">Status</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.city'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.city'">&darr;</span>
                                                </th>



                                                <th scope="col">
                                                Action
                                                </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(supp) in users" :key="supp.id" :class="isChecked(supp.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="supp.id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{supp.fullname}}</td>
                                                    <td>{{supp.gender }}</td>
                                                    <td>{{supp.contact }}</td>
                                                    <td>{{supp.email }}</td>
                                                    <td>{{supp.country }}</td>
                                                    <td>{{supp.address }}</td>
                                                    <td>{{supp.city }}</td>
                                                    <td v-if="app_portal == 'Gerdd'">
                                                    <select
                                                        class="form-control"
                                                        onchange="confirm('Are You Sure?') || event.stopImmediatePropagation()"
                                                        @change="statusVal($event.target.value,supp.id)"
                                                        v-model.trim="supp.status">
                                                        <option value="APPROVED">APPROVED</option>
                                                        <option value="BAN">BAN</option>
                                                    </select>
                                                    </td>
                                                    <td>
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                                            <li class="d-flex">                                                    
                                                                <div class="btn"  title="Ban With Note" @click="supportBanNoteMode(supp.id)" v-if="app_portal == 'Gerdd'">
                                                                    <i class="fa-solid fa-note-sticky"></i>
                                                                </div>                                                                                                   
                                                                <a class="btn" title="View Details" @click="suppView(supp.id)" >
                                                                    <i class="fa-solid fa-eye"></i>
                                                                </a>

                                                                  <a class="btn" title="View Dependent" @click="suppDepp(supp.id)" v-if="app_portal == 'Gerdd'">
                                                                    <i class="fa-solid fa-user"></i>
                                                                </a>
                                                            </li>
                                                        </div>
                                                    </td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="row mt-4">
                                             <VueTailwindPagination
                                            :current="currentPage"
                                            :total="total"
                                            :per-page="perpage"
                                            @page-changed="pageChange($event)"
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <FooterComp/>
                </div>
            </div>
        </div>
        </div> 

        <SupportBanNote @banSucc="banSucc"  :getSupportId="support_id" />

        <ViewSupportDetail :getSupportData="getSupportData" />

        <ViewSupportDependentDetail :getSupportDependentData="getSupportDependentData" />

        <!-- <UserModals @userAdded="userAdded"  @provider_view_typee="provider_view_typee"   @country_view_typee="country_view_typee"  :getUserPortalData="getUserPortalData"   :view_type="view_type"  />

        <EmployeeUserCountry @userView="userView" :employee_country_view_type="country_view_type" :employee_user_id="employee_user_id"  />    
  
        <EmployeeUserProvider @userView="userView"   :employee_provider_view_type="provider_view_type" :employee_provider_user_id="employee_provider_user_id" />      -->
        <!-- Modal -->
        <!-- <div class="modal fade" id="userModal" tabindex="-1" aria-labelledby="userModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="userModalLabel">Modal title</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                ...
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>
            </div>
        </div>
        </div> -->
    </div>
</template>


<script>
// @ is an alias to /src
import HeaderComp from "@/components/HeaderComp.vue"
import FooterComp from "@/components/FooterComp.vue"
import SideBarComp from "@/components/SideBarComp.vue"
import NavBarComp from "@/components/NavBarComp.vue"
import PerPageComponent from "@/components/table/PerPage.vue"
import TableSearch from "@/components/table/TableSearch"
import TableFilter from "@/components/table/TableFilter"
import ActionButton from "@/components/table/ActionButton"
import SelectAllComponent from "@/components/table/SelectAllComponent"
import SupportBanNote from "@/components/modalForms/SupportBanNote.vue"
import ViewSupportDetail from "@/components/modalForms/ViewSupportDetail.vue"
import ViewSupportDependentDetail from "@/components/modalForms/ViewSupportDependentDetail.vue"
import axios from "axios"

//import "@ocrv/vue-tailwind-pagination/dist/style.css";
import VueTailwindPagination from "@ocrv/vue-tailwind-pagination";
import "@ocrv/vue-tailwind-pagination/styles";
import notificationBox from '@/services/notificationBox'
import {BASEURL} from '@/services/UserService';
import {  mapMutations, mapState } from 'vuex'
import { LOADING, TABLE_CURRENT_PAGE, TABLE_PER_PAGE, TABLE_SEARCH, TABLE_SEL_ALL, TABLE_SORT_FIELD_DIRECTION, TABLE_STATUS, TABLE_TOTAL, TABLE_URL } from '@/store/storeconstants'


export default {
  name: "SupportMembersView",
  components: {
    HeaderComp, 
    FooterComp,
    SideBarComp, 
    NavBarComp,
    PerPageComponent,
    TableSearch,
    TableFilter,
    ActionButton,
    SelectAllComponent,
    VueTailwindPagination,
    SupportBanNote,
    ViewSupportDetail,
    ViewSupportDependentDetail
  },



  data(){
    return {
        users:{},
        showUserModals:false,

        //currentPage:1,
        //total:100,
        perPage:20,
        //perPage:20,
        search:"",
        status: "",
        checked:[],
        user_portal_id:"",
        user_id:"",
        getUserPortalData:{},
        getSupportDependentData:{},
        user_table_data:{},
        user_portal_table_data:{},
        view_type:"",
        app_portal:"",
        provider_id:"",
        country_view_type:"",
        provider_view_type:"", 
        employee_user_id:"",
        employee_provider_user_id:"",
        support_id:"",
        dependent_id:"",
        getSupportData:{},



        login_user_id:"",
        user_level:"",
        selectPage:false,
        //selectAll:false,
     //   sort_direction: 'desc',
     //   sort_field: 'fullname',
      //  url:'',
    }
  },


  computed:{
    ...mapState('dataTable',['currentPage','url','total','perpage','getstatus','getsearch','selectAll','sort_direction','sort_field']),
    ...mapState("user_portal",['search_user_portal_id_result']),
    ...mapState("user",['search_user_id_result'])
  },



  methods:{

       ...mapMutations({
       table_select_all: 'dataTable/'+TABLE_SEL_ALL,
       table_sort_field_direction: 'dataTable/'+TABLE_SORT_FIELD_DIRECTION,
       table_current_page:'dataTable/'+TABLE_CURRENT_PAGE,
       table_url:'dataTable/'+TABLE_URL,
       table_per_page :'dataTable/'+TABLE_PER_PAGE,
       table_total :'dataTable/'+TABLE_TOTAL,
       table_search :'dataTable/'+TABLE_SEARCH,
       table_status :'dataTable/'+TABLE_STATUS,
       showLoading:LOADING,

     //  verified_username:'auth/'+SUCC_AUTH_VERIFY,
   }),




    selectAllRecords(){

        axios.get('supports/all?status=' + this.getstatus  + '&portal=' + this.app_portal +
            '&login_user_id=' + this.login_user_id +
            '&user_level=' + this.user_level)
             .then(response => {
                 console.log(response.data);
                  this.checked = response.data;
                  // this.selectAll = true;
                 // this.table_checked(response.data);
                   this.table_select_all(true);
             });
    },



    change_sort(field){
        // if(this.sort_field == field){
        //  this.sort_direction =   this.sort_direction == "asc" ? "desc" : "asc"
        // }else{
        //     this.sort_field = field;
        // }
        this.table_sort_field_direction(field);
        this.getSupportMember();

    },



   deleteSingleRecord(id){

    //console.log(id);

       axios.delete(`supports/${id}`)
       .then( (response) => {
        this.checked = this.checked.filter(user_portal_id => user_portal_id != id)
        //this.table_checked(this.checked.filter(user_portal_id => user_portal_id != id))
        console.log(response.data.success)
        notificationBox.succNotifications("Deleted Successfully");
         this.getSupportMember();
       }).catch(error => {
        console.log(error.message)
         notificationBox.errorNotifications(error)
       })


   },



   deleteRecords(){
      axios.delete(`supports/massDestroy/${this.checked}`)
      .then((response) => {
         if(response.status == 204){
            console.log(response.data.success)
            notificationBox.succNotifications("Selected Support Member were Deleted Successfully");
            this.checked = [];
            //this.table_checked([])
            this.getSupportMember();

         }
      })
   },





   


    pageChange(pageNumber){

        //this.currentPage = pageNumber;
        this.table_current_page(pageNumber);
        this.getSupportMember();

    },




   isChecked(id){

    return  this.checked.includes(id);

   },



    getSupportMember(){
   
        axios.get(
            'supports?page=' + this.currentPage + 
            '&page_size=' + this.perpage + 
            '&q=' +this.getsearch + 
            '&status=' + this.getstatus +
            '&sort_direction=' + this.sort_direction +
            '&sort_field=' + this.sort_field +
            '&portal=' + this.app_portal +
            '&login_user_id=' + this.login_user_id +
            '&user_level=' + this.user_level 
            )
        .then(response => {

            this.users = response.data.data.data;
            //this.perPage = response.data.data.per_page;
               this.table_per_page(response.data.data.per_page);
            //this.total = response.data.data.total;
             this.table_total(response.data.data.total)
        });

    },



    perpageval(value){
        this.table_per_page(value);
       this.getSupportMember();

    },


    banSucc(){

        this.getSupportMember();

    },


    searchval(value){

         this.table_search(value);
        this.getSupportMember();

    },

    statusval(value){

        this.table_status(value);
        this.getSupportMember();

    },

 

   

     statusVal(status,id)
     {
        try{
 
     const formData = {
        "id": id,
        "status": status,
        "reason": null
     }
         
       const response = axios.post("update_support_status",formData)
       if(response.status == 200)
       {

         this.getSupportMember();
         
       }


        }catch(error){
            console.log(error);
            notificationBox.errorNotifications(error.response.data.error)
        }
     },




     supportBanNoteMode(id){

        this.support_id = id;
        this.$refs.support_ban_mode.click();
         
     },


     suppView(id){

       this.getSupportDetail(id)
        this.$refs.viewSupportDetail.click();

     },






        async getSupportDetail(support_id){
         
             this.showLoading(true);
             try{
            //   console.log("From Watchers",user_portal_id);
            //   console.log("From Props",this.get_user_portal_id);
            
            const response = await axios.get(`supports/${support_id}`);
            this.showLoading(false);
                this.getSupportData = response.data.data;
             }catch(error){
                this.showLoading(false);
             }
          
              },






             suppDepp(id){

                
       this.getSupportDependentDetail(id)
        this.$refs.viewSupportDependentDetail.click();

             },


          async getSupportDependentDetail(support_id){
         
             this.showLoading(true);
             try{
            //   console.log("From Watchers",user_portal_id);
            //   console.log("From Props",this.get_user_portal_id);
            
            const response = await axios.get(`supportDepenedentDetails?support_id=${support_id}`);
            console.log(response);
            this.showLoading(false);
                this.getSupportDependentData = response.data.data;
             }catch(error){
                console.log(error);
                this.showLoading(false);
             }
          
              },




              //Get Support Member For Edit
              async getSuppMemEdit(support_id){
         
             this.showLoading(true);
             try{
            //   console.log("From Watchers",user_portal_id);
            //   console.log("From Props",this.get_user_portal_id);
            
            const response = await axios.get(`supports/${support_id}`);
            this.showLoading(false);
                this.getUserPortalData = response.data.data;
             }catch(error){
                this.showLoading(false);
             }
          
              },


    //  userAdd(){

    //     this.view_type = "add";

    //  },


     supportAdded(){
        this.getSupportMember();
     },



    //  country_view_typee(edit_value,id){

    //     this.country_view_type = edit_value;
    //     this.employee_user_id = id;
    //     this.$refs.userEditEmployeeCountryModal.click();
    //  },



    //  provider_view_typee(edit_value,id){

    //      this.provider_view_type = edit_value;
    //     this.employee_provider_user_id = id;
    //     this.$refs.userEditEmployeeProviderModal.click();

    //  }

  
  },





 async created(){

        const user_data = JSON.parse(localStorage.getItem("users"));
          this.provider_id = user_data.provider_id;
         // console.log(this.provider_id);
          let user_idd = user_data.id;
          this.login_user_id = user_idd;
          this.user_level = user_data.user_level;
    // this.portal =  localStorage.getItem('Portall')
    this.app_portal = localStorage.getItem("Portall");
    //console.log(this.app_portal);



      const  user_dataa = {
            user_id : user_idd 
        }
       let response = await axios.post("getSupportId",user_dataa);
 
        this.support_id =  JSON.parse(JSON.stringify(response.data.data));



          let responsee = await axios.post("getDependentId",user_dataa);
 
        this.dependent_id =  JSON.parse(JSON.stringify(responsee.data.data));
      
       
       
       
      


    //this.currentPage = 1;
    this.table_current_page(1);
    this.getSupportMember();
  },


  watch:{
    // "perPage"(value){
    //    this.table_per_page(value);
    //    this.getUser();
    // },
 
    //  "search"(value){
    //     this.table_search(value);
    //     this.getUser();
    //  },

    //  "status"(value){
    //     this.table_status(value);
    //     this.getUser();
    //  },

     "selectPage"(value){
         this.checked = [];
       //  this.table_checked([]);

        if(value){
            const users = JSON.parse(JSON.stringify(this.users));
            users.forEach(user=>{
                this.checked.push(user.id)
                //this.table_checked_push(user.id);
            });
        
        }else{
              
              this.checked = [];
              //this.table_checked([]);
              //this.selectAll = false;
              this.table_select_all(false);
        }

     },

     "checked"(){

        this.table_url(BASEURL + "supports/export/"+ this.checked);
       // this.url = BASEURL + this.url + this.checked;

     }

  }

};
</script>