<template>
    <div>
        <SideBarComp/>
        <NavBarComp/>
        <div class="content-wrap">
        <div class="main">
            <div class="section__content section__content--p30">
                <div class="container-fluid">  
                    <HeaderComp page="Convert Per Church Report" pageDesc="convert report"/>                
                    <section id="main-content">
                        <div class="row ps-3 pe-3">
                            <div class="col-md-12">                               
                                <div class="row">

                                   
                                    <!-- <PerPageComponent @perpageValue="perpageval" />

                                    <TableFilter @statusValue="statusval" portall="" />

                    

                                    <TableSearch @searchValue="searchval" />


                                  
    


                                    <ActionButton  @deleteRecords="deleteRecords" :num_checked="checked.length" portall="" />









                                
                                    <div class="col-2 text-end">
                        

                                                   
                                        <a class="btn btn-outline-success" @click.prevent="crusade_typeAdd" ref="crusade_type_add_ref" data-bs-toggle="modal"  href="#crusade_typeModal" v-if="app_portal=='MAIN'">
                                            <i class="fas fa-plus"></i> Add Crusade Type
                                        </a>   


                                           <a style="display:none" class="btn btn-outline-success" ref="crusade_type_ref" data-bs-toggle="modal"  href="#crusade_typeModal">
                                            <i class="fas fa-plus"></i> Crusade Type Ref
                                        </a>   

                              

                                        
                                    </div>


                            


                                    <SelectAllComponent @selRecords="selectAllRecords" :num_checked="checked.length" v-if="selectPage" /> -->


                                </div>
                            </div>


                            <div class="card shadow-sm" id="stylized">
                              <form class="register-form" @submit.prevent="searchConvertByChurch()">

                                <div class="card-body">





                <div class="row form_space" style="margin-bottom:10px">
                    <div class="col">
                                <label><span class="text_error">*</span>Crusade Type</label>
                             <div class="input-group">
                          
                         <v-select :options="getCrusadeTypes"  class="form-control select1" v-model="formData.crusade_type_id"  :reduce="crusade_type => crusade_type.id" label="crusade_type" @option:selected="crusade_typeVal" />

                                                  
                        
                        </div>

                          <div
                            :class="{ text_error: crusade_typeErr }"
                            v-if="crusade_typeErr"
                          >
                            {{ crusade_typeErr }}
                          </div>
                        </div>



                


                    </div>




                           


                             <div class="row">

                               <div class="col">
                              <label>View By</label>
                            <div class="input-group">
                          
                          <select
                            class="form-control"
                            :class="{ form_error: view_byErr}"
                             @change="view_byVal($event.target.value)"
                            v-model.trim="formData.view_by">
                            <option value="">--Choose An Option--</option>
                            <option value="AREA" v-if="app_portal=='MAIN'">AREA</option>
                            <option value="DISTRICT">DISTRICT</option>
                          </select>
                          
                          
                        </div>
                        <div
                            :class="{ text_error: view_byErr}"
                            v-if="view_byErr"
                          >
                            {{ view_byErr }}
                          </div>
                      </div>

                      


                               <div class="col">
                        <label><span class="text_error">*</span>From Date</label>
                        <div class="input-group">
                          

                          <input
                            type="date"
                            class="form-control"
                            :class="{ form_error: from_dateErr}"
                            placeholder="yyyy-mm-ddd"
                            @blur="from_dateVal($event.target.value)" 
                            v-model.trim="formData.from_date"
                          />
                         
                        </div>
                         <div
                            :class="{ text_error: from_dateErr  }"
                           v-if="from_dateErr">
                          {{ from_dateErr }} 
                          </div>
                      </div>





                                      <div class="col">
                        <label><span class="text_error">*</span>To Date</label>
                        <div class="input-group">
                          

                          <input
                            type="date"
                            class="form-control"
                            :class="{ form_error: to_dateErr}"
                            placeholder="yyyy-mm-ddd"
                            @blur="from_dateVal($event.target.value)" 
                            v-model.trim="formData.to_date"
                          />
                         
                        </div>
                         <div
                            :class="{ text_error: to_dateErr  }"
                           v-if="to_dateErr">
                          {{ to_dateErr }} 
                          </div><br/>


                 <button type="submit" class="btn btn-primary">Generate Report</button>

                      </div>



         

    

                     




                    </div>

                    

                         
                                   
                                </div>
                                 </form>


                            <!-- Table Goes Here -->

                            </div>





                             <div class="container" v-if="report_converts.length > 0">
                                        <div class="row gutters">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div class="custom-actions-btns mb-5">
                                            <!-- <a href="#" class="btn btn-primary">
                                                <i class="icon-download"></i> Download
                                            </a> -->
                                            <a href="#" @click="printDiv()" class="btn btn-secondary">
                                                <i class="icon-printer"></i> Print
                                            </a>
                                        </div>
                                    </div>
                                </div>

        <vue-easy-print tableShow ref="easyPrint" >
        <div class="row gutters" id="printableArea">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="card">
                    <div class="card-body p-0">
                        <div class="invoice-container">
                            <div class="invoice-header">
                                <!-- Row start -->
                        
                                <!-- Row end -->
                                <!-- Row start -->
                                <div class="row gutters">
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                        <img src="@/assets/pent_logo.jpg" style="height: 80px;"/>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <address class="text-right">
                                                {{ portal_info   }}.<br>
                                                {{ app_portal   }}.<br>
                                              
                                            </address>
                                    </div>
                                </div>
                                <!-- Row end -->
                                <!-- Row start -->
                                <div class="row gutters">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div class="invoice-details">
                                            <div class="row">
                                                <div class="col">
                                                    <b>From Date:</b>
                                                    {{ formData.from_date }}
                                                    </div>
                                                <div class="col">
                                                    <b>To Date:</b>
                                                    {{ formData.to_date }}
                                                    </div>
                                               
                                                </div>

                                                   <div class="row">

                                                     <div class="col">
                                                    <b>View By:</b>
                                                    {{ formData.view_by }}
                                                    </div>


                                                       <div class="col">
                                                    <b>Crusade Type:</b>
                                                    {{ formData.crusade_type }}
                                                    </div>

                                                   </div>
                                            <!-- <addr>
                                                    From Date<br>
                                                    Denominatio
                                                </address> -->
                                        </div>
                                      
                                    </div>
                                    <!-- <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                        <div class="invoice-details">
                                            <div class="invoice-num">
                                                <div>Invoice - #009</div>
                                                <div>September 12th 2022</div>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                                <!-- Row end -->
                            </div>
                            <div class="invoice-body">
                                <!-- Row start -->
                                <div class="row gutters">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="table-responsive">
                                            <table class="table custom-table m-0">
                                                <thead>
                                                    <tr>
                                                        <th v-if="formData.view_by == 'DENOMINATION'">
                                                            Denomination

                                                        </th>
                                                        <th v-if="formData.view_by == 'CHURCH'">
                                                            Church

                                                        </th>
                                                        <th>Number</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(cov) in report_converts" :key="cov.id">
                                                        <td>{{ cov.dname }}</td>
                                                      
                                                        <td>{{ cov.convert_count }}</td>
                                                       
                                                    </tr>
                                                   
                                                    <tr>
                                                        <!-- <td>&nbsp;</td> -->
                                                        <td>
                                                          
                                                            <h5 class="text-success"><strong>Total</strong></h5>
                                                        </td>
                                                        <td>
                                                         
                                                            <h5 class="text-success"><strong>{{total_convert_count}}</strong></h5>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <!-- Row end -->
                            </div>
                            <div class="invoice-footer">
                                {{ year }}.  GREATER ACCRA UNLEASHED CRUSADE (CoP)
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </vue-easy-print>
    </div>
                        


                   



                         




                        </div>
                    </section>
                    <FooterComp/>
                </div>
            </div>
        </div>
        </div> 

  


        <!-- <UserModals @userAdded="userAdded"  @provider_view_typee="provider_view_typee"   @country_view_typee="country_view_typee"  :getUserPortalData="getUserPortalData"   :view_type="view_type"  />

        <EmployeeUserCountry @userView="userView" :employee_country_view_type="country_view_type" :employee_user_id="employee_user_id"  />    
  
        <EmployeeUserProvider @userView="userView"   :employee_provider_view_type="provider_view_type" :employee_provider_user_id="employee_provider_user_id" />      -->
        <!-- Modal -->
        <!-- <div class="modal fade" id="userModal" tabindex="-1" aria-labelledby="userModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="userModalLabel">Modal title</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                ...
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>
            </div>
        </div>
        </div> -->
    </div>
</template>


<script>
// @ is an alias to /src
import HeaderComp from "@/components/HeaderComp.vue"
import FooterComp from "@/components/FooterComp.vue"
import SideBarComp from "@/components/SideBarComp.vue"
import NavBarComp from "@/components/NavBarComp.vue"

// import TableFilter from "@/components/table/TableFilter"
import axios from "axios"
import FieldValidateService from "@/services/FieldValidateService";

//import "@ocrv/vue-tailwind-pagination/dist/style.css";
import "@ocrv/vue-tailwind-pagination/styles";
import notificationBox from '@/services/notificationBox'
import {  mapMutations, mapState } from 'vuex';
import { LOADING, TABLE_CURRENT_PAGE, TABLE_PER_PAGE, TABLE_SEARCH, TABLE_SEL_ALL, TABLE_SORT_FIELD_DIRECTION, TABLE_STATUS, TABLE_TOTAL, TABLE_URL } from '@/store/storeconstants';
import vSelect from 'vue-select';
import vueEasyPrint from "vue-easy-print";


export default {
  name: "ConvertReportView",
    beforeRouteEnter(to,from,next){
     let user_level = "";
     if(Array.isArray(JSON.parse(localStorage.getItem("user_portal_info")))) {
     const user_data = JSON.parse(localStorage.getItem("user_portal_info"));
     user_level = user_data[0].user_level;
     }
     else{
             const user_data = JSON.parse(localStorage.getItem("user_portal_info"));
             user_level = user_data.user_level;
     }
   
    
    if(user_level=='SUPER ADMIN' || user_level=='SECRETARY' || user_level=='AREA ADMIN' || user_level=='AREA SECRETARY'){
        next();

    }else{
  
        next("/user-not-authorise"); 
    }
 
    
  },
  components: {
    HeaderComp, 
    FooterComp,
    SideBarComp, 
    NavBarComp,
    vueEasyPrint ,

    // TableFilter,

    vSelect,

  },



  data(){
    return {

      grid_converts:[],
      report_converts:[],
      total_convert_count:"",
        //showUserModals:false,

        //currentPage:1,
        //total:100,
        perPage:20,
        //perPage:20,
        search:"",
        is_active: "",
        checked:[],
        user_table_data:{},
        user_portal_table_data:{},
        getCrusadeTypeData:{},
        view_type:"",
        app_portal:"",
        user_portal_info:{},
        getSearchBy:[],
        getCrusadeTypes:[],
        errorClient:[],
        errorServers:[],
        portal_name:"",
      portal_info:"",
        // provider_id:"",
        // country_view_type:"",
        // provider_view_type:"", 
        // employee_user_id:"",
        // employee_provider_user_id:"",
        // support_id:"",
        // dependent_id:"",
        // getSupportData:{},


        login_user_id:"",
        user_level:"",
        selectPage:false,
        year:"",

         formData:{
            view_by:"",
            from_date:"",
            to_date:[],
            crusade_type_id:"",
            crusade_type:"",
            denomination_id:"",
         },

         view_byErr:"",
         from_dateErr:"",
         to_dateErr:"",
         crusade_typeErr:"",



    }
  },


  computed:{
    ...mapState('dataTable',['currentPage','url','total','perpage','getstatus','getsearch','selectAll','sort_direction','sort_field']),
    ...mapState("user_portal",['search_user_portal_id_result']),
    //...mapState("user",['search_user_id_result'])
  },



  methods:{

       ...mapMutations({
       table_select_all: 'dataTable/'+TABLE_SEL_ALL,
       table_sort_field_direction: 'dataTable/'+TABLE_SORT_FIELD_DIRECTION,
       table_current_page:'dataTable/'+TABLE_CURRENT_PAGE,
       table_url:'dataTable/'+TABLE_URL,
       table_per_page :'dataTable/'+TABLE_PER_PAGE,
       table_total :'dataTable/'+TABLE_TOTAL,
       table_search :'dataTable/'+TABLE_SEARCH,
       table_status :'dataTable/'+TABLE_STATUS,
       showLoading:LOADING,

     //  verified_username:'auth/'+SUCC_AUTH_VERIFY,
   }),




 async get_crusade_type_by_id(){
           try{
       let response = await axios.get(`getCrusadeTypeById?crusade_type_id=${this.formData.crusade_type_id}`);
        console.log(response);
        this.formData.crusade_type =  JSON.parse(JSON.stringify(response.data.data));
       // this.getPortalDenominations =  JSON.parse(JSON.stringify(response.data.data));
        //console.log("meeeeeeee");
       
      // console.log(response);
      
    }catch(error){
        console.log(error);
    }
},






crusade_typeVal(value){

             let field = "Crusade Type";

                   if(typeof value == "object" && value!=null)
             {
             value = value.id;
             }

      if(FieldValidateService.reqVal2(value,field)){
        this.crusade_typeErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.crusade_typeErr);
      }else{
        if(this.errorClient.includes(this.crusade_typeErr))
        {
        let pos = this.errorClient.indexOf(this.crusade_typeErr);
        this.errorClient.splice(pos,1);
        }
        this.crusade_typeErr =""; 
        this.get_crusade_type_by_id();
      }

    },

    


async getPortal(){


 
if(Array.isArray(JSON.parse(localStorage.getItem("user_portal_info")))) {
   this.app_portal = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal;
     this.formData.denomination_id = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_denomination_id;


}
else{


     this.app_portal = JSON.parse(localStorage.getItem("user_portal_info")).portal;
       this.formData.denomination_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_denomination_id;



}




  let user_info = [];
  user_info = JSON.parse(localStorage.getItem("user_portal_info"));
   //console.log(user_info);
    if(Array.isArray(user_info)) {
        this.portal_name = user_info[0].portal;

          if(user_info[0].portal  == "MAIN"){
   this.portal_info  = "CoPAccra";
  }
  else if(user_info[0].portal  == "AREA"){
    this.portal_info  = user_info[0].portal+" - "+user_info[0].portal_denomination
  }
    else if(user_info[0].portal  == "DISTRICT"){
    this.portal_info  = user_info[0].portal+" - "+user_info[0].portal_church;
  }
    }
    else
    {
  this.portal_name = user_info.portal;
    if(user_info.portal  == "MAIN"){
   this.portal_info  = "CoPAccra";
  }
  else if(user_info.portal  == "AREA"){
    this.portal_info  = user_info.portal+" - "+user_info.portal_denomination
  }
    else if(user_info.portal  == "DISTRICT"){
    this.portal_info  = user_info.portal+" - "+user_info.portal_church;
  }
    }

},





          view_byVal(value) {

      let max = 500;
      let field = "View By";
     
     if(FieldValidateService.reqMaxStringVal(value,field,max))
     {
       this.view_byErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.view_byErr);
     }else{

           if(this.errorClient.includes(this.view_byErr))
           {
            let pos = this.errorClient.indexOf(this.view_byErr);
           this.errorClient.splice(pos,1);
            this.view_byErr =""; 
           }
          
          

     }
    },












     from_dateVal(value){

        let field = "From Date"

      if(FieldValidateService.reqVal2(value,field)){
        this.from_dateErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.from_dateErr);
      }else{
        if(this.errorClient.includes(this.from_dateErr))
        {
        let pos = this.errorClient.indexOf(this.from_dateErr);
        this.errorClient.splice(pos,1);
        }
        
        this.from_dateErr =""; 
      }

    },




         to_dateVal(value){

            let field = "To Date";

      if(FieldValidateService.reqVal2(value,field)){
        this.to_dateErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.to_dateErr);
      }else{
        if(this.errorClient.includes(this.to_dateErr))
        {
        let pos = this.errorClient.indexOf(this.to_dateErr);
        this.errorClient.splice(pos,1);
        }
        
        this.to_dateErr =""; 
      }

    },








    async searchConvertByChurch(){

       this.errorServers = [];
       this.errorClient = [];
       this.report_converts = [];
       this.showLoading(true);

        this.crusade_typeVal(this.formData.crusade_type_id);
        this.view_byVal(this.formData.view_by);
        this.from_dateVal(this.formData.from_date);
        this.to_dateVal(this.formData.to_date);



          if(this.errorClient.length > 0)
       {
         this.showLoading(false);
          notificationBox.errorNotifications("Some Errors Exist");
          console.log(this.errorClient);
          return true;
       }

       else{

   


                 try{


                   const formData = {

            crusade_type_id: this.formData.crusade_type_id,
            view_by: this.formData.view_by,
            from_date: this.formData.from_date,
            to_date: this.formData.to_date,
            portal: this.portal_name,
            denomination_id: this.formData.denomination_id,

            
        };


            let response = await axios.post("searchConvertByChurch",formData);
              if(response.status == 200)
              {
                   this.showLoading(false);

                     //this.clearFormData();
        // notificationBox.succNotifications("Convert Added Successfully");
        // this.$emit("convertAdded");
    
               
                  
                this.report_converts = response.data.data;
                //console.log(this.report_converts);
                this.total_convert_count = response.data.total_convert_count;
                  this.year = new Date().getFullYear(); 
               
              }


         }catch(error){

                  this.showLoading(false);
                  console.log(error)
               // console.log(error.response.data.error);
                 notificationBox.errorNotifications(error.response.data.error);
  

         }

       }


    },




printDiv() {
     this.$refs.easyPrint.print();
}


  
  },





 async created(){


        try{
       let response = await axios.get("getCrusadeTypes");
        this.getCrusadeTypes =  JSON.parse(JSON.stringify(response.data.data));
       // this.getPortalDenominations =  JSON.parse(JSON.stringify(response.data.data));
        //console.log("meeeeeeee");
       
       
      
    }catch(error){
        console.log(error);
    }

    this.getCrusadeTypes.unshift({"id":"ALL", "crusade_type":"ALL"});


       
      
// this.app_portal = JSON.parse(localStorage.getItem("user_portal_info")).portal;
// this.denomination_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_denomination_id;
//console.log(JSON.parse(localStorage.getItem("user_portal_info")))

    //this.currentPage = 1;
    this.getPortal();
    this.table_current_page(1);
    //this.getCrusadeType();

   
  },


  watch:{


    
  }

};
</script>

<style scoped src="@/assets/css/report.css"></style>