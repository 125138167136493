import { GET_DEPENDENT_SUPPORT_INFO, GET_DEPENDENT_SUPPORT_VIEW_TYPE } from "@/store/storeconstants";

export default{

    
[GET_DEPENDENT_SUPPORT_VIEW_TYPE](state,payload){
    //console.log(payload);
    state.dependent_support_view_type = payload;
},

[GET_DEPENDENT_SUPPORT_INFO](state,payload){
    state.dependent_support_info = payload;
},

}