import { GET_MODAL_USER_PORTAL_ID, GET_MODAL_USER_PORTAL_USER_ID, GET_MODAL_USER_PORTAL_VIEW_TYPE, GET_SEARCH_USER_USER_PORTAL, SET_USER_PORTAL_ID, SUCC_USER_PORTAL_REG_DATA } from "@/store/storeconstants";

export default{
    [SUCC_USER_PORTAL_REG_DATA](state,payload){

        state.user_portal_id = payload.id;

    },

    [GET_SEARCH_USER_USER_PORTAL](state,payload){
        
        state.search_user_portal_id_result = payload;
    
    },

    [GET_MODAL_USER_PORTAL_VIEW_TYPE](state,payload){

        state.modal_user_portal_view_type = payload

    },

   [GET_MODAL_USER_PORTAL_USER_ID](state,payload){

    state.modal_user_portal_user_id = payload

   },

   [GET_MODAL_USER_PORTAL_ID](state,payload){

    state.modal_user_portal_id = payload

   },

   [SET_USER_PORTAL_ID](state,payload){
    state.user_portal_id = payload;
   }
}