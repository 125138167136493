
<template>

 <div class="limiter">
		<div class="container-login100">
      
			<div class="wrap-login100">
        
        <h2 style="margin-top:-140px; margin-left:100px; color:rgb(237,28,36); font-family:Arial, Helvetica, sans-serif; font-weight:bold">ASANTEMAN GREAT GOSPEL CRUSADE</h2>
         
				<Tilt :parallax="true">
					<img src="@/assets/CfaN_Plain_Logo_red.png" style="width:300px; margin-top:60px" alt="IMG">
				</Tilt>

				<form class="login100-form validate-form" @submit.prevent="handleSubmit()">
					<span class="login100-form-title">
						Reset Password
					</span>

    

          <div><label><b>New Password</b></label></div>
					<div class="wrap-input100 validate-input" data-validate = "Password is required">
						<input class="input100"  :class="{ form_error: passwordErr}" name="password" @blur="passwordVal($event.target.value)" v-model.trim="password" type="password"  placeholder="Enter Your Password">
						<span class="focus-input100"></span>
						<span class="symbol-input100">
							<i class="fa fa-lock" aria-hidden="true"></i>
						</span>
              <div
                    :class="{ text_error: passwordErr  }"
                  v-if="passwordErr">
                  {{ passwordErr }} 
                  </div>
					</div>




             <div><label><b>Confirm Password</b></label></div>
					<div class="wrap-input100 validate-input" data-validate = "Confirm Password is required">
						<input class="input100"  :class="{ form_error: password_confirmationErr}" name="password_confirmation" @blur="password_confirmationVal($event.target.value)" v-model.trim="password_confirmation" type="password"  placeholder="Confrim Password">
						<span class="focus-input100"></span>
						<span class="symbol-input100">
							<i class="fa fa-lock" aria-hidden="true"></i>
						</span>
              <div
                    :class="{ text_error: password_confirmationErr  }"
                  v-if="password_confirmationErr">
                  {{ password_confirmationErr }} 
                  </div>
					</div>
					
					<div class="container-login100-form-btn">
						<button type="submit" class="login100-form-btn">
							Submit
						</button>
					</div>

					<div class="text-center p-t-12">
						<span class="txt1">
							Return To
						</span>
           <a href="/"  class="txt2">
                Login
           </a>
					</div>

					<!-- <div class="text-center p-t-136">
						<a class="txt2" href="#">
							Create your Account
							<i class="fa fa-long-arrow-right m-l-5" aria-hidden="true"></i>
						</a>
					</div> -->
				</form>


       
   


			</div>
		</div>
	</div>














	<!-- <div class="unix-login">
		<div class="container-fluid">
			<div class="row justify-content-center">
				<div class="col-lg-4">
					<div class="login-content">
						<div class="login-logo">
							<img
								src="@/assets/eMedipay-logo-small.png"
								alt=""
								style="height: 55px"
							/>
						</div>
						<div class="card login-form">
							<h4>Reset Password</h4>
							<form @submit.prevent="handleSubmit()">
                                <div class="row">
                                <div class="col">
								<div class="form-group">
									<label>New Password</label>
									<input
										type="password"
										class="form-control"
										:class="{ form_error: passwordErr || 'password' in errorServers }"
										v-model.trim.lazy="password"
										placeholder="New Password"
									/>
									<div :class="{ text_error: passwordErr || 'password' in errorServers }" v-if="passwordErr">
										{{ passwordErr }} <span v-if="'password' in errorServers">{{ errorServers.password[0] }}</span>
									</div>
								</div>

                                </div>
                                </div>

                                <div class="row">
                                <div class="col">
                                <div class="form-group">
                                    <label>Confirm Password</label>
                                     <input
                            type="password"
                            class="form-control"
                            :class="{ form_error: password_confirmationErr || 'password_confirmation' in errorServers }"
                            placeholder="Confirm Password"
                            v-model.trim.lazy="password_confirmation"
                          />
                          <div :class="{ text_error: password_confirmationErr || 'password_confirmation' in errorServers }" v-if="password_confirmationErr">
                            {{ password_confirmationErr }} <span v-if="'password_confirmation' in errorServers">{{ errorServers.password_confirmation[0] }}</span>
                          </div>
                                </div>
                                </div>

                                </div>


                           

                                


								<button type="submit" class="btn btn-primary btn-flat m-b-15">
									Submit
								</button>
								<div class="register-link text-center">
									<p v-if="!page">Back to <router-link to="/"> Home</router-link></p>
                  <p v-else>Back to <router-link to="/em_login"> Login Page</router-link></p>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div> -->
</template>

<script>
import axios from 'axios';
import FieldValidateService from '@/services/FieldValidateService';
import notificationBox from '@/services/notificationBox';
import { AUTH_RESET, LOADING } from '@/store/storeconstants';
import { mapActions, mapMutations } from 'vuex';
import Tilt from 'vanilla-tilt-vue';
export default {
	name: "ResetPasswordView",
	components: {Tilt},

   created(){

  this.page = localStorage.getItem("page");

 },

    data(){
        return {

         
            page:"",
            password:"",
            password_confirmation:"",
            passwordErr:"",
            password_confirmationErr:"",
            errorClient:[],
            errorServers:[],
               clear:false,

        }
    },


    methods:{

     ...mapActions({
        resetAct: 'auth/'+AUTH_RESET,
        
    }),

    ...mapMutations({
       showLoading:LOADING,
   }),

        async handleSubmit(){

                  this.errorServers = [];
             this.errorClient = [];

        this.showLoading(true);
        this.passwordVal(this.password);
        this.password_confirmationVal(this.password_confirmation);



        if(this.errorClient.length > 0){
            this.showLoading(false);
          notificationBox.errorNotifications("Some Errors Exist");
            
        }
        else{


                  try{


              const formData = {
                token:this.$route.params.code,
                password:this.password,
                password_confirmation:this.password_confirmation
            };

        let response = await axios.post("reset",formData)
        if(response.status == 200){

           this.showLoading(false);

                this.errorServers = [];
             this.errorClient = [];
            this.clear_all();
            notificationBox.succNotifications("Password Reseted Successfully");
            //this.$router.replace("/");
            window.location.href = '/';
        }
                  }catch(error){

                    this.errorServers.push(error.response.data.errors);
           console.log(error);
           this.showLoading(false);
           notificationBox.errorNotifications(error.response.data.message);

                  }


          

        }

        },


    passwordVal(value){

    //  let max = 19;
      let field = "Password";
     
     if(FieldValidateService.reqValPassword(value,field))
     {
       this.passwordErr = FieldValidateService.reqValPassword(value,field);
        this.errorClient.push(this.passwordErr);
     }else{

           if(this.errorClient.includes(this.passwordErr))
           {
            let pos = this.errorClient.indexOf(this.passwordErr);
           this.errorClient.splice(pos,1);
            this.passwordErr =""; 
           }
          
          

     }

   },


    password_confirmationVal(value){
          
      if(!this.clear  && FieldValidateService.valPasswordConfirm(value, this.password))
     {
       this.password_confirmationErr = FieldValidateService.valPasswordConfirm(value,this.password);
        this.errorClient.push(this.password_confirmationErr);
     }else{

           this.password_confirmationErr =""; 
          this.errorClient.pop(this.password_confirmationErr);

     }


   },



    clear_all(){
      this.clear = true;
      this.password = "";
      this.password_confirmation = "";
      this.clear = false;
        },



        



    },



       watch:{

     password(curValue){
        this.passwordVal(curValue);
     },


     password_confirmation(curValue){
        this.password_confirmationVal(curValue);
     }

  }


};
</script>