<template>
    <div>
        <h2>Registration Successful</h2>
        <p>An Email Has Been Sent To You. Please Click On The Link To Verify Your Account</p>
        <p>Go To  <router-link to="/"> Portal</router-link></p>
    </div>
</template>


<script>
export default {
    name:'RegistrationInfoPage'
}
</script>