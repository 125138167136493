<template>
  <div class="dropdown dib">
      <li class="header-icon" data-bs-toggle="dropdown" aria-expanded="false">
          <span class="user-avatar">{{ first_name }}
              <i class="ti-angle-down f-s-10"></i>
          </span>
      </li>
      <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
          <ul class="p-0 mb-0">
              <li>
                  <router-link to="/">
                    <div class="p-2 user-drop-item">
                      <i class="fa-regular fa-user"></i>
                       {{ fname }} 
                    </div> 
                  </router-link>
              </li>
              <!-- <li>
                  <router-link to="/">
                    <div class="p-2 user-drop-item">
                      <i class="fa-solid fa-screwdriver-wrench"></i>
                       Settings
                    </div> 
                  </router-link>
              </li> -->
              <li>
                  <a href="#" @click="handleSignOut" v-if="is_arr">
                    <div class="p-2 user-drop-item">
                      <i class="fa-solid fa-sign-out"></i>
                       Signout
                    </div> 
                  </a>
              </li>
              <li>
                  <a href="#" @click="handleLogOut">
                    <div class="p-2 user-drop-item">
                      <i class="fa-solid fa-key"></i>
                       Logout
                    </div> 
                  </a>
              </li>
          </ul>
      </div>
  </div>
</template>

<script>
 import axios from 'axios';
 import notificationBox from "@/services/notificationBox";
export default {
  name: "UserDropDownComp",
  props: ['first_name'],
  async created(){

        let user_infoo = [];
       user_infoo = JSON.parse(localStorage.getItem("user_portal_info"));
        //console.log(user_infoo);
    if(Array.isArray(user_infoo)) {
     this.is_arr = true;
     //console.log(user_infoo[0].fullname);
     this.user_name = user_infoo[0].fullname;
     this.fname = this.user_name.split(" ")[0];

} 
else{
   this.is_arr = false;
     //console.log(user_infoo.fullname);
     this.user_name = user_infoo.fullname;
     this.fname = this.user_name.split(" ")[0];
     //console.log(this.fname);
}

  },
  data(){
     return {
        is_arr:true,
        user_name:"",
        fname:""
     }
  },
  methods: {
    async handleLogOut(){

      try{

        let response = await axios.post("logout");
        if(response.status == 200)
        { 

        localStorage.clear();
         this.$router.push("/")

        }

      }catch(error){

        console.log(error)

     notificationBox.errorNotifications("Could Not Logout");

      }

  

  
    },

    handleSignOut(){

        this.$router.push("/main-menu")

    }
  },
};
</script>

<style scoped>
.dropdown-menu{
    width: 150px;
    padding: 0;
}

.dropdown-menu li:last-child{
    border-bottom: 0;
}

.user-drop-item{
  color: #444;
}

.user-drop-item:hover{
  color: #6b51df;
}
</style>