<template>
  <div class="unix-login">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-lg-9">
          <div class="login-content">
            <div class="login-logo">
              <img
                src="@/assets/eMedipay-logo-small.png"
                alt=""
                style="height: 55px"
              />
            </div>
            <div class="card login-form">
              <div class="card shadow-sm" id="stylized">
                 <center><b>Page Not Found</b></center>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "RegisterT",

    components: {
      
    
  },

   mounted(){
   
 }


   // console.log(input2);
    
  }

  

</script>
