<template>
    <div class="sidebar sidebar-hide-to-small sidebar-shrink sidebar-gestures">
        <div class="nano">
            <div class="nano-content">
                <div class="logo shadow-lg" style="max-height: 72px;">
                    <router-link to="/dashboard">
                        <img src="@/assets/pent_sidebar.png" style="height: 150px;"/>
                        <span class="font-white"></span>
                    </router-link>
                </div>
                <ul class="nav nav-pills flex-column mb-auto mt-3">
                    <li class="label">Main</li>
                    <li class="nav-item"  v-if="user_portal_info.user_level=='SUPER ADMIN' || user_portal_info.user_level=='AREA ADMIN' || user_portal_info.user_level=='DISTRICT ADMIN'">
                        <router-link to="/dashboard" class="nav-link">
                            <i class="ti-home"></i> Dashboard
                            <!--<span class="badge badge-primary">2</span>-->
                        </router-link>
                    </li>

                    <li class="label">App</li>

                    <li class="nav-item" v-if="user_portal_info.user_level=='SUPER ADMIN' || user_portal_info.user_level=='AREA ADMIN' || user_portal_info.user_level=='DISTRICT ADMIN'"> 
                        <router-link to="/area" class="nav-link" data-bs-toggle="collapse" data-bs-target="#area-collapse" aria-expanded="false" v-if="this.$route.name == 'Area'">
                        <i class="fa-solid fa-gear me-1"></i>
                        Setups
                        <span class="sidebar-collapse-icon ti-angle-down"></span>
                        </router-link>

                        <router-link to="/district" class="nav-link" data-bs-toggle="collapse" data-bs-target="#area-collapse" aria-expanded="false" v-else-if="this.$route.name == 'District'">
                        <i class="fa-solid fa-gear me-1"></i>
                        Setups
                        <span class="sidebar-collapse-icon ti-angle-down"></span>
                        </router-link>

                        <router-link to="/crusade_type" class="nav-link" data-bs-toggle="collapse" data-bs-target="#area-collapse" aria-expanded="false" v-else>
                        <i class="fa-solid fa-gear me-1"></i>
                        Setups
                        <span class="sidebar-collapse-icon ti-angle-down"></span>
                        </router-link>
                  

                        <div class="collapse" id="area-collapse" style="">
                        <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                         <li class="nav-item" v-if="user_portal_info.user_level=='SUPER ADMIN' || user_portal_info.user_level=='AREA ADMIN'"> 
                            <router-link to="/area" class="nav-link ps-5">
                                Area
                            </router-link>
                            </li>
                         <li class="nav-item" v-if="user_portal_info.user_level=='SUPER ADMIN' || user_portal_info.user_level=='AREA ADMIN' || user_portal_info.user_level=='DISTRICT ADMIN'">
                            <router-link to="/district" class="nav-link ps-5">
                                Districts
                            </router-link>
                            </li>

                             <li class="nav-item" v-if="user_portal_info.user_level=='SUPER ADMIN'">
                            <router-link to="/crusade_type" class="nav-link ps-5">
                                Crusade Type
                            </router-link>
                            </li>

                        </ul>
                        </div>
                    </li>









                 










                    <li class="nav-item" v-if="user_portal_info.user_level != 'FIRECONFERENCE'"> 
                        <router-link to="/convert" class="nav-link" data-bs-toggle="collapse" data-bs-target="#convert-collapse" aria-expanded="false">
                        <i class="fa-solid fa-users me-1"></i>
                        Converts
                        <span class="sidebar-collapse-icon ti-angle-down"></span>
                        </router-link>

                        <div class="collapse" id="convert-collapse" style="">
                        <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                         <li class="nav-item"> 
                            <router-link to="/convert" class="nav-link ps-5">
                                Converts
                            </router-link>
                            </li>
                       
                        </ul>
                        </div>
                    </li>






                       <!-- <li class="nav-item" v-if="user_portal_info.user_level=='SUPER ADMIN' || user_portal_info.user_level=='SECRETARY' || user_portal_info.user_level=='AREA ADMIN' || user_portal_info.user_level=='AREA SECRETARY' || user_portal_info.user_level=='CHURCH ADMIN' || user_portal_info.user_level=='CHURCH SECRETARY'"> 
                        <router-link to="/sms_setup" class="nav-link" data-bs-toggle="collapse" data-bs-target="#sms_setup-collapse" aria-expanded="false"  v-if="this.$route.name == 'sms_setup'">
                        <i class="fa-solid fa-sms me-1"></i>
                        Sms Setup
                        <span class="sidebar-collapse-icon ti-angle-down"></span>
                        </router-link>


                        <router-link to="/send_bulk_sms" class="nav-link" data-bs-toggle="collapse" data-bs-target="#sms_setup-collapse" aria-expanded="false" v-if="this.$route.name == 'send_bulk_sms'">
                        <i class="fa-solid fa-sms me-1"></i>
                        Sms Setup
                        <span class="sidebar-collapse-icon ti-angle-down"></span>
                        </router-link>



                        <div class="collapse" id="sms_setup-collapse" style="">
                        <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                         <li class="nav-item" v-if="user_portal_info.user_level=='SUPER ADMIN' || user_portal_info.user_level=='DENOMINATION ADMIN' || user_portal_info.user_level=='CHURCH ADMIN'"> 
                            <router-link to="/sms_setup" class="nav-link ps-5">
                                Sms Setup
                            </router-link>
                            </li> 


                        
                        
                        <li class="nav-item" v-if="user_portal_info.user_level=='SUPER ADMIN' || user_portal_info.user_level=='SECRETARY' || user_portal_info.user_level=='DENOMINATION ADMIN' || user_portal_info.user_level=='DENOMINATION SECRETARY' || user_portal_info.user_level=='CHURCH ADMIN' || user_portal_info.user_level=='CHURCH SECRETARY'"> 
                        <router-link to="/send_bulk_sms" class="nav-link ps-5">
                        Send Bulk SMS
                        </router-link>
                        </li>


                       
                        </ul>
                        </div>
                    </li>-->








                         <li class="nav-item" v-if="user_portal_info.user_level=='SUPER ADMIN' || user_portal_info.user_level=='SECRETARY' || user_portal_info.user_level=='AREA ADMIN' || user_portal_info.user_level=='AREA SECRETARY' || user_portal_info.user_level=='DISTRICT ADMIN' || user_portal_info.user_level=='DISTRICT SECRETARY'"> 
                        <router-link to="/convert_report" class="nav-link" data-bs-toggle="collapse" data-bs-target="#report-collapse" aria-expanded="false" v-if="this.$route.name == 'convert_report'">
                        <i class="fa-solid fa-file me-1"></i>
                        Reports
                        <span class="sidebar-collapse-icon ti-angle-down"></span>
                        </router-link>


                       

                            <router-link to="/fire_conference_per_church" class="nav-link" data-bs-toggle="collapse" data-bs-target="#report-collapse" aria-expanded="false" v-else-if="this.$route.name == 'fire_conference_per_church'">
                        <i class="fa-solid fa-file me-1"></i>
                        Reports
                        <span class="sidebar-collapse-icon ti-angle-down"></span>
                        </router-link>



                            <router-link to="/fire_conference_report" class="nav-link" data-bs-toggle="collapse" data-bs-target="#report-collapse" aria-expanded="false" v-else-if="this.$route.name == 'fire_conference_report'">
                        <i class="fa-solid fa-file me-1"></i>
                        Reports
                        <span class="sidebar-collapse-icon ti-angle-down"></span>
                        </router-link>


                              <router-link to="/convert_per_church" class="nav-link" data-bs-toggle="collapse" data-bs-target="#report-collapse" aria-expanded="false" v-else>
                        <i class="fa-solid fa-file me-1"></i>
                        Reports
                        <span class="sidebar-collapse-icon ti-angle-down"></span>
                        </router-link>

                  

                        <div class="collapse" id="report-collapse" style="">
                        <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                         <li class="nav-item" v-if="user_portal_info.user_level=='SUPER ADMIN' || user_portal_info.user_level=='SECRETARY' || user_portal_info.user_level=='AREA ADMIN' || user_portal_info.user_level=='AREA SECRETARY' || user_portal_info.user_level=='DISTRICT ADMIN' || user_portal_info.user_level=='DISTRICT SECRETARY'"> 
                            <router-link to="/convert_report" class="nav-link ps-5">
                                Convert Report
                            </router-link>
                            </li>

                               <li class="nav-item" v-if="user_portal_info.user_level=='SUPER ADMIN' || user_portal_info.user_level=='SECRETARY' || user_portal_info.user_level=='AREA ADMIN' || user_portal_info.user_level=='AREA SECRETARY'">
                            <router-link to="/convert_per_church" class="nav-link ps-5">
                                Convert Per Church
                            </router-link>
                            </li>

                            


                             <!-- <li class="nav-item" v-if="user_portal_info.user_level=='SUPER ADMIN' || user_portal_info.user_level=='FIRECONFERENCE'">
                            <router-link to="/fire_conference_per_church" class="nav-link ps-5">
                                Fire Conference Per Church
                            </router-link>
                            </li>

                         <li class="nav-item" v-if="user_portal_info.user_level=='SUPER ADMIN' || user_portal_info.user_level=='FIRECONFERENCE'">
                            <router-link to="/fire_conference_report" class="nav-link ps-5">
                                Fire Conference Report
                            </router-link>
                            </li> -->

                        

                        </ul>
                        </div>
                    </li> 







         <li class="nav-item" v-if="user_portal_info.user_level=='SUPER ADMIN' || user_portal_info.user_level=='AREA ADMIN' || user_portal_info.user_level=='DISTRICT ADMIN'"> 
                        <router-link to="/user" class="nav-link" data-bs-toggle="collapse" data-bs-target="#user-collapse" aria-expanded="false">
                        <i class="fa-solid fa-user me-1"></i>
                        Users
                        <span class="sidebar-collapse-icon ti-angle-down"></span>
                        </router-link>

                        <div class="collapse" id="user-collapse" style="">
                        <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                         <li class="nav-item" v-if="user_portal_info.user_level=='SUPER ADMIN' || user_portal_info.user_level=='AREA ADMIN' || user_portal_info.user_level=='DISTRICT ADMIN'"> 
                            <router-link to="/user" class="nav-link ps-5">
                                Users
                            </router-link>
                            </li>
                       
                        </ul>
                        </div>
                    </li>









                    <!-- <li class="nav-item">
                        <router-link to="/form" class="nav-link">
                        <i class="fas fa-chart-column me-1"></i>
                        Reports
                        </router-link>
                    </li> -->
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "SideBarComp",
  created(){
    // const user_data = JSON.parse(localStorage.getItem("users"));
    // this.user_level = user_data.user_level;
   // console.log(this.$route.name);
   this.user_portal_info = JSON.parse(localStorage.getItem("user_portal_info"));

   //console.log(this.user_portal_info);

           let user_infoo = [];
       user_infoo = JSON.parse(localStorage.getItem("user_portal_info"));
      
        //console.log(user_infoo);
    if(Array.isArray(user_infoo)) {

 this.user_portal_info = user_infoo[0];

} 
else{
   
   this.user_portal_info = user_infoo;
}

   
 


  },

 

  data(){
    return {
        user_portal_info:"",
        user_level:"",
        link_url:"",
      //  link_report_url:"",
    }
  },


  

    methods: {
     link_urll() {
      
         if(this.$route.name == 'Denomination'){
        this.link_url = '/denomination';
    }
    else if(this.$route.name == 'Church'){
       this.link_url = '/church';
    }
    else if(this.$route.name == 'Crusade_Type'){
       this.link_url = '/crusade_type';
    }





    },


    // link_report_urll(){

    //       if(this.$route.name == 'convert_report'){
    //     this.link_report_url = '/convert_report';
    // }
    // else if(this.$route.name == 'Church'){
    //    this.link_url = '/church';
    // }

    // }
  }

};
</script>

<style scoped>
.nav-link{
  color: #bdbdc7;
  border-radius: 0 !important;
  padding: 1em 1.5em;
}

.nav-link.router-link-active, .nav-link:hover{  
    background-color: #6b51df !important;
    color: #fff;
}
</style>