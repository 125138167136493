import actions from "./actions";
import mutations from "./mutations";
export default {
    namespaced:true,
    state(){
        return{
            currentPage:1,
            total:100,
            perpage:20,
            getsearch:"",
          //  status:"",
            getstatus:"",
            checked:[],
            selectPage:false,
            selectAll:false,
            sort_direction:'desc',
            sort_field:'',
            url:''
        }
    },

    mutations,
    actions,
}