import { createStore } from "vuex";
import dataTable from './modules/dataTable/index';
import auth from './modules/auth/index';
import user from './modules/user/index';
import support from './modules/support/index';
import dependent from './modules/dependent/index';
import provider from './modules/provider/index';
import user_portal from './modules/user_portal/index';
import employee_country from './modules/employee_country/index';
import employee_provider from './modules/employee_provider/index';
import dependent_support from './modules/dependent_support/index';
import validations from './modules/validations/index';
import { LOADING } from "./storeconstants";

const store = createStore({
  modules:{
    dataTable,
    auth,
    validations,
    user,
    support,
    dependent,
    provider,
    user_portal,
    employee_country,
    employee_provider,
    dependent_support,
  },
  state(){
    return{
       isLoading:false,
    }
  },
  mutations:{
    [LOADING](state,payload){
      state.isLoading = payload;
    }
  }

})

export default store;

// import { createStore } from "vuex";

// export default createStore({
//   state: {},
//   getters: {},
//   mutations: {},
//   actions: {},
//   modules: {},
// });
