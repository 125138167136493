import { GET_PROVIDER_EXIST_MESS, GET_PROVIDER_ID, GET_PROVIDER_INFO, GET_PROVIDER_VIEW_TYPE, SET_PROVIDER_MESSAGE, SUCC_PROVIDER_REG_DATA } from "@/store/storeconstants";

export default{
    [SUCC_PROVIDER_REG_DATA](state,payload){

        state.provider_idd = payload.id;

    },

    [GET_PROVIDER_ID](state,payload){

        state.provider_idd = payload;

    },

    [GET_PROVIDER_EXIST_MESS](state,payload){
        state.provider_exist_success_mess = payload;
    },

    [SET_PROVIDER_MESSAGE](state,payload){
        state.provider_message = payload;
    },

    [GET_PROVIDER_INFO](state,payload){
        state.provider_info = payload;
    },

    [GET_PROVIDER_VIEW_TYPE](state,payload){
        state.provider_view_type = payload;
    }
}