<template>

        <!-- Modal -->
        <div class="modal fade"  :class="{ show: false, 'd-block': false }"  id="supportDependentDetailModule"    aria-labelledby="supportDependentDetailModuleLabel" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg" style="max-height:30%; max-width: 80%;" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="supportDependentDetailModuleLabel">View Dependent Detail</h5>
                <button type="button" ref="supportDependentDetailModule" class="btn-close" :data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#supportDependentDetailModule" aria-label="Close"></button>
            </div>



                      <div class="modal-body">
                        <div class="table-responsive table-responsive-data2">
                        <table class="table table-hover table-striped">
                          
                              <thead>
                                <tr>
                                    <th>Fullname</th>
                                    <th>Email</th>
                                    <th>Contact</th>
                                    <th>Country</th>
                                </tr>
                                </thead>


                                <tbody>

                                <tr v-for="data in dependent_data" :key="data.id">
                                <td>{{ data.fullname }}</td>
                                <td>{{ data.email }}</td>
                                <td>{{ data.contact }}</td>
                                <td>{{ data.country }}</td>
                                </tr>

                             
                              </tbody>
                                  </table>







                               

                    
                        </div>

                      </div>





            </div>
        </div>


           



        
        </div>
</template>

<script>
 import axios from 'axios';
// import FieldValidateService from "@/services/FieldValidateService";
//import vSelect from 'vue-select';
// import notificationBox from "@/services/notificationBox";
// import intlTelInput from 'intl-tel-input';
// import SpinnerComp from "@/components/SpinnerComp.vue";
import { mapMutations } from 'vuex';
import { LOADING } from '@/store/storeconstants';


    export default {

        name:'ViewSupportDependentDetail',
        props:{

          getSupportData:{
            required:true,
          },


          getSupportDependentData:{
            required:true
          },


        },


        components:{
       //   SpinnerComp,
         // vSelect,
        },


           mounted(){



           },

        async created() {
        //  this.showLoading(true);


      //    this.formData.fname = this.firstName;
      
         
    // this.portal =  localStorage.getItem('Portall')
    this.app_portal = localStorage.getItem("Portall");
    this.formData.portal = localStorage.getItem("Portall");

      const user_data = JSON.parse(localStorage.getItem("users"));
          this.formData.provider_id = user_data.provider_id;  
        //  console.log(this.formData.provider_id);
 //this.view_data();

    switch(this.app_portal) {
 case 'Dependent':
    this.formData.user_level = "DEPENDENT";
    break;
  case 'Support':
    this.formData.user_level = "SUPPORT";
    break;
  case 'Telemedicine':
    this.formData.user_level = "TELEMEDICINE";
    break;
  case 'Provider':
    this.formData.user_level = "ADMIN";
    break;
  default:
    this.formData.user_level = "";
}



   try{
       let response = await axios.get("getCountries");
     // console.log(response.data.data);
     // response.data.data.unshift({id:"",country:"Please Select Option", created_at:null,updated_at:null})   
        this.getCountries =  JSON.parse(JSON.stringify(response.data.data));
        //console.log(this.getCountries);
       
       
       
      
    }catch(error){
        console.log(error);
    }





   try{
       let response = await axios.get("getProviders");
      // console.log(response.data.data);
         
        this.getProviders =  JSON.parse(JSON.stringify(response.data.data));
        //console.log(this.getCountries);
       
       
       
      
    }catch(error){
        console.log(error);
    }


  // if(this.app_portal === 'Provider')
  // {
  //       try{
  //      let response = await axios.get("getHealthCares");
  //     // console.log(response.data.data);
         
  //       this.getHealthCares =  JSON.parse(JSON.stringify(response.data.data));
  //       //console.log(this.getCountries);
       
       
       
      
  //   }catch(error){
  //       console.log(error);
  //   }
  // }


  

 
  
 

        },




         data: function () {
    return {
      //userModal: null,
      link_val:"",
      is_existing_user: false,
      is_existing:"NO",
      app_portal: "",
      search: "",
      searchErr: "",
      loading:false,
      getCountries:[],
      getProviders:[],
      errorMess: "",
      succMess:"",
      SearcherrorServers:"",
      clear_user: true,
      clear_all: true,
      errorClient:[],
      errorServers:[],
      dependent_data:[],
       


      modal:"modal",


   
      

      formData: {
        link:[],   //This Helps Link A Gerdd Employee To Either A Country Or A Provider  Applys Here
        user_level:"",
        portal:"",
        is_logged_in:"NO",
        otp_code:"",
        user_idy:"",
        user_portal_idy:"",
        action_type:"REG",

        provider_id:"",
        reg_otp:"",
        fullname: "",
        gender: "",
        country_id: null,
        gps_address: "",
        city:  "",
        status:"",
        contact: "",
        email:  "",
        password: "",
        password_confirmation: "",
        country_name:"",
        portal_id: "",


        view_portal:"",
        
        
      },



    //Errors  
      fullnameErr: "",
      genderErr: "",
      countryErr: "",
      cityErr: "",
      contactErr: "",
      emailErr: "",
      otpErr:"",
      passwordErr: "",
      password_confirmationErr: "",
      user_levelErr:"",
      linkErr:"",








    };
    },


      methods: {

           ...mapMutations({
       showLoading:LOADING,
   }),


     



      },


    //    computed:{

    //     changes : {
    //         get : function(){
    //             return this.count_down;
    //         },
    //         set : function(vem){
             
    //             this.count_down =  vem;
    //              if(this.count_down <= 0){
    //             this.count_down = "";
    //              clearInterval(this.count_down);
    //           }
    //         }
    //     }
    // },

 


      watch:{
           view_type(value){

           if(value == "add")
           {
             this.clearFormData();
           }

          

        },




        getSupportDependentData(value){
           this.dependent_data = value;

  
        },

     


      }
        
    }
</script>

<style scoped>


</style>