import {  TABLE_CURRENT_PAGE, TABLE_PER_PAGE, TABLE_SEARCH, TABLE_SEL_ALL, TABLE_SORT_FIELD_DIRECTION, TABLE_STATUS, TABLE_TOTAL, TABLE_URL } from "@/store/storeconstants";
//import axios from 'axios';

export default{
    [TABLE_SEL_ALL](state,payload){

       // state.dependent_id = payload.id;
        state.selectAll = payload;


    },

    // [TABLE_CHECKED](state,payload){
    //     state.checked = payload;
    // },


    // [TABLE_CHECKED_PUSH](state,payload){

    //     state.checked.push(payload);

    // },


    [TABLE_SORT_FIELD_DIRECTION](state,payload){
        if(state.sort_field == payload){
            state.sort_direction =   state.sort_direction == "asc" ? "desc" : "asc"
           }else{
               state.sort_field = payload;
           }
    },



    [TABLE_CURRENT_PAGE](state,payload){
        state.currentPage = payload
    },

    [TABLE_URL](state,payload){
        state.url = payload
    },


    [TABLE_PER_PAGE](state,payload){
        state.perpage = payload;
    },


    [TABLE_TOTAL](state,payload){
        state.total = payload;
    },

    [TABLE_SEARCH](state,payload){
        state.getsearch = payload;
    },

    [TABLE_STATUS](state,payload){
        state.getstatus = payload;
    }
}