import actions from "./actions";
import mutations from "./mutations";
export default {
    namespaced:true,
    state(){
        return{
            user_idd:"",
            search_user_result:{},
            search_user_id_result:{}, //This Is The Result Of Search User By Id
            search_message:""
        }
    },

    mutations,
    actions,
}