<template>
    <div>
        <SideBarComp/>
        <NavBarComp/>
        <div class="content-wrap">
        <div class="main">
            <div class="section__content section__content--p30">
                <div class="container-fluid">  
                    <HeaderComp page="Transactions" pageDesc="transaction"/>                
                    <section id="main-content">
                        <div class="row ps-3 pe-3">
                            <div class="col-md-7">


                                       <a class="btn btn-outline-success" style="display:none" data-bs-dismiss="modal" ref="getCloseDependentSupportModal" data-bs-toggle="modal" href="#dependentSupportModal" >
                                            <i class="fas fa-plus"></i> Link User To Country Portal
                                        </a>  




                                                                <div class="col-md-12">
                                        <div class="row">

<div class="card shadow-sm" id="stylized">
                                    <form class="register-form" @submit.prevent="reg_cart()">
                                    <div class="row">
       



                       <div class="col">


                      <div class="form-group">
                          <label>Search Dependent By Contact/ Email
                            <span><a title="Add Dependent" @click="dependentSupportAddModal()" data-bs-toggle="modal"  href="#dependentSupportModal"><i class="mr-1 fas fa-plus-square" style="color:black;" aria-hidden="true"></i></a></span>
                             <span style="margin-left:10px" v-if="formData.dependent_id"><a href="" title="Edit Dependent" data-toggle="modal"><i class="mr-1 fas fa-pen-square" style="color:black;" aria-hidden="true"></i></a></span>
                          
                          </label>

                             <input
                            type="text"
                            class="form-control"
                            :class="{ form_error: searchDependentErr}"
                            style="min-width:100%"
                            placeholder="Search Support"
                            v-model.trim.lazy="searchDependent"
                             @blur="searchDependentVal($event.target.value)" 
                          />
                          <div
                            :class="{ text_error: searchDependentErr  }"
                           v-if="searchDependentErr">
                          {{ searchDependentErr }} 
                          </div>

                           <div v-if="dependentLoading && !formData.dependent_id">
                            <SpinnerComp />
                            <div class="center">Checking...</div>
                          </div>
                          <span v-if="search_dependent_fail && searchDependent != ''" class="text_error : search_dependent_fail">{{ search_dependent_fail }}</span>

                         </div>



                        
                      </div>







                       <div class="col">
                      
                      


                        <div class="form-group">
                          <label>Dependent Info</label>

                          <input
                            type="text"
                            class="form-control"
                            :class="{ form_error: dependentErr}"
                            placeholder="Full Name"
                            v-model.trim="dependent"
                            readonly
                            style="width:100%"
                          />
                          <div
                            :class="{ text_error: dependentErr  }"
                           v-if="dependentErr">
                          {{ dependentErr }} 
                          </div>
                        </div>
                      </div>








               


                    </div>





                                                        <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <label>Description</label>

                          <input
                            type="text"
                            class="form-control"
                            :class="{ form_error: descriptionErr}"
                            placeholder="Description"
                             v-model.trim="formData.description"
                             @blur="descriptionVal($event.target.value)"
                               style="width:100%"
                         
                          />

                           <div
                            :class="{ text_error: descriptionErr}"
                            v-if="descriptionErr"
                          >
                            {{ descriptionErr }}
                          </div>
                    
                         
                        </div>
                      </div>




                       <div class="col">
                        <div class="form-group">
                          <label>(Unit) Cost</label>

                          <input
                            type="text"
                            class="form-control"
                            :class="{ form_error: unit_costErr}"
                             v-model.trim="formData.unit_cost"
                             @blur="unit_costVal($event.target.value)"
                            placeholder="Unit Cost"
                               style="width:100%"
                          />

                          <div
                            :class="{ text_error: unit_costErr}"
                            v-if="unit_costErr"
                          >
                            {{ unit_costErr }}
                          </div>
                    
                         
                        </div>
                      </div>



              


                    


               



                    </div>

                    <div class="row">
                        <div class="col">
                <button type="submit" class="btn btn-primary" style="margin-right:10px" v-if="view_type != 'edit'">Add To Cart</button>
                <button type="submit" class="btn btn-primary" style="margin-right:10px" v-else>Edit Cart</button>
                <button type="button" class="btn btn-danger" @click="remove_all">Clear All</button>
                        </div>
                    </div>




                                             </form>
</div>
                                        </div>
                                    </div>

                            
                            <div class="card shadow-sm">
                                <div class="card-body">




                                                                <div class="col-md-12">                               
                                <div class="row">

                                            <!-- <div class="col-1 text-end">
                                        <div class="d-flex align-items-center ml-4" style="min-width: 100px; float:right;">
                                            <label for="paginate" class="text-nowrap mr-2 mb-0"><strong>Per Page</strong></label>
                                            <select v-model.number.trim="perPage" class="form-control form-control-sm">
                                                <option value="20">20</option>
                                                <option value="2">2</option>
                                                <option value="40">40</option>
                                                <option value="60">60</option>
                                                <option value="80">80</option>
                                                <option value="100">100</option>
                                                <option value="120">120</option>
                                                <option value="140">140</option>
                                             </select>
                                       
                                        </div>
                                    </div> -->

                          

                                        <!-- <div class="col-3 text-end">
                                         <div class="d-flex align-items-center ml-4">
                                            <label for="paginate" class="text-nowrap mr-2 mb-0"><strong>FilterBy Status</strong></label>
                                            <select v-model="status" class="form-control form-control-sm">
                                                <option value="">All Status</option>
                                                <option value="PENDING">PENDING</option>
                                                <option value="APPROVED">APPROVED</option>
                                                <option value="REJECTED">REJECTED</option>
                                                <option value="BAN">BAN</option>
                                            </select>

                                         </div>
                                    </div> -->
                                



                                    <!-- <div class="col-4 text-end">
                                        <div style="min-width: 400px; float:right;">
                                                <div class="input-group mb-3">
                                                <input type="text" class="form-control" v-model="search" placeholder="Search" name="SearchTerm">
                                                </div>
                                        </div>
                                    </div> -->

                                    <TableSearch @searchValue="searchval" v-if="transactionProcess.length" />


                                  
                                     <!-- <div class="col-2 text-end">
                          
                                        <div class="dropdown ml-4">
                                            <button v-if="checked.length > 0" class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown">
                                                With checked ({{ checked.length }})
                                            </button>
                                            <div class="dropdown-menu">
                                                <a  onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" href="#" class="dropdown-item" type="button" @click.prevent="deleteRecords">
                                                    Delete
                                                </a>
                                                   <a :href="url"  class="dropdown-item" type="button">
                                                    Export
                                                </a>
                                            </div>
                                        </div>
                                    </div> -->


                                    <ActionButton :num_checked="checked.length" portall=""/>









                                
                                    <div class="col-2 text-end">
                                        <!-- <a class="btn btn-outline-success" @click="userAdd()" data-bs-toggle="modal"  href="#userModal">
                                            <i class="fas fa-plus"></i> Add User
                                        </a>    -->

                                         <!-- <a class="btn btn-outline-success" style="display:none" ref="userEditEmployeeCountryModal" data-bs-toggle="modal" href="#userEmployeeCountryModal" >
                                            <i class="fas fa-plus"></i> Link User To Country Portal
                                        </a>    

                                          <a class="btn btn-outline-success" style="display:none" ref="userEditEmployeeProviderModal" data-bs-toggle="modal" href="#userEmployeeProviderModal" >
                                            <i class="fas fa-plus"></i> Link User To Provider Portal
                                        </a>  -->
                                    </div>


                                             <!-- <div class="col-4 text-start" v-if="selectPage">

                                            <div v-if="selectAll">
                                                You are currently selecting all
                                                <strong>{{ checked.length }}</strong> items.
                                            </div>

                                            <div v-else>
                                                You have selected <strong>{{ checked.length }}</strong> items, Do you want to
                                                Select All <strong>{{total}}</strong>?
                                                 <a @click.prevent="selectAllRecords" href="#" class="m1-2">Select All</a>
                                            </div>
                                       
                                    </div> -->


                                    <SelectAllComponent @selRecords="selectAllRecords" :num_checked="checked.length" v-if="selectPage" />


                                </div>
                            </div>
                                    <div class="table-responsive table-responsive-data2">
                                        <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                                <!-- <th scope="col">#</th> -->
                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('transaction_processes.description')">Description</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='transaction_processes.description'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='transaction_processes.description'">&darr;</span>
                                                </th>

                                              <th scope="col">
                                                <a href="#" @click.prevent="change_sort('transaction_processes.qty')">Quantity</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='transaction_processes.qty'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='transaction_processes.qty'">&darr;</span>
                                                </th>

                                              <th scope="col">
                                                <a href="#" @click.prevent="change_sort('transaction_processes.unit_cost')">Unit Cost</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='transaction_processes.unit_cost'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='transaction_processes.unit_cost'">&darr;</span>
                                                </th>

                                     

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('transaction_processes.subtotal')">Subtotal</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='transaction_processes.subtotal'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='transaction_processes.subtotal'">&darr;</span>
                                                </th>

                                                <!-- <th scope="col">
                                                <a href="#" @click.prevent="change_sort('health_cares.health_care')">Health Care</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='health_cares.health_care'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='health_cares.health_care'">&darr;</span>
                                                </th>

                                             

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('users.address')">Status</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='providers.status'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='providers.status'">&darr;</span>
                                                </th>

                                        

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('users.fullname')">Health Professional</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.fullname'">&darr;</span>
                                                </th>
 -->
                                                <!-- <th scope="col">
                                                Action
                                                </th> -->

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(itemm) in transactionProcess" :key="itemm.id" :class="isChecked(itemm.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="itemm.id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{itemm.description}}</td>
                                                    <td>

                                                              <input
                                                            type="number"
                                                            class="form-control"
                                                            min="1"
                                                                v-model.trim="itemm.qty"
                                                                @blur="qtyVal($event.target.value,itemm.id,itemm.unit_cost)"
                                                                @click="qtyVal($event.target.value,itemm.id,itemm.unit_cost)"
                                                            placeholder="Unit Cost"
                                                                style="width:100px"
                                                            />

                                                    </td>
                                                    <td>{{ itemm.unit_cost }}</td>
                                                    <td>{{  itemm.subtotal }}</td>
                                                    <td>
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                                            <li class="d-flex">                                                    
                                                                <!-- <div class="btn"  title="edit" @click="providerApprove(user_portal.id, user_portal.user_id)" data-bs-toggle="modal" data-bs-target="#userModal">
                                                                    <i class="fa-solid fa-check"></i>
                                                                </div>                                                   -->
                                                                <div class="btn" title="edit" @click="transactionProcessEdit(itemm.id)">
                                                                <i class="fa-solid fa-pencil"></i>
                                                            </div>                                                  
                                                            <div onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" class="btn" title="delete" @click="deleteSingleRecord(itemm.id)">
                                                                <i class="fa-solid fa-trash"></i>
                                                            </div>  
                                                            </li>
                                                        </div>
                                                    </td>

     

                                                    <!-- <td v-else-if="">

                                                    </td> -->
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>Subtotal</td>
                                                    <td>{{cartSubtotal}}</td>
                                                    <td></td>
                                                    
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="row mt-4">
                                             <VueTailwindPagination
                                            :current="currentPage"
                                            :total="total"
                                            :per-page="perpage"
                                            @page-changed="pageChange($event)"
                                            />
                                    </div>
                                </div>
                            </div>




                      


                       </div>



                    <div class="col-md-4" style="margin-left: 40px;">






                                   <div class="col-md-12">
                                        <div class="row">

<div class="card shadow-sm" id="stylized">
                                    <form class="register-form" @submit.prevent="reg_final_trans()">


                                                         <div class="card-body">
                      <h1>Complete Transaction</h1><br/>
                      <p></p>



                                    <div class="row">


                                         <div class="col">
                        <div class="form-group">
                          <label><span class="text_error">*</span>Support</label>
   

                         <v-select :options="getSupports"  class="select1" v-model="formData.support_id" multiple  :reduce="support => support.support_id" label="support" @option:selected="supportVal" />

                       
                          <div
                            :class="{ text_error: supportErr }"
                            v-if="supportErr"
                          >
                            {{ supportErr }}
                          </div>
                        </div>
                      </div>


                                    </div>





                                                                        <div class="row">


                                         <div class="col">
                         <div class="form-group">
                          <label>Total Cost</label>

                          <input
                            type="text"
                            class="form-control"
                            placeholder="Total Cost"
                            v-model="total_cost"
                             @blur="total_costVal($event.target.value)"
                               style="width:100%"
                          />

                             <div
                            :class="{ text_error: total_costErr}"
                            v-if="total_costErr"
                          >
                            {{ total_costErr }}
                          </div>
                    
                         
                        </div>
                      </div>



                     


                                    </div>



                                    <div class="row">
                        <div class="col">
                <button type="submit" class="btn btn-primary" style="margin-right:10px">Transact</button>
                <button type="button" class="btn btn-danger" @click="remove_all">Clear All</button>
                        </div>
                    </div>
                    </div>

                                    </form>

</div>
                                        </div>
                         </div>






                         <div class="col-md-12">
                                        <div class="row">

<div class="card shadow-sm" id="stylized">
                                    <form class="register-form" @submit.prevent="convert()">

                                          <div class="card-body">
                      <h1>Currency Converter</h1><br/>
                      <p></p>
        

                                 <div class="row">


                                         <div class="col">
                        <div class="form-group">
                          <label><span class="text_error">*</span>{{from_currency_name}}</label>
   

                           <input
                            type="text"
                            class="form-control"
                            :class="{ form_error: from_currencyErr}"
                             v-model.trim="from_currency"
                             readonly
                             @blur="from_currency($event.target.value)"
                            placeholder="Provider's Currency"
                               style="width:100%"
                          />


                       
                          <div
                            :class="{ text_error: from_currencyErr }"
                            v-if="from_currencyErr"
                          >
                            {{ from_currencyErr }}
                          </div>
                        </div>
                      </div>
                      </div>

                      <hr/>

                           <div class="row">
                               <div class="col">
                        <div class="form-group">
                          <label><span class="text_error">*</span>Currency Convert To</label>
   

                          <v-select :options="getCurrencys" style="width:100%" class="select1" v-model="currency"  :reduce="currency => currency.currency" label="currency_code" @option:selected="currencyVal" />



                       
                          <div
                            :class="{ text_error: to_currencyErr }"
                            v-if="to_currencyErr"
                          >
                            {{ to_currencyErr }}
                          </div>
                        </div>
                      </div>


                                    </div>




                          <div class="row">
                               <div class="col">
                        <div class="form-group">
                          <label><span class="text_error">*</span>Currency Convert Value</label>
   


                          <input
                            type="text"
                            class="form-control"
                                 v-model.trim="currency_convert_val"
                             @blur="currency_convert_val_val($event.target.value)"
                            placeholder="Currency Convert Value"
                               style="width:100%"
                          />


                       
                          <div
                            :class="{ text_error: currency_convert_valErr }"
                            v-if="currency_convert_valErr"
                          >
                            {{ currency_convert_valErr }}
                          </div>
                        </div>
                      </div>


                                    </div>


                                          </div>

                                    
                                    <div class="row">
                        <div class="col">
                <button type="submit" class="btn btn-primary" style="margin-right:10px">Convert</button>
                </div>
                </div>


                                    </form>
                                    </div>
                                    </div>
                                    </div>








                        

                    </div>


                        </div>
                    </section>
                    <FooterComp/>
                </div>
            </div>
        </div>
        </div>

<DependentModals @dependent_added="dependent_added" />

        <!-- Modal -->
        <!-- <div class="modal fade" id="userModal" tabindex="-1" aria-labelledby="userModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="userModalLabel">Modal title</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                ...
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>
            </div>
        </div>
        </div> -->
    </div>
</template>


<script>
// @ is an alias to /src
import HeaderComp from "@/components/HeaderComp.vue"
import FooterComp from "@/components/FooterComp.vue"
import SideBarComp from "@/components/SideBarComp.vue"
import NavBarComp from "@/components/NavBarComp.vue"
import TableSearch from "@/components/table/TableSearch"
import ActionButton from "@/components/table/ActionButton"
import SelectAllComponent from "@/components/table/SelectAllComponent"
import axios from "axios"

//import "@ocrv/vue-tailwind-pagination/dist/style.css";
import FieldValidateService from "@/services/FieldValidateService";
import VueTailwindPagination from "@ocrv/vue-tailwind-pagination";
import "@ocrv/vue-tailwind-pagination/styles";
import notificationBox from '@/services/notificationBox'
import {BASEURL} from '@/services/UserService';
import { mapActions, mapMutations, mapState } from 'vuex'
import { APPROVE_PROVIDER, GET_DEPENDENT_SUPPORT_VIEW_TYPE, GET_PROVIDER_ID, LOADING, MASS_APPROVE_PROVIDER, MASS_REJECT_PROVIDER, REJECT_PROVIDER, RESET_DEPENDENT_SEARCH_RESULT, SEARCH_DEPENDENT, SEARCH_USER_ID, SEARCH_USER_USER_PORTAL, SET_PROVIDER_INFO, TABLE_CURRENT_PAGE, TABLE_PER_PAGE, TABLE_SEARCH, TABLE_SEL_ALL, TABLE_SORT_FIELD_DIRECTION, TABLE_STATUS, TABLE_TOTAL, TABLE_URL } from '@/store/storeconstants'
import SpinnerComp from "@/components/SpinnerComp.vue";
import DependentModals from '@/components/modalForms/Dependents.vue' 
import vSelect from 'vue-select'

export default {
  name: "TransactView",
  beforeRouteEnter(next){

     const user_data = JSON.parse(localStorage.getItem("users"));
     const user_level = user_data.user_level;
    
    if(user_level!='ADMIN'){
        //console.log(user_level);

     next(vm=> {
        vm.$router.push({name:"user-not-authorise"});
     })

    }else{
        return true;
    }
 
    
  },
  components: {
    HeaderComp, 
    FooterComp,
    SideBarComp, 
    NavBarComp,
    TableSearch,
    ActionButton,
    SelectAllComponent,
    VueTailwindPagination,
    SpinnerComp,
    vSelect,
    DependentModals
  },



  data(){
    return {
        transactionProcess:{},
        cartSubtotal:"",
        showUserModals:false,
        user_level:"",
        country_levels:[],
        //currentPage:1,
        //total:100,
        perPage:20,
        //perPage:20,
        search:"",
        status: "",
        checked:[],
        app_portal:"",
        provider_id:"",
        support_id:[],
        getSupports:[],
        getCurrencys:[],
        getMessage:"",
        errorClient:[],
        errorServers:[],
        errorFinalServers:[],
         errorFinalClient: [],
        dependent:"",
        selectPage:false,

        dependentErr:"",
        searchDependentErr:"",
        search_dependent_fail:"",
        dependentLoading:"",
        searchDependent:"",

        descriptionErr:"",
        unit_costErr:"",
        provider_country_id:"",
        support_country_id:"",
        from_currency:"",
        to_currency:"",
        from_currency_name:"",
        currency_convert_val:"",
        currency_convert_valErr:"",
        show_converter:false,
        view_type:"",
         currency:"",
         total_cost:"",

        formData:{
            dependent_id:"",
            provider_id:"",
            user_id:"",
            support_id:"",
            description:"",
            unit_cost:"",
            qty:"",
            id:"",
           
        }

    }
  },


  computed:{
    ...mapState('dataTable',['currentPage','url','total','perpage','getstatus','getsearch','selectAll','sort_direction','sort_field']),
    ...mapState("user_portal",['search_user_portal_id_result']),
    ...mapState("user",['search_user_id_result']),
    ...mapState("provider",['provider_message'])
  },



  methods:{

       ...mapMutations({
       table_select_all: 'dataTable/'+TABLE_SEL_ALL,
       table_sort_field_direction: 'dataTable/'+TABLE_SORT_FIELD_DIRECTION,
       table_current_page:'dataTable/'+TABLE_CURRENT_PAGE,
       table_url:'dataTable/'+TABLE_URL,
       table_per_page :'dataTable/'+TABLE_PER_PAGE,
       table_total :'dataTable/'+TABLE_TOTAL,
       table_search :'dataTable/'+TABLE_SEARCH,
       table_status :'dataTable/'+TABLE_STATUS,
       get_provider_id: 'provider/'+GET_PROVIDER_ID,
       showLoading:LOADING,
       reset_dependent_search_result: 'dependent/'+RESET_DEPENDENT_SEARCH_RESULT,
       get_dependent_support_view_type: 'dependent_support/'+GET_DEPENDENT_SUPPORT_VIEW_TYPE,

     //  verified_username:'auth/'+SUCC_AUTH_VERIFY,
   }),


   ...mapActions({
             search_user_id: 'user/'+SEARCH_USER_ID,
       search_user_portal_id:'user_portal/'+SEARCH_USER_USER_PORTAL,
       approve_provider:'provider/' + APPROVE_PROVIDER,
       reject_provider:'provider/'+ REJECT_PROVIDER,
       mass_approve_provider:'provider/'+ MASS_APPROVE_PROVIDER,
       mass_reject_provider:'provider/'+MASS_REJECT_PROVIDER,
       set_provider_info:'provider/'+SET_PROVIDER_INFO,
       search_dependent: 'dependent/'+SEARCH_DEPENDENT,
   }),

    selectAllRecords(){

        axios.get('transaction_processes/all?provider_id='+this.formData.provider_id+'&user_id='+this.formData.user_id)
             .then(response => {
                 console.log(response.data);
                  this.checked = response.data;
                  // this.selectAll = true;
                 // this.table_checked(response.data);
                   this.table_select_all(true);
             });
    },



    change_sort(field){
        // if(this.sort_field == field){
        //  this.sort_direction =   this.sort_direction == "asc" ? "desc" : "asc"
        // }else{
        //     this.sort_field = field;
        // }
        this.table_sort_field_direction(field);
        this.getTransactionProcess();

    },



//    deleteSingleRecord(id){

//     //console.log(id);

//        axios.delete(`providers/${id}`)
//        .then( (response) => {
//         this.checked = this.checked.filter(user_portal_id => user_portal_id != id)
//         //this.table_checked(this.checked.filter(user_portal_id => user_portal_id != id))
//         console.log(response.data.success)
//         notificationBox.succNotifications("Deleted Successfully");
//          this.getProvider();
//        }).catch(error => {
//         console.log(error.message)
//          notificationBox.errorNotifications(error)
//        })


//    },



   deleteRecords(){
      axios.delete(`transaction_processes/massDestroy/${this.checked}`)
      .then((response) => {
         if(response.status == 204){
            console.log(response.data.success)
            notificationBox.succNotifications("Selected Items Deleted Successfully");
            this.checked = [];
            //this.table_checked([])
            this.getTransactionProcess();

         }
      })
   },







   deleteSingleRecord(id){

    //console.log(id);

       axios.delete(`transaction_processes/${id}`)
       .then( (response) => {
        this.checked = this.checked.filter(transaction_process_id => transaction_process_id != id)
        //this.table_checked(this.checked.filter(user_portal_id => user_portal_id != id))
        console.log(response.data.success)
        notificationBox.succNotifications("Item Deleted Successfully");
         this.getTransactionProcess();
       }).catch(error => {
        console.log(error.message)
         notificationBox.errorNotifications(error)
       })


   },




 



    pageChange(pageNumber){

        //this.currentPage = pageNumber;
        this.table_current_page(pageNumber);
        this.getTransactionProcess();

    },



    transaction_porocess_added(){
        this.getTransactionProcess();
    },



   isChecked(id){

    return  this.checked.includes(id);

   },



    getTransactionProcess(){
   
        axios.get(
            'transaction_processes?page=' + this.currentPage + 
            '&page_size=' + this.perpage + 
            '&provider_id=' + this.formData.provider_id + 
            '&user_id=' + this.formData.user_id + 
            '&q=' +this.getsearch + 
            '&sort_direction=' + this.sort_direction +
            '&sort_field=' + this.sort_field 
            )
        .then(response => {
            this.transactionProcess = response.data.data.data;
            //this.searchDependent = response

            //this.perPage = response.data.data.per_page;
               this.table_per_page(response.data.data.per_page);
            //this.total = response.data.data.total;
             this.table_total(response.data.data.total)
        });


        this.getCartSubTotal();

        

    },







        getCartSubTotal(){
   
        axios.get(
            'get_cart_subtotal?provider_id=' + this.formData.provider_id +  
            '&user_id=' + this.formData.user_id 
            )
        .then(response => {
            console.log(response);
          
              this.cartSubtotal = response.data.data;
              this.total_cost = this.cartSubtotal; 
          
    
        });

    },






  async getCurrentDependentData(){
    try{
       const response = await axios.get(`transaction_processes/current_dep_data?provider_id=${this.formData.provider_id}&user_id=${this.formData.user_id}`);
       if(response.status == 200){
        this.searchDependent = response.data.dependent_email;
        this.dependent = response.data.full_detail;
        this.formData.dependent_id = response.data.dep_id;
        this.formData.provider_id = response.data.prov_id;
        this.formData.user_id = response.data.prov_id;
       }
    }catch(error){
      console.log(error);
    }

  },








   async getSupport(value){
        try{



       let response = await axios.get(`getDependentSupports?dependent_id=${value}`);
     // console.log(response.data.data);
     // response.data.data.unshift({id:"",country:"Please Select Option", created_at:null,updated_at:null})   
        this.getSupports =  JSON.parse(JSON.stringify(response.data.data));
        console.log(this.getSupports);
       
      // this.from_currency = "1 "+this.getCurrency(this.provider_country_id);
       
       
      
    }catch(error){
        console.log(error);
    }
   },






     



    perpageval(value){
        this.table_per_page(value);
       this.getTransactionProcess();

    },

    searchval(value){

         this.table_search(value);
        this.getTransactionProcess();

    },

    statusval(value){

        this.table_status(value);
        this.getTransactionProcess();

    },

 
 transactionProcessEdi(id)
{
  console.log(id);
},



 async reg_cart(){

       this.errorServers = [];
       this.errorClient = [];
       this.showLoading(true);
    
     this.descriptionVal(this.formData.description);
     this.unit_costVal(this.formData.unit_cost);

 
             if(this.errorClient.length > 0)
       {
         this.showLoading(false);
          notificationBox.errorNotifications("Some Errors Exist");
          console.log(this.errorClient);
          return true;
       }
       else{

              if(this.view_type != "edit")
              {


                   try{

         this.showLoading(false);

       let response = await axios.post(`transaction_processes`,this.formData);
     // console.log(response.data.data);
     // response.data.data.unshift({id:"",country:"Please Select Option", created_at:null,updated_at:null})   
        this.getMessage =  JSON.parse(JSON.stringify(response.data.message));
        this.getTransactionProcess();
         this.clearCartForm();
        notificationBox.succNotifications("Item Added To Cart");
     
       
       
       
      
    }catch(error){
        console.log(error);
               this.errorServers.push(error.response.data.error);
           this.showLoading(false);
           notificationBox.errorNotifications("Some Errors Exist");
            
            if(error.response.data.error_mess)
            {
                notificationBox.errorNotifications(error.response.data.error_mess);
            }

              else if(this.errorServers.length > 0)
       {


   
      this.descriptionErr = 'description' in this.errorServers[0] ? this.errorServers[0].description[0] : '';
      this.unit_costErr = 'unit_cost' in this.errorServers[0] ? this.errorServers[0].unit_cost[0] : '';
    


     
           return true;
       }

    }


       }

       else 
       {


         try{

         this.showLoading(false);

       let response = await axios.patch(`transaction_processes/${this.formData.id}`,this.formData);
     // console.log(response.data.data);
     // response.data.data.unshift({id:"",country:"Please Select Option", created_at:null,updated_at:null})   
        this.getMessage =  JSON.parse(JSON.stringify(response.data.message));
        this.view_type = "";
        this.getTransactionProcess();
         this.clearCartForm();
        notificationBox.succNotifications("Item Edited In Cart");
     
       
       
       
      
    }catch(error){
        console.log(error);
           this.errorServers.push(error.response.data.error);
           this.showLoading(false);
           notificationBox.errorNotifications("Some Errors Exist");

               if(error.response.data.error_mess)
            {
                notificationBox.errorNotifications(error.response.data.error_mess);
            }

             else  if(this.errorServers.length > 0)
       {
   
      this.descriptionErr = 'description' in this.errorServers[0] ? this.errorServers[0].description[0] : '';
      this.unit_costErr = 'unit_cost' in this.errorServers[0] ? this.errorServers[0].unit_cost[0] : '';
    


     
           return true;
       }
    }

       }




       }




  },







 async reg_final_trans(){


         this.errorFinalServers = [];
       this.errorFinalClient = [];
       this.showLoading(true);

       this.supportVal(this.formData.support_id);
       this.total_costVal(this.total_cost);

       if(this.errorFinalClient.length > 0)
       {
          this.showLoading(false);
           notificationBox.errorNotifications("Some Errors Exist");

       }

       else
       {

       const finalFormData = {
         total_cost: this.total_cost,
         dependent_id: this.formData.dependent_id,
         support_id: this.formData.support_id,
         user_id: this.formData.user_id,
         provider_id: this.formData.provider_id
       }


      try{
      const response = await axios.post("completeTransaction",finalFormData);
      console.log(response);
      if(response.status == 200)
      {
         this.showLoading(false);
          this.clearAllform();
          this.getTransactionProcess();
          this.transactionProcess = {};
        notificationBox.succNotifications("Transaction Completed Successfully");
      }


      }catch(error){
        console.log(error);
        this.showLoading(false);

        notificationBox.errorNotifications("Tranaction IS Not Succesful");

      }
       




       }
      

  },




    clearAllform(){
         this.clearCartForm();
         this.formData.support_id = [];
         this.total_cost = "";
          this.transactionProcess = {};
    },


    dependentSupportAddModal(){
      
        this.get_dependent_support_view_type("add");
        
        
     },


    dependent_added(){
      this.$refs.getCloseDependentSupportModal.click();
    },



    async getSearchedDependent(value){
          try{
         
         this.dependentLoading = true;

       let response = await axios.get(`getSearchDependents?searchVal=${value}`);
     // console.log(response.data.data);
     // response.data.data.unshift({id:"",country:"Please Select Option", created_at:null,updated_at:null})   
     this.dependentLoading = false;
        const getDependents =  JSON.parse(JSON.stringify(response.data.data));
        this.dependent =  getDependents.fullname+" - "+getDependents.email+" - "+getDependents.contact;
        this.formData.dependent_id = getDependents.dependent_id;
        this.searchDependentErr=null;
        //console.log(this.formData.dependent_id);
         this.getSupport(this.formData.dependent_id);
        
       
       
      
    }catch(error)
    {

      this.dependentLoading = false;
      console.log(error);
       this.searchDependentErr = JSON.parse(JSON.stringify(error.response.data.error));
      // error.response.data.error;
    }
    },





    async searchDependentVal(value)
 {

     let field = "Search Dependent By Contact/ Email";
             if(typeof value == "object" && value!=null)
             {
             value = value.id;
             }


      if(this.clear_user && !this.formData.dependent_id && FieldValidateService.reqVal2(value,field)){
        this.searchDependentErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.searchDependentErr);
      }else{


            if(this.errorClient.includes(this.searchDependentErr))
           {
              let pos = this.errorClient.indexOf(this.searchDependentErr);
              this.errorClient.splice(pos,1);
              this.searchDependentErr =""; 
           }



             this.getSearchedDependent(value);
            


      
      }


 },




 descriptionVal(value)
 {
    //console.log(value);
     let max = 800;
      let field = "Description"
    //  let value = e.target.value

      //console.log("YES");

         if(FieldValidateService.reqMaxStringVal(value, field,max))
     {
       this.descriptionErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.descriptionErr);
     }else{



            if(this.errorClient.includes(this.descriptionErr))
           {
              let pos = this.errorClient.indexOf(this.descriptionErr);
              this.errorClient.splice(pos,1);
              this.descriptionErr =""; 
           }

     }

 },





  unit_costVal(value)
 {
      let field = "Unit Cost"
    //  let value = e.target.value

      //console.log("YES");


         if(FieldValidateService.reqValNum(value,field))
     {
       this.unit_costErr = FieldValidateService.reqValNum(value,field)
        this.errorClient.push(this.unit_costErr);
     }else{



            if(this.errorClient.includes(this.unit_costErr))
           {
              let pos = this.errorClient.indexOf(this.unit_costErr);
              this.errorClient.splice(pos,1);
              this.unit_costErr =""; 
           }

     }

 },






 total_costVal(value){



   let field = "Total Cost"
    //  let value = e.target.value

      //console.log("YES");


         if(FieldValidateService.reqValNum(value,field))
     {
       this.total_costErr = FieldValidateService.reqValNum(value,field)
        this.errorFinalClient.push(this.total_costErr);
     }else{



            if(this.errorFinalClient.includes(this.total_costErr))
           {
              let pos = this.errorFinalClient.indexOf(this.total_costErr);
              this.errorFinalClient.splice(pos,1);
              this.total_costErr =""; 
           }

     }

 },







  async qtyVal(value,id,unit_cost)
  {
    this.showLoading(true);
       const changeData = {
         qty : value,
         id: id,
         unit_cost : unit_cost
       } 




         try{

         this.showLoading(false);

       let response = await axios.post(`update_qty`,changeData);
     // console.log(response.data.data);
     // response.data.data.unshift({id:"",country:"Please Select Option", created_at:null,updated_at:null})   
        const mess =  JSON.parse(JSON.stringify(response.data.message));
        if(mess == "success")
        {
            this.getTransactionProcess();
            console.log(mess);
        }
     
       
       
       
      
    }catch(error){
       
        this.showLoading(false);
        console.log(error)


    }
  },








   supportVal(value){

             let field = "Support";
            //  if(typeof value == "object" && value!=null)
            //  {
            //  value = value.id;
            //  }



      if(this.clear_user && !this.user_idd && FieldValidateService.reqVal(value,field)){
        this.supportErr = FieldValidateService.reqVal2(value,field);
        this.errorFinalClient.push(this.supportErr);
      }else{
        this.supportErr =""; 
          this.errorFinalClient.pop(this.supportErr);
       
          
      }

    },





async  transactionProcessEdit(id){
     console.log(id);
    this.view_type = "edit";


     try{
         

       let response = await axios.get('transaction_processes/'+id);
     // console.log(response.data.data);
     // response.data.data.unshift({id:"",country:"Please Select Option", created_at:null,updated_at:null})   
     this.dependentLoading = false;
        const getCartData =  JSON.parse(JSON.stringify(response.data.data));
        this.dependent =  getCartData.fullname+" - "+getCartData.email+" - "+getCartData.contact;
        this.formData.dependent_id = getCartData.dependent_id;
        this.formData.description = getCartData.description;
        this.formData.unit_cost = getCartData.unit_cost;
        this.formData.qty = getCartData.qty;
        this.formData.id = getCartData.id;

        //this.searchDependentErr=null;
        //console.log(this.formData.dependent_id);
         this.getSupport(this.formData.dependent_id);
        
       
       
      
    }catch(error)
    {

      console.log(error);

      // this.searchDependentErr = JSON.parse(JSON.stringify(error.response.data.error));
      // error.response.data.error;
    }


  },



   async getCurrency(country_id,currency_type){

        try{
       let response = await axios.get("getCurrency?country_id="+country_id);
     // console.log(response.data.data);
     // response.data.data.unshift({id:"",country:"Please Select Option", created_at:null,updated_at:null})   
        const getcurrency =  JSON.parse(JSON.stringify(response.data.data));
        if(currency_type == "from_currency"){
          this.from_currency_name = getcurrency;
          this.from_currency = 1;
        }
        else if(currency_type == "to_currency"){
          this.to_currency_name = getcurrency;
        }
        //console.log(this.getCountries);
       
       
       
      
    }catch(error){
        console.log(error);
    }

   },


 clearCartForm(){
    this.formData.unit_cost = "";
    this.formData.description = "";
 }




  },







    

  





       async created(){

        const user_data = JSON.parse(localStorage.getItem("users"));
         const provider_data = JSON.parse(localStorage.getItem("provider_data"));
         //console.log(provider_data)
          this.user_level = user_data.user_level;
          this.formData.provider_id = provider_data[0].provider_id;
          this.formData.user_id = user_data.id;

          this.provider_country_id = provider_data[0].provider_country_id;
         
    // this.portal =  localStorage.getItem('Portall')
    this.app_portal = localStorage.getItem("Portall");

    //this.currentPage = 1;
    this.table_current_page(1);
    this.getTransactionProcess();

    if(this.transactionProcess != {}){
      this.getCurrentDependentData();
    }


   


        try{
       let response = await axios.get("getCountries");
     // console.log(response.data.data);
     // response.data.data.unshift({id:"",country:"Please Select Option", created_at:null,updated_at:null})   
        this.getCurrencys =  JSON.parse(JSON.stringify(response.data.data));
        //console.log(this.getCountries);
       
       
       
      
    }catch(error){
        console.log(error);
    }



 

   


  },


  beforeMount(){


    
  this.getCurrency(this.provider_country_id,"from_currency");
          this.show_converter = true;

  },


  watch:{
    // "perPage"(value){
    //    this.table_per_page(value);
    //    this.getUser();
    // },
 
    //  "search"(value){
    //     this.table_search(value);
    //     this.getUser();
    //  },

    //  "status"(value){
    //     this.table_status(value);
    //     this.getUser();
    //  },

     "selectPage"(value){
         this.checked = [];
       //  this.table_checked([]);

        if(value){
            const trans_procs = JSON.parse(JSON.stringify(this.transactionProcess));
            trans_procs.forEach(trans_proc=>{
                this.checked.push(trans_proc.id)
                //this.table_checked_push(user.id);
            });
        
        }else{
              
              this.checked = [];
              //this.table_checked([]);
              //this.selectAll = false;
              this.table_select_all(false);
        }

     },

     "checked"(){

        this.table_url(BASEURL + "providers/export/"+ this.checked);
       // this.url = BASEURL + this.url + this.checked;

     }

  }

};
</script>