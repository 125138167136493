import { GET_EMPLOYEE_USER_COUNTRY_PORTAL, GET_EMPLOYEE_USER_COUNTRY_PORTAL_ID, GET_EMPLOYEE_USER_COUNTRY_PORTAL_VIEW_TYPE } from "@/store/storeconstants";

export default {
    [GET_EMPLOYEE_USER_COUNTRY_PORTAL](state,payload){
        
        state.employee_user_country_portal = payload;
    
    },


    [GET_EMPLOYEE_USER_COUNTRY_PORTAL_ID](state,payload){
 
         state.employee_user_country_portal_data_id = payload
  

    },


    [GET_EMPLOYEE_USER_COUNTRY_PORTAL_VIEW_TYPE](state,payload){
        state.employee_user_country_portal_view_type = payload
    }
}