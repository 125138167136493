import actions from "./actions";
import mutations from "./mutations";
export default {
    namespaced:true,
    state(){
        return{
            employee_user_country_portal:[],
            employee_user_country_portal_data_id:{},
            employee_user_country_portal_view_type:""
            // user_portal_inst_message:"",
            // user_portal_id:"",
            // modal_user_portal_view_type:"",
            // modal_user_portal_user_id:"",
            // modal_user_portal_id:"",
            // search_user_portal_id_result:{} //Get Result Of Search By User Portal Id
        }
    },

    mutations,
    actions,
}