<template>

<div>
    <div v-if="mess" class="text_error">{{messs}}</div>
    <div v-else>Successfully Verified</div>
</div>
    
</template>


<script>
import { AUTH_VERIFY } from '@/store/storeconstants'
import { mapActions} from 'vuex'
//import notificationBox from '@/services/notificationBox'
export default {
    name:'ActivationView',
    created(){

        this.page = localStorage.getItem("page");
        this.activate();
      //  localStorage.setItem("login_email",this.verified_username)
      //  localStorage.setItem("login_verified",this.is_verified)

    },
    data(){
        return{
          page:"",
          errorServers:[],
          mess:""
        }
    },

    methods:{

    ...mapActions({
        'verify':'auth/'+AUTH_VERIFY,
    }),

        async activate(){

            const formData = {
                code: this.$route.params.code,
                port: this.$route.params.port,
                prov_id: this.$route.params.prov_id
            };

                 await this.verify(formData).catch(error => {
           this.errorServers.push(error.response.data.errors);
           console.log(error);
          // this.showLoading(false);
          // notificationBox.errorNotifications(error.response.data.message);
           this.mess = error.response.data.message;
           
       });

       if(this.errorServers.length < 1){
        if(formData.port == "SUPPORT"){
         this.$router.push('/support_login');
        }
        else if(formData.port == "DEPENDENT")
        {
            this.$router.push('/dependent_login');
        }
            else if(formData.port == "PROVIDER")
        {
            this.$router.push('/provider_login');
        }
           else if(formData.port == "TELEMEDICINE")
        {
            this.$router.push('/telemedicine_login');
        }
        else 
        {
             this.$router.push('/em_login');
        }
       }




        }
    },

 
 
}
</script>