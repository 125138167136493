<template>

    <div class="unix-login">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-lg-4">
                    <div class="login-content">
                        <div class="login-logo">
                            <img src="@/assets/eMedipay-logo-small.png" alt="" style="height: 55px;"/>
                        </div>
                        <div class="card login-form">
                            <h4>OTP</h4>
                            <form > 
                                <div class="form-group">
                                    <label>Verify Otp Code</label>
                                     <input
                            type="otp"
                            class="form-control"
                            :class="{ form_error: otpErr || 'otp' in errorServers }"
                            placeholder="Otp"
                            v-model.trim.lazy="otp"
                          />
                          <div
                            :class="{ text_error: otpErr || 'otp' in errorServers }"
                            v-if="otpErr || 'otp' in errorServers"
                          >
                            {{ otpErr }} <span v-if="'otp' in errorServers">{{ errorServers.otp[0] }}</span>
                          </div>
                                </div>


                               <!-- <div v-if="count_down && !otp_success_message" class="text-success">Count Down: {{count_down}} To Input Otp <button class="btn btn-primary" @click.prevent="verify_otp()">Verify Otp</button></div>
                        <div v-else-if="show_otp_button && !otp_success_message" class="text-success"><button class="btn btn-primary" @click.prevent="resend_otp()">Resend Otp</button></div> -->
                        
                        <!-- <span v-if="otp_success_message" class="text-success">{{otp_success_message}}</span>
                        <span v-else-if="otpErr" class="text_error">{{otpErr}}</span><br/> -->
                
                                <!-- <router-link to="/reset-password" class="btn btn-primary btn-flat m-b-15">Submit</router-link> -->
                               <div v-if="count_down && !otp_success_message" class="text-success">Count Down: {{count_down}} To Input Otp  <button @click.prevent="handleSubmit()" class="btn btn-primary btn-flat m-b-15">Submit</button></div>
                                <button v-else-if="show_otp_button && !otp_success_message" @click.prevent="resend_otp()" class="btn btn-primary btn-flat m-b-15">Resend</button>

                                          <span v-if="otp_success_message" class="text-success">{{otp_success_message}}</span>

                                <div class="register-link text-center">
                                    <p>Back to <router-link to="/login"> Login Page</router-link></p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
 import axios from 'axios';
import FieldValidateService from '@/services/FieldValidateService';
import notificationBox from '@/services/notificationBox';
import { mapActions, mapMutations } from 'vuex';
import { AUTH_OTP, LOADING } from '@/store/storeconstants';
export default {    
  name: "LoginOtp",
  components: {

  },

  created(){
     this.portal = localStorage.getItem("Portall");
     const user_data = JSON.parse(localStorage.getItem("users"));
    // console.log(user_data);
     this.user_id = user_data.id;
     this.provider = localStorage.getItem("login_provider_idd");
     console.log(localStorage.getItem("login_provider_idd"));
     this.execOtpCountDown();
  },

  // mounted(){

  //   this.execOtpCountDown();

  // },

  data(){
    return{
        otp:"",
        otpErr:"",
        portal:"",
        provider:"",
        user_id:"",
        providerErr:"",
        count_down:"",
        show_otp_button:"", 
        otp_success_message:"",
        clear:false,
        errorServers:[],
        errorClient:[]
    }
  },


  methods:{

    ...mapActions({
        otpAct: 'auth/'+AUTH_OTP,
        
    }),

       ...mapMutations({
       showLoading:LOADING,
   }),

    async handleSubmit(){
       this.errorServers = [];

        this.showLoading(true);
        this.otpVal(this.otp);


        if(this.errorClient.length > 0){
            this.showLoading(false);
          notificationBox.errorNotifications("Some Errors Exist");
            
        }
        else{

            const formData = {
              otp:this.otp,
              user_id:this.user_id,
              provider_id:this.provider,
              portal:this.portal
            };

console.log("see provider",this.provider);
        

            await this.otpAct(formData).catch(error => {
           this.errorServers.push(error.response.data.errors);
           console.log(error);
           this.showLoading(false);
           this.otp_success_message = error.response.data.message;
           notificationBox.errorNotifications(error.response.data.message);
           

        });

           if(this.errorServers.length < 1){

           localStorage.removeItem("login_provider_idd")

           this.show_otp_button = false;
           this.otp_success_message = "";

             this.showLoading(false);
        this.clear_all();
       // notificationBox.successAlert("Email Successful","An Email Has Been Sent To You. Please Click On The Link In Your Mail, To Take You To Reset Password Page")
        this.$router.push("/dashboard");
           }

    }
    },

        clear_all(){
      this.clear = true;
      this.otp = "";
      this.clear = false;
        },
     
         otpVal(value){


                 let field = "OTP"
                 let max = 7;

                

         if(!this.clear && FieldValidateService.reqMaxNumVal(value, field, max))
     {
       this.otpErr = FieldValidateService.reqMaxNumVal(value,field,max);
        this.errorClient.push(this.otpErr);
     }else{

           this.otpErr =""; 
          this.errorClient.pop(this.otpErr);

     }


    },










       async resend_otp(){

    try{

      const otpData = {
        user_id : this.user_id,
        provider: this.provider,
        portal: this.portal
      }

      const response = await axios.post("resend_login_otp",otpData);
      console.log(response);
      this.execOtpCountDown();

    }catch(error){
   console.log(error);
    }


   },



  async send_empty_otp(){


    try{

      const otpData = {
        user_id : this.user_id,
        provider: this.provider,
        portal: this.portal
      }

      await axios.post("send_empty_login_otp",otpData);
    //  console.log(response);

    }catch(error){
   console.log(error);
    }




  },





      execOtpCountDown(){

          this.count_down = 60;
        let refreshId =    setInterval(() => {
  
     this.count_down--;
     if(this.count_down <= 0)
     {
        this.count_down = "" ;  
        this.show_otp_button = true;  
         this.send_empty_otp();
         clearInterval(refreshId);
        
     }
   }, 1000);




      
   },




    

  //  async verify_otp(){
    
  //   try{

  //     const otpData = {
  //       user_id: this.user_idd,
  //       otp: this.formData.reg_otp
  //     }

  //     const response = await axios.post("verifyUserExistOtp",otpData);
  //     console.log(response);
  //     this.show_otp_button = false;
  //     this.otp_success_message = JSON.parse(JSON.stringify(response.data.message));
      


  //   }catch(error){
  //     console.log(error);

  //     this.otpErr = error.response.data.error;
        
  //     this.otp_success_message = "";

  //   }

  //  },



 

  },





  watch:{

     otp(curValue){
        this.otpVal(curValue);
     },



  }


}
</script>