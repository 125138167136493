<template>
        <div class="col-1 text-end">
            <div class="d-flex align-items-center ml-4" style="min-width: 100px; float:right;">
                <label for="paginate" class="text-nowrap mr-2 mb-0"><strong>Per Page</strong></label>
                <select v-model.number.trim="perPage" class="form-control form-control-sm">
                    <option value="20">20</option>
                    <option value="2">2</option>
                    <option value="40">40</option>
                    <option value="60">60</option>
                    <option value="80">80</option>
                    <option value="100">100</option>
                    <option value="120">120</option>
                    <option value="140">140</option>
                </select>
        
            </div>
        </div>
</template>

<script>
    export default {
        name:'PerPageComponent',
        emits:['perpageValue'],

        data(){
            return{
                 perPage:20
            }
        },


      watch:{
        perPage(value){

             this.$emit('perpageValue', value)

        }
      }
    }
</script>

<style lang="scss" scoped>

</style>