<template>

        <!-- Modal -->
        <div class="modal fade"  :class="{ show: false, 'd-block': false }"  id="supportBanReasonModal"    aria-labelledby="supportBanReasonModalLabel" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg" style="max-height:30%; max-width: 80%;" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="supportBanReasonModalLabel" v-if="this.view_type=='note'">Support Ban Reason</h5>
                <button type="button" ref="supportBanReasonModal" class="btn-close" :data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#supportBanReasonModal" aria-label="Close"></button>
            </div>
              <div>
              <form class="register-form" @submit.prevent="reg()">
            <div class="modal-body">

                <div class="card shadow-sm" id="stylized">
                   
                                <!-- <div class="card-body"> -->


                    <div class="card-body">
                      <h1>Support Ban Reason</h1><br/>
                      <p></p>
        
                  <span>
                  
                    <div class="row">
                     



                                  <div class="col">
                        <div class="form-group">
                          <label>Reason</label>

                          <!-- <input
                            type="text"
                            class="form-control"
                            :class="{ form_error: fullnameErr}"
                            placeholder="Full Name"
                            @blur="fullnameVal($event.target.value)" 
                            v-model.trim="formData.fullname"
                          /> -->
                          <textarea 
                          class="form-control"
                          :class="{ fomr_error: reasonError}"
                          placeholder="Reason"
                          @blur="reasonVal($event.target.value)" 
                          v-model.trim="formData.reason"
                          style="width:700px; height:200px"
                        >

                          </textarea>
                          <div
                            :class="{ text_error: reasonErr  }"
                           v-if="reasonErr">
                          {{ reasonErr }} 
                          </div>
                        </div>
                      </div>




                    </div>




                
                  </span>
                










                  
                </div>


                    

                                </div>
                
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary">Submit</button>
                <button type="button" class="btn btn-secondary" ref="supportRemovemodal" data-bs-dismiss="modal">Close</button>
            </div>

                  </form>
                  </div>


              



            </div>
        </div>


           



        
        </div>
</template>

<script>
 import axios from 'axios';
import FieldValidateService from "@/services/FieldValidateService";
// import vSelect from 'vue-select';
import notificationBox from "@/services/notificationBox";
// import intlTelInput from 'intl-tel-input';
// import SpinnerComp from "@/components/SpinnerComp.vue";
import { mapMutations } from 'vuex';
import { LOADING } from '@/store/storeconstants';


    export default {

        name:'SupportBanNote',
        props:{
  





         getSupportId:{
           required:true
         },



   



        },


        components:{
        //  SpinnerComp,
         // vSelect,
        },


           mounted(){



           },

        async created() {
        //  this.showLoading(true);


      //    this.formData.fname = this.firstName;
      
         
    // this.portal =  localStorage.getItem('Portall')
    this.app_portal = localStorage.getItem("Portall");
    this.formData.portal = localStorage.getItem("Portall");

      const user_data = JSON.parse(localStorage.getItem("users"));
          this.formData.provider_id = user_data.provider_id;  
        //  console.log(this.formData.provider_id);
 //this.view_data();

    switch(this.app_portal) {
 case 'Dependent':
    this.formData.user_level = "DEPENDENT";
    break;
  case 'Support':
    this.formData.user_level = "SUPPORT";
    break;
  case 'Telemedicine':
    this.formData.user_level = "TELEMEDICINE";
    break;
  case 'Provider':
    this.formData.user_level = "ADMIN";
    break;
  default:
    this.formData.user_level = "";
}



   try{
       let response = await axios.get("getCountries");
     // console.log(response.data.data);
     // response.data.data.unshift({id:"",country:"Please Select Option", created_at:null,updated_at:null})   
        this.getCountries =  JSON.parse(JSON.stringify(response.data.data));
        //console.log(this.getCountries);
       
       
       
      
    }catch(error){
        console.log(error);
    }





   try{
       let response = await axios.get("getProviders");
      // console.log(response.data.data);
         
        this.getProviders =  JSON.parse(JSON.stringify(response.data.data));
        //console.log(this.getCountries);
       
       
       
      
    }catch(error){
        console.log(error);
    }


  // if(this.app_portal === 'Provider')
  // {
  //       try{
  //      let response = await axios.get("getHealthCares");
  //     // console.log(response.data.data);
         
  //       this.getHealthCares =  JSON.parse(JSON.stringify(response.data.data));
  //       //console.log(this.getCountries);
       
       
       
      
  //   }catch(error){
  //       console.log(error);
  //   }
  // }


  

 
  
 

        },




         data: function () {
    return {
      //userModal: null,
      link_val:"",
      is_existing_user: false,
      is_existing:"NO",
      app_portal: "",
      search: "",
      searchErr: "",
      loading:false,
      getCountries:[],
      getProviders:[],
      country_name:"",
      errorMess: "",
      succMess:"",
      SearcherrorServers:"",
      clear_user: true,
      clear_all: true,
      errorClient:[],
      errorServers:[],
 


      modal:"modal",


   
      

      formData: {
        id:"",
        status:"BAN",
        reason:"",
        
        
      },



    //Errors  
      reasonErr: "",









    };
    },


      methods: {

           ...mapMutations({
       showLoading:LOADING,
   }),


        async reg(){

    
        
   
           this.showLoading(true); 
    
      this.errorServers = [];
       this.errorClient = [];
      

       this.reasonVal(this.formData.reason);
       

       

                 if(this.errorClient.length > 0)
       {
        // clearTimeout(bbb);
         this.showLoading(false);
          notificationBox.errorNotifications("Some Errors Exist");
         // console.log(this.errorClient);
          return true;
       }
       else{

        

            try{
               
             const response = await axios.post("update_support_status",this.formData);

                  
               this.showLoading(false);
            if(response.status == 200)
            {
         this.clearFormData();
        notificationBox.succNotifications("Support Member Ban Successfully");
        this.$emit("banSucc");
        this.$refs.supportRemovemodal.click();
            }

            }catch(error){

            //console.log(error);
           
           this.showLoading(false);
           notificationBox.errorNotifications(error.response.data.error);

     

            }

          








       }

        },








        clearFormData(){
        this.formData.reason = "";
        this.reasonErr = "";





        this.errorClient = [];
      this.errorServers = [];


        //view_portal:"",

        },



        reasonVal(value){

      let field = "Reason"

         if(FieldValidateService.reqVal(value, field))
     {
       this.reasonErr = FieldValidateService.FieldValidateService.reqVal(value, field);
        this.errorClient.push(this.reasonErr);
     }else{

           this.reasonErr =""; 
          this.errorClient.pop(this.reasonErr);

     }


    },








      },


   


      watch:{
           view_type(value){

           if(value == "add")
           {
             this.clearFormData();
           }

          

        },




        getSupportId(value){

            this.formData.id = value;
        }

     

      }
        
    }
</script>

<style scoped>


</style>