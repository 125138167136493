<template>
  <!-- <div>
    <img src="../assets/loading.gif" alt="checking..." title="checking..." class="d-block m-auto">
  </div> -->

  <div>
    <img src="../assets/loading.gif" alt="checking..." title="checking...">
  </div>
</template>

<script>
export default {
  name: "SpinnerComp"
};
</script>

<style scoped>
img{
  width: 30px;
}
</style>