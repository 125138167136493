import { SUCC_PROVIDER_REG_DATA, PROVIDER_REG, VERIFY_PROVIDER_EXIST, GET_PROVIDER_EXIST_MESS, SET_PROVIDER_MESSAGE, APPROVE_PROVIDER, REJECT_PROVIDER, MASS_APPROVE_PROVIDER, MASS_REJECT_PROVIDER, SET_PROVIDER_INFO, GET_PROVIDER_INFO } from '@/store/storeconstants';
import axios from 'axios';
export default{

    async [PROVIDER_REG](context,payload){

        let response = await axios.post('providers',payload);
        //  console.log(response);
          let mess = response.data.message;
         
          if(mess === 'success'){
           let data = response.data.data;
              context.commit(SUCC_PROVIDER_REG_DATA,data);
          }

    },



    async [VERIFY_PROVIDER_EXIST](context,payload){
        let response = await axios.post('verifyproviderexist',payload);
        let mess = response.data.message;
         if(mess === 'success'){
            context.commit(GET_PROVIDER_EXIST_MESS,mess)
         }
      },




      async [APPROVE_PROVIDER](context,payload){

        let response = await axios.post('approve_provider',payload);
        //  console.log(response);
          let mess = response.data.message;
         
          if(mess === 'success'){
              context.commit(SET_PROVIDER_MESSAGE,"Provider Approved");
          }

    },




    async [REJECT_PROVIDER](context,payload){

        let response = await axios.post('reject_provider',payload);
        //  console.log(response);
          let mess = response.data.message;
         
          if(mess === 'success'){
              context.commit(SET_PROVIDER_MESSAGE,"Provider Rejected");
          }

    },





    async [MASS_APPROVE_PROVIDER](context,payload){

        let response = await axios.post('mass_approve_provider',payload);
        //  console.log(response);
          let mess = response.data.message;
         
          if(mess === 'success'){
              context.commit(SET_PROVIDER_MESSAGE,"These Provider(s) Approved");
          }

    },



    async [MASS_REJECT_PROVIDER](context,payload){

        let response = await axios.post('mass_reject_provider',payload);
        //  console.log(response);
          let mess = response.data.message;
         
          if(mess === 'success'){
              context.commit(SET_PROVIDER_MESSAGE,"These Provider(s) Rejected");
          }

    },


    async [SET_PROVIDER_INFO](context,payload){

        let response = await axios.get('providers/'+payload);
        //  console.log(response);
          let mess = response.data.message;
          console.log(mess);
         
          if(mess === 'success'){
              let data = response.data.data;
              console.log(data);
              context.commit(GET_PROVIDER_INFO,data);
          }

    }

}