import actions from "./actions";
import mutations from "./mutations";
export default {
    namespaced:true,
    state(){
        return{
            dependent_support_id:"",
            dependent_support_idd:"",
            dependent_support_view_type:"",
            dependent_support_info:{},
            search_dependent_support_result:{},
            search_message:""
        }
    },

    mutations,
    actions,
}