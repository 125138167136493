import { EDIT_EMPLOYEE_USER_COUNTRY_PORTAL, GET_EMPLOYEE_USER_COUNTRY_PORTAL, GET_EMPLOYEE_USER_COUNTRY_PORTAL_ID, SEARCH_EMPLOYEE_USER_COUNTRY_PORTAL, SEARCH_EMPLOYEE_USER_COUNTRY_PORTAL_ID } from "@/store/storeconstants";
import axios from 'axios';

export default {

    async [SEARCH_EMPLOYEE_USER_COUNTRY_PORTAL](context,payload){
        let response = await axios.get(`getEmployeeCountryPortal/${payload}`);
        let data = response.data.data;
       // console.log(data);
        context.commit(GET_EMPLOYEE_USER_COUNTRY_PORTAL,data);
    },


    async[SEARCH_EMPLOYEE_USER_COUNTRY_PORTAL_ID](context,payload){
              
        let response = await axios.get(`gerdd_employee_countries/${payload}`);
        let data = response.data.data;

        console.log(data);

        context.commit(GET_EMPLOYEE_USER_COUNTRY_PORTAL_ID,data)
    },


    async[EDIT_EMPLOYEE_USER_COUNTRY_PORTAL](context,payload){

        let response = await axios.patch(`gerdd_employee_countries/${payload.employee_user_country_id}`,payload);
        //  console.log(response);
          let mess = response.data.message;
         
          if(mess === 'success'){
           let data = response.data.data;
              context.commit(GET_EMPLOYEE_USER_COUNTRY_PORTAL_ID,data);
          }

    },

}