import { DEPENDENT_SUPPORT_LINK_REG, GET_DEPENDENT_SUPPORT_INFO, SET_DEPENDENT_SUPPORT_INFO} from '@/store/storeconstants';
import axios from 'axios';
export default{




      async [DEPENDENT_SUPPORT_LINK_REG](context,payload){

        await axios.post('add_dependent',payload);
        //  console.log(response);
      

    },





    async [SET_DEPENDENT_SUPPORT_INFO](context,payload){

      let response = await axios.get('dependent_supports/'+payload);
      //  console.log(response);
      //  let mess = response.data.data;
       // console.log(response.data);
       
      
            let data = response.data.data;
            console.log(data);
            context.commit(GET_DEPENDENT_SUPPORT_INFO,data);
        

  },




}