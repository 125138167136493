import { GET_DEPENDENT_INFO, GET_DEPENDENT_VIEW_TYPE, GET_PROVIDER_ID, GET_SEARCH_DEPENDENT, RESET_DEPENDENT_SEARCH_RESULT, SUCC_DEPENDENT_REG_DATA } from "@/store/storeconstants";

export default{
    [SUCC_DEPENDENT_REG_DATA](state,payload){

        state.dependent_id = payload.id;

    },


    [GET_DEPENDENT_INFO](state,payload){
        state.dependent_info = payload;
    },


    [GET_DEPENDENT_VIEW_TYPE](state,payload){
        state.dependent_view_type = payload;
    },

    [GET_PROVIDER_ID](state,payload){

        state.provider_idd = payload;

    },




    [GET_SEARCH_DEPENDENT](state,payload){
         
        if(payload.success_data == "Link Dependent To Support" || payload.success_data == "Success" || payload.success_data == "You Are Already Providing Support To This Dependent"){
            console.log(payload.data)
            state.search_dependent_result = payload.data;
            state.dependent_idd = payload.data.dependent_id;
            state.search_message = payload.success_data;
        }
 
        else{
            state.search_message = payload.success_data;
            state.dependent_idd = ""
            state.search_dependent_result = {};
        }

      

    },


    [RESET_DEPENDENT_SEARCH_RESULT](state){

        state.search_dependent_result = {};
        state.dependent_idd = "";
        state.search_message = "";

    },


}