import { GET_SEARCH_USER, GET_SEARCH_USER_ID, GET_SEARCH_USER_PER_PORTAL, SEARCH_USER, SEARCH_USER_ID, SEARCH_USER_PER_PORTAL, SUCC_USER_REG_DATA, USER_REG, VERIFY_USER_EXIST_OTP } from "@/store/storeconstants";
import axios from 'axios';

export default{

   async [USER_REG](context,payload){


    let response = await axios.post('users',payload);
  //  console.log(response);
    let mess = response.data.message;
   
    if(mess === 'success'){
     let data = response.data.data;
        context.commit(SUCC_USER_REG_DATA,data);
    }


    },



    async [VERIFY_USER_EXIST_OTP](_,payload){
      let response = await axios.post('verifyUserExistOtp',payload);
      let mess = response.data.message;
       if(mess === 'success'){
          console.log("");
       }
    },



    async [SEARCH_USER](context,payload){
        let response = await axios.post("getUser",payload);
        let data = response.data.data;
       // console.log(data);
        context.commit(GET_SEARCH_USER,data);
    },


    async [SEARCH_USER_PER_PORTAL](context,payload){

      let response = await axios.post("getUserPerPortal",payload);
      let data = response.data;
     // console.log(data);
      context.commit(GET_SEARCH_USER_PER_PORTAL,data);

    },


    async [SEARCH_USER_ID](context,payload){

      let response = await axios.get(`users/${payload}`);
      let data = response.data.data;
     // console.log(data);
      context.commit(GET_SEARCH_USER_ID,data);

    },





}