<template>
    <div>
        <SideBarComp/>
        <NavBarComp/>
        <div class="content-wrap">
        <div class="main">
            <div class="section__content section__content--p30">
                <div class="container-fluid">  
                    <HeaderComp page="Dependents" pageDesc="list of all dependents"/>                
                    <section id="main-content">
                        <div class="row ps-3 pe-3">
                            <div class="col-md-12">                               
                                <div class="row">

                                            <!-- <div class="col-1 text-end">
                                        <div class="d-flex align-items-center ml-4" style="min-width: 100px; float:right;">
                                            <label for="paginate" class="text-nowrap mr-2 mb-0"><strong>Per Page</strong></label>
                                            <select v-model.number.trim="perPage" class="form-control form-control-sm">
                                                <option value="20">20</option>
                                                <option value="2">2</option>
                                                <option value="40">40</option>
                                                <option value="60">60</option>
                                                <option value="80">80</option>
                                                <option value="100">100</option>
                                                <option value="120">120</option>
                                                <option value="140">140</option>
                                             </select>
                                       
                                        </div>
                                    </div> -->

                                    <PerPageComponent @perpageValue="perpageval" />

                                    <TableFilter @statusValue="statusval" portall="support" />

                                        <!-- <div class="col-3 text-end">
                                         <div class="d-flex align-items-center ml-4">
                                            <label for="paginate" class="text-nowrap mr-2 mb-0"><strong>FilterBy Status</strong></label>
                                            <select v-model="status" class="form-control form-control-sm">
                                                <option value="">All Status</option>
                                                <option value="PENDING">PENDING</option>
                                                <option value="APPROVED">APPROVED</option>
                                                <option value="REJECTED">REJECTED</option>
                                                <option value="BAN">BAN</option>
                                            </select>

                                         </div>
                                    </div> -->
                                



                                    <!-- <div class="col-4 text-end">
                                        <div style="min-width: 400px; float:right;">
                                                <div class="input-group mb-3">
                                                <input type="text" class="form-control" v-model="search" placeholder="Search" name="SearchTerm">
                                                </div>
                                        </div>
                                    </div> -->

                                    <TableSearch @searchValue="searchval" />


                                  
                                     <!-- <div class="col-2 text-end">
                          
                                        <div class="dropdown ml-4">
                                            <button v-if="checked.length > 0" class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown">
                                                With checked ({{ checked.length }})
                                            </button>
                                            <div class="dropdown-menu">
                                                <a  onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" href="#" class="dropdown-item" type="button" @click.prevent="deleteRecords">
                                                    Delete
                                                </a>
                                                   <a :href="url"  class="dropdown-item" type="button">
                                                    Export
                                                </a>
                                            </div>
                                        </div>
                                    </div> -->


                                   <ActionButton :num_checked="checked.length" portall="" />









                                
                                    <div class="col-2 text-end">
                                        <a class="btn btn-outline-success" @click="dependentSupportAddModal()"   data-bs-toggle="modal"  href="#dependentSupportModal">
                                            <i class="fas fa-plus"></i> Add Dependent
                                        </a>   

                                          <a class="btn btn-outline-success" style="display:none" ref="getDependentSupportModal" data-bs-toggle="modal" href="#dependentSupportModal" >
                                            <i class="fas fa-plus"></i> Link User To Country Portal
                                        </a>    

                                          <a class="btn btn-outline-success" style="display:none" ref="viewDependentSupportDetail" data-bs-toggle="modal" href="#dependentSupportDetailModule" >
                                            <i class="fas fa-plus"></i> View Dependent Support
                                        </a> 
                                    </div>


                                             <!-- <div class="col-4 text-start" v-if="selectPage">

                                            <div v-if="selectAll">
                                                You are currently selecting all
                                                <strong>{{ checked.length }}</strong> items.
                                            </div>

                                            <div v-else>
                                                You have selected <strong>{{ checked.length }}</strong> items, Do you want to
                                                Select All <strong>{{total}}</strong>?
                                                 <a @click.prevent="selectAllRecords" href="#" class="m1-2">Select All</a>
                                            </div>
                                       
                                    </div> -->


                                    <SelectAllComponent @selRecords="selectAllRecords" :num_checked="checked.length" v-if="selectPage" />


                                </div>
                            </div>


                                 <div class="card shadow-sm">
                                <div class="card-body">
                                    <div class="table-responsive table-responsive-data2">
                                        <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                                <!-- <th scope="col">#</th> -->
                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('users.fullname')">Full Name</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.fullname'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('users.gender')">Gender</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.gender'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.gender'">&darr;</span>
                                                </th>

                                     

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('users.contact')">Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.contact'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('users.email')">Email</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.email'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.email'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('countries.country')">Country</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='countries.country'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='countries.country'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('dependent_supports.status')">Status</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='dependent_supports.status'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='dependent_supports.status'">&darr;</span>
                                                </th>

                                        

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('users.city')">City</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='users.city'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='users.city'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                Action
                                                </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(dep) in dependents" :key="dep.dependent_support_id" :class="isChecked(dep.dependent_support_id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="dep.dependent_support_id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{ dep.fullname}}</td>
                                                    <td>{{ dep.gender }}</td>
                                                    <td>{{ dep.contact }}</td>
                                                    <td>{{ dep.email }}</td>
                                                    <td>{{ dep.country }}</td>
                                                    <td>{{ dep.status }}</td>
                                                    <td>{{ dep.city }}</td>
                                                    <td>
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                                            <li class="d-flex">                                                    
                                                                <div class="btn"  title="edit" @click="depEdit(dep.dependent_support_id)" v-if="user_level=='SUPPORT'">
                                                                    <i class="fa-solid fa-pencil"></i>
                                                                </div>                                                  
                                                                <div onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" class="btn" title="delete" @click="deleteSingleRecord(dep.dependent_support_id)" v-if="user_level=='SUPPORT'">
                                                                    <i class="fa-solid fa-trash"></i>
                                                                </div>                                                  
                                                                <a class="btn" title="View Dependent Details" @click="depView(dep.dependent_support_id)">
                                                                    <i class="fa-solid fa-eye"></i>
                                                                </a>

                                                                 <a class="btn" title="View Dependent Support Details" @click="depSuppView(dep.dependent_id)">
                                                                    <i class="fa-solid fa-users"></i>
                                                                </a>
                                                            </li>
                                                        </div>
                                                    </td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="row mt-4">
                                             <VueTailwindPagination
                                            :current="currentPage"
                                            :total="total"
                                            :per-page="perpage"
                                            @page-changed="pageChange($event)"
                                            />
                                    </div>
                                </div>
                            </div>
                            
                         
                        </div>
                    </section>
                    <FooterComp/>
                </div>
            </div>
        </div>
        </div>


        <DependentModals @dependent_added="dependent_added" />

        <ViewDependentSupport :getDependentSupportData="getDependentSupportData" />
        <!-- Modal -->
        <!-- <div class="modal fade" id="userModal" tabindex="-1" aria-labelledby="userModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="userModalLabel">Modal title</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                ...
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>
            </div>
        </div>
        </div> -->
    </div>
</template>


<script>
// @ is an alias to /src
import HeaderComp from "@/components/HeaderComp.vue"
import FooterComp from "@/components/FooterComp.vue"
import SideBarComp from "@/components/SideBarComp.vue"
import NavBarComp from "@/components/NavBarComp.vue"
import PerPageComponent from "@/components/table/PerPage.vue"
import TableSearch from "@/components/table/TableSearch"
import TableFilter from "@/components/table/TableFilter"
import ActionButton from "@/components/table/ActionButton"
import SelectAllComponent from "@/components/table/SelectAllComponent"
import axios from "axios"

//import "@ocrv/vue-tailwind-pagination/dist/style.css";
import VueTailwindPagination from "@ocrv/vue-tailwind-pagination";
import "@ocrv/vue-tailwind-pagination/styles";
import notificationBox from '@/services/notificationBox'
import {BASEURL} from '@/services/UserService';
import DependentModals from '@/components/modalForms/Dependents.vue' 
import ViewDependentSupport from '@/components/modalForms/ViewDependentSupport.vue' 
import { mapActions, mapMutations, mapState } from 'vuex'
import {  GET_DEPENDENT_ID, GET_DEPENDENT_INFO, GET_DEPENDENT_SUPPORT_VIEW_TYPE, GET_SEARCH_DEPENDENT, LOADING, SEARCH_USER_ID, SEARCH_USER_USER_PORTAL, SET_DEPENDENT_INFO, SET_DEPENDENT_SUPPORT_INFO, TABLE_CURRENT_PAGE, TABLE_PER_PAGE, TABLE_SEARCH, TABLE_SEL_ALL, TABLE_SORT_FIELD_DIRECTION, TABLE_STATUS, TABLE_TOTAL, TABLE_URL } from '@/store/storeconstants'


export default {
  name: "DependantsView",
  beforeRouteEnter(next){

     const user_data = JSON.parse(localStorage.getItem("users"));
     const user_level = user_data.user_level;
    
    if(user_level =='DEPENDENT'){
     next(vm=> {
        vm.$router.push({name:"user-not-authorise"});
     })

    }else{
        return true;
    }
 
    
  },
  components: {
    HeaderComp, 
    FooterComp,
    SideBarComp, 
    NavBarComp,
    PerPageComponent,
    TableSearch,
    TableFilter,
    ActionButton,
    SelectAllComponent,
    VueTailwindPagination,
    DependentModals,
    ViewDependentSupport
  },



  data(){
    return {
        dependents:{},
        showUserModals:false,
        user_level:"",
        country_levels:[],
        //currentPage:1,
        //total:100,
        perPage:20,
        //perPage:20,
        search:"",
        status: "",
        checked:[],
        app_portal:"",
        provider_id:"",
        getDependentSupportData:{},
        selectPage:false,
        //selectAll:false,
     //   sort_direction: 'desc',
     //   sort_field: 'fullname',
      //  url:'',
      formData:{
        support_id:""
      }
    }
  },


  computed:{
    ...mapState('dataTable',['currentPage','url','total','perpage','getstatus','getsearch','selectAll','sort_direction','sort_field']),
    ...mapState("user_portal",['search_user_portal_id_result']),
    ...mapState("user",['search_user_id_result']),
    ...mapState("provider",['provider_message'])
  },



  methods:{

       ...mapMutations({
       table_select_all: 'dataTable/'+TABLE_SEL_ALL,
       table_sort_field_direction: 'dataTable/'+TABLE_SORT_FIELD_DIRECTION,
       table_current_page:'dataTable/'+TABLE_CURRENT_PAGE,
       table_url:'dataTable/'+TABLE_URL,
       table_per_page :'dataTable/'+TABLE_PER_PAGE,
       table_total :'dataTable/'+TABLE_TOTAL,
       table_search :'dataTable/'+TABLE_SEARCH,
       table_status :'dataTable/'+TABLE_STATUS,
       get_dependent_id: 'dependent/'+GET_DEPENDENT_ID,
       get_dependent_info: 'dependent/'+GET_DEPENDENT_INFO,
       get_search_dependent: 'dependent/'+GET_SEARCH_DEPENDENT,
       get_dependent_support_view_type: 'dependent_support/'+GET_DEPENDENT_SUPPORT_VIEW_TYPE,
       showLoading:LOADING,

     //  verified_username:'auth/'+SUCC_AUTH_VERIFY,
   }),


   ...mapActions({
             search_user_id: 'user/'+SEARCH_USER_ID,
       search_user_portal_id:'user_portal/'+SEARCH_USER_USER_PORTAL,
       set_dependent_info:'dependent/'+SET_DEPENDENT_INFO,
       set_dependent_support_info:'dependent_support/'+SET_DEPENDENT_SUPPORT_INFO,
   }),

    selectAllRecords(){

        axios.get('dependent_supports/all?status=' + this.getstatus)
             .then(response => {
                 console.log(response.data);
                  this.checked = response.data;
                  // this.selectAll = true;
                 // this.table_checked(response.data);
                   this.table_select_all(true);
             });
    },



    change_sort(field){
        // if(this.sort_field == field){
        //  this.sort_direction =   this.sort_direction == "asc" ? "desc" : "asc"
        // }else{
        //     this.sort_field = field;
        // }
        this.table_sort_field_direction(field);
        this.getDependentSupport();

    },



   deleteSingleRecord(id){

    //console.log(id);

       axios.delete(`dependent_supports/${id}`)
       .then( (response) => {
        this.checked = this.checked.filter(dependent_support_id => dependent_support_id != id)
        //this.table_checked(this.checked.filter(user_portal_id => user_portal_id != id))
        console.log(response.data.success)
        notificationBox.succNotifications("Deleted Successfully");
         this.getDependentSupport();
       }).catch(error => {
        console.log(error.message)
         notificationBox.errorNotifications(error)
       })


   },



   deleteRecords(){
      axios.delete(`dependent_supports/massDestroy/${this.checked}`)
      .then((response) => {
         if(response.status == 204){
            console.log(response.data.success)
            notificationBox.succNotifications("Selected Dependents were Deleted Successfully");
            this.checked = [];
            //this.table_checked([])
            this.getDependentSupport();

         }
      })
   },











//   providerNote(id){
//     this.get_provider_id(id);
//     this.get_provider_view_type("add");
//   },
   


    pageChange(pageNumber){

        //this.currentPage = pageNumber;
        this.table_current_page(pageNumber);
        this.getDependentSupport();

    },



    dependent_added(){
        this.getDependentSupport();
    },




   isChecked(id){

    return  this.checked.includes(id);

   },



    async getDependentSupport(){
        try{
        const response = await axios.get(
            'dependent_supports?page=' + this.currentPage + 
            '&page_size=' + this.perpage + 
            '&q=' +this.getsearch + 
            '&status=' + this.getstatus +
            '&sort_direction=' + this.sort_direction +
            '&sort_field=' + this.sort_field +
            '&portal=' + this.app_portal +
            '&support_id=' + this.formData.support_id
            )
       
             //console.log(response.data.data);
            this.dependents = response.data.data.data;
            //this.perPage = response.data.data.per_page;
               this.table_per_page(response.data.data.per_page);
            //this.total = response.data.data.total;
             this.table_total(response.data.data.total)
        
        }catch(_){
            console.log(_);
        }

    },

    perpageval(value){
        this.table_per_page(value);
       this.getDependentSupport();

    },

    searchval(value){

         this.table_search(value);
        this.getDependentSupport();

    },

    statusval(value){

        this.table_status(value);
        this.getDependentSupport();

    },

 


   depSuppView(dependent_id){

    this.dependentSupportData(dependent_id);
    this.$refs.viewDependentSupportDetail.click();
    //console.log(dependent_id);


   },





   async dependentSupportData(id){
       try{
     const response = await axios.get(`dependentSupportDetails?dependent_id=${id}`);
              // console.log(response);
            this.showLoading(false);
                this.getDependentSupportData = response.data.data;
             }catch(error){
                //console.log(error);
                this.showLoading(false);
             }

    },


    //    added_providers(){
    //     this.getDependent();
    // },


     dependentSupportAddModal(){
      
        this.get_dependent_support_view_type("add");
        
        
     },

    depView(id){
        this.set_dependent_support_info(id);
        this.get_dependent_support_view_type("view");
        console.log("view");
        this.$refs.getDependentSupportModal.click();
    },


    depEdit(id){
        this.set_dependent_support_info(id);
        this.get_dependent_support_view_type("edit");
        console.log("edit");
        this.$refs.getDependentSupportModal.click();
    },





    // edit_user_provider_portal(){
    //     this.$refs.userEditEmployeeProviderModal.click();
    // },



//    user_employee_country_edit(){
//              console.log("okkkkkkkkkkkk")
//              this.view_type = "view";
//            //this.userView(portal_id, user_id)
//            this.$refs.userCountryModal.click();
//     }


  },





  async created(){

        const user_data = JSON.parse(localStorage.getItem("users"));
      
          this.user_level = user_data.user_level;
         let user_idd = user_data.id;
    // this.portal =  localStorage.getItem('Portall')
    this.app_portal = localStorage.getItem("Portall");

    //this.currentPage = 1;
    this.table_current_page(1);
    this.getDependentSupport();

       try{
      const  user_dataa = {
            user_id : user_idd 
        }
       let response = await axios.post("getSupportId",user_dataa);
     // console.log(response.data.data);
     // response.data.data.unshift({id:"",country:"Please Select Option", created_at:null,updated_at:null})   
        this.formData.support_id =  JSON.parse(JSON.stringify(response.data.data));
       console.log(user_idd);
       
       
       
      
    }catch(error){
         console.log("5555");
        console.log(error);
    }
  },


  watch:{
    // "perPage"(value){
    //    this.table_per_page(value);
    //    this.getUser();
    // },
 
    //  "search"(value){
    //     this.table_search(value);
    //     this.getUser();
    //  },

    //  "status"(value){
    //     this.table_status(value);
    //     this.getUser();
    //  },

     "selectPage"(value){
         this.checked = [];
       //  this.table_checked([]);

        if(value){
            const dependents = JSON.parse(JSON.stringify(this.dependents));
            dependents.forEach(dependent=>{
                this.checked.push(dependent.id)
                //this.table_checked_push(user.id);
            });
        
        }else{
              
              this.checked = [];
              //this.table_checked([]);
              //this.selectAll = false;
              this.table_select_all(false);
        }

     },

     "checked"(){

        this.table_url(BASEURL + "dependents/export/"+ this.checked);
       // this.url = BASEURL + this.url + this.checked;

     }

  }

};
</script>